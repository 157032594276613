<div class="container ">
  <div class="row d-flex justify-content-center align-items-center authentication-cards">
    <div
      class="col-12 col-md-9 col-lg-7 col-xl-6">
      <h2 class="text-center mb-4 heading-text">
     <!-- MainHeading -->
        <span>
          {{headingText}}
     </span>
      </h2>

      <div class="d-flex justify-content-center">
        <!-- ReturnToLoginScreenButtonText -->
        <button type="button" class="btn btn-success btn-lg pre-auth-btn  py-1"  (click)="navigateToLoginPage()"> {{'AUTHENTICATION.PASSWORDRESETSUCCESS.ReturnToLoginScreenButtonText' | translate}}</button>
    </div>
    </div>
  </div>
</div>
