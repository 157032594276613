import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { BlankComponent } from './Layouts/blank/blank.component';
import { FullComponent } from './Layouts/full/full.component';
const routes: Routes = 

[
  {
		path: '',
		component : BlankComponent,
		children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
			{
				path: 'authentication',
				loadChildren:
					() => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule)
			},
		]
	},
  {
		path: '',
		component : FullComponent, canActivate: [AuthGuard],
		children: [
      		{ 
				  path: '', 
				  redirectTo: '/authentication/login', 
				  pathMatch: 'full' },
			{
				path: 'patient',
				loadChildren:
					() => import('./components/patient/patient.module').then(m => m.PatientModule)
			},
      		{
				path: 'admin',
				loadChildren:
					() => import('./components/admin/admin.module').then(m => m.AdminModule)
			},
      		{
				path: 'provider',
				loadChildren:
					() => import('./components/provider/provider.module').then(m => m.ProviderModule)
			},
		]
	},
	{ path: '**', redirectTo: '/authentication/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
