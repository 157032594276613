import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-reset-password',
  templateUrl: './success-reset-password.component.html',
  styleUrls: ['./success-reset-password.component.css']
})
export class cSuccessResetPasswordComponent implements OnInit {

  constructor(private router: Router, private dataService : DataService) { }

  headingText: string = "Password Reset Successfully";
  ngOnInit(): void {
    this.dataService.displayHippaElement.next(true) 
    this.dataService.passwordText.subscribe(result=>{
      this.headingText = result
    })
  }
  navigateToLoginPage()
  {
    this.router.navigateByUrl('/authentication/login');
  }
}
