<div class="container">
    <div class="row">
        <div class="col-12 ">
           <div class="not-found">
            <div class="img-404"></div>
            <h3 class="font-800 mt-2">Internal Server Error!</h3>
            <p>Please contact system admin or try again.</p>
            <a href="/authentication/login" routerLinkActive="router-link-active" >Back to login</a>
           </div>
        </div>
    </div>
</div>