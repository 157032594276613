  <div class="card mb-3">
    <div class="card-header header">
      <h3>About</h3>
    </div>
  </div>
  <div>
    <div class="card" style="min-height: 600px;">
      <div class="card-header header">
        <h3 >Release Notes</h3>
      </div>
      <div class="card-body">
        <!-- to be fetched from release notes -->
        <div *ngFor="let release of releaseNotes.default | slice: (notesCurrentPage-1) * notesPageSize : notesCurrentPage * notesPageSize">
          <p class="font-weight-bold mb-0 ml-4  pt-2">- &nbsp;&nbsp;{{release.ReleaseVersion}}</p>
          <ul class="custom-list ml-4"><li *ngFor="let note of release.ReleaseNotes" class="text-dark">{{note.note}}</li></ul>
        </div>
    </div>
  </div>
  