import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }
  cSessionService_SetUserDetails(sData)
  {
    localStorage.setItem('lsUserDetails', JSON.stringify(sData));
  }
  cSessionService_GetUserDetails()
  {
    return JSON.parse(localStorage.getItem('lsUserDetails'));
  }
  cSessionService_SetPermissions(sData)
  {
    localStorage.setItem('lsPermissions', JSON.stringify(sData));
  }
  cSessionService_GetPermissions()
  {
    return JSON.parse(localStorage.getItem('lsPermissions'));
  }
  // lsTenantId
  cSessionService_SetTenantId(sData)
  {
    localStorage.setItem('lsTenantId', JSON.stringify(sData));
  }
  cSessionService_GetTenantId()
  {
    return JSON.parse(localStorage.getItem('lsTenantId'));
  }
  //set and get phone
  cSessionService_SetPhoneNumber(sData)
  {
    localStorage.setItem('lsPhone', JSON.stringify(sData));
  }
  cSessionService_GetPhoneNumber()
  {
    return JSON.parse(localStorage.getItem('lsPhone'));
  }
  //set and get signup user details
  cSessionService_SetSignupDetails(sData)
  {
    localStorage.setItem('lsSignupUserDetails',JSON.stringify(sData));
  }
  cSessionService_GetSignupDetails()
  {
    return JSON.parse(localStorage.getItem('lsSignupUserDetails'));
  }
  //set and get Provider doseSpot ID
  cSessionService_SetProviderdoseSpotID(sData)
  {
    localStorage.setItem('ProviderDoseSpotID',JSON.stringify(sData));
  }
  cSessionService_GetProviderdoseSpotID()
  {
    return JSON.parse(localStorage.getItem('ProviderDoseSpotID'));
  }
}
