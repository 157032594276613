<div *ngIf="responseAlert" class="alert data-table alert-messages {{alertColor}}" role="alert"> {{resMessage |
  titlecase}}
  <button type="button" class="btn-close ml-2 mt-1" data-dismiss="alert" aria-label="Close"
      (click)="cHeaderComponent_DismissAlert()">
  </button>
</div>
<div class="header-navbar">
  <nav class="navbar navbar-expand-lg shadow navbar-light" [style.background]="menuBarColor">
    <div class="container-fluid px-0">
      <div class="header-logo" (click)="cHeaderComponent_RefreshonLogo()"  routerLinkActive="router-link-active">
        <img class="cursor-point logo" [src]="clientImageUrl" alt="" style="object-fit: cover;"
          (error)="DefaultImage()">
      </div>

      <button  class="navbar-toggler mr-3" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div [style.background]="menuBarColor" class="collapse navbar-collapse p-2 justify-content-end" id="navbarSupportedContent">
        <button *ngIf="isProvider" class="mr-3"
          [ngClass]="{'vacation-btn-on':vacationStatus, 'vacation-btn-off':!vacationStatus}"
          (click)="cHeaderComponent_ChangeVacationStatus()">
          {{vacationStatus? ('HEADER.HeaderButtonTextOn' | translate) : ('HEADER.HeaderButtonTextOff' | translate)}}
        </button>

        <form class="d-flex">
          <div class=" nav-item dropdown header-dropdown">
            <button class="btn btn-secondary dropdown-toggle" [disabled]="userRole && userRole === 'user'"
              [style.color]="menuBarTextColor ===''? '#000':'#000'" [style.borderColor]="menuBarTextColor"
              [style.background]="menuBarTextColor" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{selectedLanguage}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="javascript:void(0)" #langSelect
                  (click)="translateService.use(langSelect.value);cHeaderComponent_SetLanguage(langSelect.value);"
                  *ngFor="let lang of translateService.getLangs()" [value]="lang"
                  [selected]="lang === translate?.currentLang">{{ lang }}</a></li>
            </ul>
          </div>
        </form>


        <div class="d-flex" *ngIf="displayHeaderOptions == true">
          <!-- meter -->
          <div class="ml-2 text-light d-flex justify-content-center align-items-center">
            <svg (click)="cHeaderComponent_Navigate('dashboard')" version="1.1" weight="30" height="50" id="Capa_1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path [style.fill]="menuBarTextColor" d="M435.143,129.356c-6.796-6.795-17.463-7.797-25.407-2.384c-29.926,20.398-180.03,122.969-196.162,139.1
                    c-23.394,23.395-23.394,61.459,0,84.854c11.697,11.696,27.063,17.545,42.427,17.545c15.364,0,30.729-5.849,42.427-17.545
                    c16.131-16.132,118.701-166.236,139.1-196.162C442.939,146.821,441.938,136.153,435.143,129.356z M270.142,322.641
                    c-7.797,7.799-20.486,7.799-28.283,0c-7.798-7.797-7.799-20.482-0.004-28.28c6.268-6.194,48.885-36.588,101.319-73.035
                    C306.728,273.76,276.334,316.375,270.142,322.641z" />
                    <path [style.fill]="menuBarTextColor" d="M92.231,401.523l-24.69,12.044C49.475,381.325,40,345.338,40,308.499c0-26.991,4.977-52.842,14.06-76.683l28.291,13.57
                    c2.79,1.338,5.735,1.972,8.636,1.972c7.453,0,14.608-4.185,18.047-11.355c4.776-9.959,0.576-21.906-9.384-26.683l-27.932-13.398
                    c34.717-56.62,94.784-96.095,164.283-102.505v30.081c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V93.402
                    c23.828,2.169,46.884,8.237,68.771,18.117c10.065,4.545,21.912,0.066,26.457-9.999c4.545-10.068,0.068-21.913-10-26.458
                    C328.063,60.091,292.659,52.499,256,52.499c-68.38,0-132.667,26.628-181.02,74.98C26.629,175.832,0,240.119,0,308.499
                    c0,50.53,14.998,99.674,43.373,142.115c3.822,5.715,10.141,8.886,16.639,8.886c2.954,0,5.946-0.655,8.757-2.026l41-20
                    c9.928-4.843,14.05-16.816,9.207-26.744C114.133,400.803,102.159,396.682,92.231,401.523z" />
                    <path [style.fill]="menuBarTextColor"
                      d="M489.436,203.271c-4.544-10.067-16.387-14.547-26.458-10c-10.067,4.545-14.544,16.39-9.999,26.457
                    C465.601,247.686,472,277.553,472,308.499c0,36.894-9.506,72.939-27.625,105.218l-25.777-12.275
                    c-9.971-4.748-21.906-0.515-26.656,9.459c-4.749,9.972-0.514,21.907,9.459,26.656l42,20c2.763,1.315,5.692,1.944,8.588,1.944
                    c6.5,0,12.82-3.175,16.637-8.886C497.002,408.173,512,359.029,512,308.499C512,271.84,504.408,236.436,489.436,203.271z" />
                  </g>
                </g>
              </g>
              <g>
              </g>
            </svg>

          </div>
          <!-- list -->
          <div *ngIf="userRole !== 'user' && userRole !== 'editor'" (click)="cHeaderComponent_NavigateToPatientList()"
            class="text-light ml-2 d-flex align-items-center cursor-point">
            <i class="fa-solid fa-list-ul font-45 mt-1" [style.color]="menuBarTextColor"></i>
          </div>
          <div *ngIf="userRole == 'editor'" class="text-light ml-2 d-flex align-items-center cursor-point">
            <i class="fa-solid fa-bell font-40 position-relative" [style.color]="menuBarTextColor" (click)="cProviderDashboardComponent_GetDoseSpotUrl()">
              <sup class="badge bg-danger font-12" *ngIf="notificationCount>0">{{notificationCount}}</sup>
            </i>
          </div>

          <!-- calender svg -->
          <div *ngIf="userRole !== 'user'" class="text-light d-flex justify-content-center align-items-center">
            <svg (click)="cHeaderComponent_Navigate('availability')" id="Layer_11" enable-background="new 0 0 34 34"
              height="45" viewBox="0 0 34 34" width="45" xmlns="http://www.w3.org/2000/svg" class="ml-2">
              <g>
                <path [style.fill]="menuBarTextColor"
                  d="m29.6 2h-3v3c0 .6-.5 1-1 1s-1-.4-1-1v-3h-16v3c0 .6-.5 1-1 1s-1-.4-1-1v-3h-3c-1.5 0-2.6 1.3-2.6 3v3.6h32v-3.6c0-1.7-1.8-3-3.4-3zm-28.6 8.7v18.3c0 1.8 1.1 3 2.7 3h26c1.6 0 3.4-1.3 3.4-3v-18.3zm8.9 16.8h-2.4c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c-.1.5-.4.8-.9.8zm0-9h-2.4c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c-.1.5-.4.8-.9.8zm8 9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm0-9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm8 9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm0-9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8z" />
              </g>
            </svg>

          </div>
          <!-- Message svg -->
          <div (click)="NavigateToChat()" class="d-flex position-relative ml-2 text-light">
            <svg weight="30" height="54" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <g>
                <g>
                  <path [style.fill]="menuBarTextColor" d="M331.756,277.251l-42.881,43.026c-17.389,17.45-47.985,17.826-65.75,0l-42.883-43.026L26.226,431.767
         C31.959,434.418,38.28,436,45,436h422c6.72,0,13.039-1.58,18.77-4.232L331.756,277.251z" />
                </g>
              </g>
              <g>
                <g>
                  <path [style.fill]="menuBarTextColor" d="M467,76H45c-6.72,0-13.041,1.582-18.772,4.233l164.577,165.123c0.011,0.011,0.024,0.013,0.035,0.024
         c0.011,0.011,0.013,0.026,0.013,0.026l53.513,53.69c5.684,5.684,17.586,5.684,23.27,0l53.502-53.681c0,0,0.013-0.024,0.024-0.035
         c0,0,0.024-0.013,0.035-0.024L485.77,80.232C480.039,77.58,473.72,76,467,76z" />
                </g>
              </g>
              <g>
                <g>
                  <path [style.fill]="menuBarTextColor"
                    d="M4.786,101.212C1.82,107.21,0,113.868,0,121v270c0,7.132,1.818,13.79,4.785,19.788l154.283-154.783L4.786,101.212z" />
                </g>
              </g>
              <g>
                <g>
                  <path [style.fill]="menuBarTextColor" d="M507.214,101.21L352.933,256.005L507.214,410.79C510.18,404.792,512,398.134,512,391V121
         C512,113.866,510.18,107.208,507.214,101.21z" />
                </g>
              </g>
              <g>
              </g>
              <g>
              </g>
            </svg>
            <span id="unread-message-count" class="badge badge-danger message-notifier"></span>
          </div>
          <!-- profile -->
          <form class="d-flex">
            <div
              class=" nav-item dropdown header-dropdown mx-1  mb-1 profile-img  d-flex justify-content-center align-items-center">
              <ng-container *ngIf="!userImage">
                <button class="custom-btn-profile mt-1 dropdown-toggle " type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="45" height="45"
                    viewBox="0 0 45.532 45.532" style="enable-background:new 0 0 45.532 45.532;" xml:space="preserve">
                    <g>
                      <path [style.fill]="menuBarTextColor" d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765
           S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53
           c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012
           c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592
           c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z" />
                    </g>
                    <g>
                    </g>
                  </svg>
                </button>
              </ng-container>
              <ng-container *ngIf="userImage">
                <button class="custom-btn-profile dropdown-toggle mt-1" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <img src='{{userImage}}' (error)="userImage=''">
                </button>
              </ng-container>
              <ul class="dropdown-menu ac-setting mt-2" aria-labelledby="dropdownMenuButton1" style="margin: -110px">
                <li class="d-flex justify-content-center align-items-center" *ngIf="userRole === 'editor'"><a
                    class="dropdown-item font-14 font-400" href="javascript:void(0)" routerLink="provider/profile">
                    {{ "HEADER.editprofiletext" | translate }}
                    <svg class="ml-3" height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg">
                      <g id="Layer_2" data-name="Layer 2" fill="black">
                        <path
                          d="m29.21 11.84a3.92 3.92 0 0 1 -3.09-5.3 1.84 1.84 0 0 0 -.55-2.07 14.75 14.75 0 0 0 -4.4-2.55 1.85 1.85 0 0 0 -2.09.58 3.91 3.91 0 0 1 -6.16 0 1.85 1.85 0 0 0 -2.09-.58 14.82 14.82 0 0 0 -4.1 2.3 1.86 1.86 0 0 0 -.58 2.13 3.9 3.9 0 0 1 -3.25 5.36 1.85 1.85 0 0 0 -1.62 1.49 14.14 14.14 0 0 0 -.28 2.8 14.32 14.32 0 0 0 .19 2.35 1.85 1.85 0 0 0 1.63 1.55 3.9 3.9 0 0 1 3.18 5.51 1.82 1.82 0 0 0 .51 2.18 14.86 14.86 0 0 0 4.36 2.51 2 2 0 0 0 .63.11 1.84 1.84 0 0 0 1.5-.78 3.87 3.87 0 0 1 3.2-1.68 3.92 3.92 0 0 1 3.14 1.58 1.84 1.84 0 0 0 2.16.61 15 15 0 0 0 4-2.39 1.85 1.85 0 0 0 .54-2.11 3.9 3.9 0 0 1 3.13-5.39 1.85 1.85 0 0 0 1.57-1.52 14.5 14.5 0 0 0 .26-2.53 14.35 14.35 0 0 0 -.25-2.67 1.83 1.83 0 0 0 -1.54-1.49zm-8.21 4.16a5 5 0 1 1 -5-5 5 5 0 0 1 5 5z" />
                      </g>
                    </svg></a></li>
                <li class="d-flex justify-content-center align-items-center" *ngIf="userRole === 'user'"><a
                    class="dropdown-item font-14 font-400" href="javascript:void(0)" routerLink="patient/profile">
                    {{ "HEADER.editprofiletext" | translate }}
                    <svg class="ml-3" height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg">
                      <g id="Layer_2" data-name="Layer 2" fill="black">
                        <path
                          d="m29.21 11.84a3.92 3.92 0 0 1 -3.09-5.3 1.84 1.84 0 0 0 -.55-2.07 14.75 14.75 0 0 0 -4.4-2.55 1.85 1.85 0 0 0 -2.09.58 3.91 3.91 0 0 1 -6.16 0 1.85 1.85 0 0 0 -2.09-.58 14.82 14.82 0 0 0 -4.1 2.3 1.86 1.86 0 0 0 -.58 2.13 3.9 3.9 0 0 1 -3.25 5.36 1.85 1.85 0 0 0 -1.62 1.49 14.14 14.14 0 0 0 -.28 2.8 14.32 14.32 0 0 0 .19 2.35 1.85 1.85 0 0 0 1.63 1.55 3.9 3.9 0 0 1 3.18 5.51 1.82 1.82 0 0 0 .51 2.18 14.86 14.86 0 0 0 4.36 2.51 2 2 0 0 0 .63.11 1.84 1.84 0 0 0 1.5-.78 3.87 3.87 0 0 1 3.2-1.68 3.92 3.92 0 0 1 3.14 1.58 1.84 1.84 0 0 0 2.16.61 15 15 0 0 0 4-2.39 1.85 1.85 0 0 0 .54-2.11 3.9 3.9 0 0 1 3.13-5.39 1.85 1.85 0 0 0 1.57-1.52 14.5 14.5 0 0 0 .26-2.53 14.35 14.35 0 0 0 -.25-2.67 1.83 1.83 0 0 0 -1.54-1.49zm-8.21 4.16a5 5 0 1 1 -5-5 5 5 0 0 1 5 5z" />
                      </g>
                    </svg></a></li>
                <li class="d-flex justify-content-center align-items-center" *ngIf="userRole === 'admin'"><a
                    class="dropdown-item font-14 font-400" href="javascript:void(0)" routerLink="admin/setting"> {{ "HEADER.editSettingsText" | translate }}
                    <svg class="ml-3" height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg">
                      <g id="Layer_2" data-name="Layer 2" fill="black">
                        <path
                          d="m29.21 11.84a3.92 3.92 0 0 1 -3.09-5.3 1.84 1.84 0 0 0 -.55-2.07 14.75 14.75 0 0 0 -4.4-2.55 1.85 1.85 0 0 0 -2.09.58 3.91 3.91 0 0 1 -6.16 0 1.85 1.85 0 0 0 -2.09-.58 14.82 14.82 0 0 0 -4.1 2.3 1.86 1.86 0 0 0 -.58 2.13 3.9 3.9 0 0 1 -3.25 5.36 1.85 1.85 0 0 0 -1.62 1.49 14.14 14.14 0 0 0 -.28 2.8 14.32 14.32 0 0 0 .19 2.35 1.85 1.85 0 0 0 1.63 1.55 3.9 3.9 0 0 1 3.18 5.51 1.82 1.82 0 0 0 .51 2.18 14.86 14.86 0 0 0 4.36 2.51 2 2 0 0 0 .63.11 1.84 1.84 0 0 0 1.5-.78 3.87 3.87 0 0 1 3.2-1.68 3.92 3.92 0 0 1 3.14 1.58 1.84 1.84 0 0 0 2.16.61 15 15 0 0 0 4-2.39 1.85 1.85 0 0 0 .54-2.11 3.9 3.9 0 0 1 3.13-5.39 1.85 1.85 0 0 0 1.57-1.52 14.5 14.5 0 0 0 .26-2.53 14.35 14.35 0 0 0 -.25-2.67 1.83 1.83 0 0 0 -1.54-1.49zm-8.21 4.16a5 5 0 1 1 -5-5 5 5 0 0 1 5 5z" />
                      </g>
                    </svg></a></li>
                <li><a class="dropdown-item font-14 font-400 d-flex justify-content-end align-items-end"
                    href="javascript:void(0)" (click)="cHeaderComponent_logout()"><span class="mr-1"> {{ "HEADER.LogoutText" | translate }}</span> <i
                      class="fa-solid fa-sign-out signout" aria-hidden="true"></i>
                  </a>
                </li>

              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </nav>
</div>

<div class="admin-settings-notification" *ngIf="message;">
  <div *ngIf="message.data.type === 'Banner'"
    class=" pharmacy-alert alert-danger bg-red d-flex justify-content-center align-items-center position-fixed"
    role="alert">
    {{message.data.message}}
    <i class="fa-solid fa-times position-absolute" aria-hidden="true" (click)="message=null;" style="right: 10px;"></i>
  </div>

  <!-- modal -->
  <div class="modal fade" id="NotificationPopUp" data-bs-keyboard="false" tabindex="-1" aria-labelledby="BackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal-content-bg">
        <div class="modal-header header-notification" style="border-bottom: none">
          <h2 class="modal-title modal-title-notification mb-0 ml-5 font-600 position-relative" id="BackdropLabel">
            <strong> {{ "ADMIN.NOTIFICATIONS.ModalHeading" | translate }} </strong>
          </h2>
          
          <i class="fa-solid fa-times-circle-o" data-bs-dismiss="modal" aria-hidden="true"></i>
        </div>
        <div class="modal-body modal-notification mb-5 ml-5">
          {{message.data.message}}
        </div>
      </div>
    </div>
  </div>

</div>
<!-- notificationModal Model -->
<div class="modal precription-modal fade family-member-modal" id="notificationModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-body" style="height: calc(100vh - 132px) !important;">
        <iframe *ngIf="doseSpotIframeUrl" [src]="doseSpotIframeUrl | safeUrlPipe" height=100% width=100%><p>Your browser does not support iframes.</p>
        </iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="this.doseSpotIframeUrl = ''">Cancel</button>
      </div>
    </div>
  </div>
</div>