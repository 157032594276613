<!-- <app-header></app-header> -->
<div *ngIf="responseAlert" class="alert data-table alert-messages {{ alertColor }}" role="alert">
  {{ resMessage | titlecase }}
  <button type="button" class="btn-close ml-2 mt-1" data-dismiss="alert" aria-label="Close"
    (click)="dismissAlert()"></button>
</div>
<div class="loading" *ngIf="loader">
  <div class="spinner-border" role="status"></div>
</div>
<div *ngIf="!loader" class="container registration-two">
  <span class="d-flex justify-content-center">
    <!-- MainHeading -->
    <h2 class="pt-5">
      {{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.MainHeading" | translate }}
    </h2>
  </span>
  <div class="row p-4 main patient-regist-patient-list-scroller custom-scroller scroller">
    <form class="row g-3 mt-2" [formGroup]="patientRegistrationForm">
      <div class="col-lg-4">
        <div class="row">
          <div class="col-lg-12 p-0 profile-pic text-center">
            <div class="position-relative complete-register-profile">
              <label class="card-body appointment-images p-0 justify-content-center align-items-center" for="Inputfile"
                class="form-label ml-2">
                <svg *ngIf="!patientImage" height="250" viewBox="0 0 32 32" width="250"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="_34-Add-image" data-name="34-Add-image">
                    <path d="m29 24h-3v-3a1 1 0 0 0 -2 0v3h-3a1 1 0 0 0 0 2h3v3a1 1 0 0 0 2 0v-3h3a1 1 0 0 0 0-2z" />
                    <path
                      d="m17 24h-12a1 1 0 0 1 -1-1v-18a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v12a1 1 0 0 0 2 0v-12a3 3 0 0 0 -3-3h-18a3 3 0 0 0 -3 3v18a3 3 0 0 0 3 3h12a1 1 0 0 0 0-2z" />
                    <circle cx="11" cy="8.5" r="2.5" />
                    <path
                      d="m7.29 14.29-1.29 1.3v6.41h16v-6.41l-4.29-4.3a1 1 0 0 0 -1.42 0l-5.29 5.3-2.29-2.3a1 1 0 0 0 -1.42 0z" />
                  </g>
                </svg>
                <input #myInput (click)="reset()" id="Inputfile" type="file" class="form-control update-img w-0 d-none"
                  accept="image/png, image/jpg, image/jpeg" (change)="uploadProfilePicture($event) " />
              </label>

              <img *ngIf="patientImage" src="{{ patientImage }}" width="250" height="250" style="border-radius: 10%"
                class="profile-picture-size" />
            </div>
          </div>
          <div class="col-lg-12 custom-col-lg-12 p-0 mt-2 text-center">
            <label class="form-label card-body appointment-images p-0 justify-content-center align-items-center"
              for="Inputfile">
              <!-- ProfilePictureUploadLabelText -->
              <span
                class="font-800 font-16 cursor-point upload-picture-text">{{"AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.ProfilePictureUploadLabelText"|
                translate}}</span>
              <input #myInput (click)="reset()" id="Inputfile" type="file" class="form-control update-img w-0 d-none"
                accept="image/png, image/jpg, image/jpeg" (change)="uploadProfilePicture($event) " />
            </label>
          </div>
        </div>
      </div>

      <!-- Right side form -->
      <div class="col-lg-8 form-2">
        <!-- Measurment start-->
        <div class="row">
          <div class="col-md-4 mb-1 height-text">
            <p for="exampleInputEmail1" class="form-label mb-1">
              {{"AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.HeightLabelText" | translate}}</p>
            <!-- HeightLabelText -->
            <input type="number" class="form-control measure"
              placeholder=" {{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.FeetPlaceholderText' | translate}}"
              id="inputZip" formControlName="heightFeet" />
            <!-- HeightFeetValidationText -->
            <div
              *ngIf="patientRegistrationForm.controls['heightFeet'].hasError('required') && patientRegistrationForm.controls['heightFeet'].touched"
              class="text-danger ml-2 validation-sm-font">{{'PATIENT.EDITPROFILE.fieldValidationMessage' | translate}}
            </div>
            <div
              *ngIf="patientRegistrationForm.controls['heightFeet'].hasError('pattern') && patientRegistrationForm.controls['heightFeet'].touched"
              class="text-danger ml-2 validation-sm-font">{{'PATIENT.EDITPROFILE.fieldValidationMessagefeetcheck' |
              translate}}</div>
          </div>
          <div class="col-md-4 height-text">
            <p for="exampleInputEmail1" class="form-label mb-1">
              {{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.InchesPlaceHolderText' | translate}}</p>
            <!-- HeightInchesValidationText -->
            <input type="number" class="form-control measure"
              placeholder="  {{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.InchesPlaceHolderText' | translate}}"
              id="inputZip" formControlName="heightInch" />
            <div
              *ngIf="patientRegistrationForm.controls['heightInch'].hasError('required') && patientRegistrationForm.controls['heightInch'].touched"
              class="text-danger ml-2 validation-sm-font">{{'PATIENT.EDITPROFILE.fieldValidationMessage' | translate}}
            </div>
            <div
              *ngIf="patientRegistrationForm.controls['heightInch'].hasError('pattern') && patientRegistrationForm.controls['heightInch'].touched"
              class="text-danger ml-2 validation-sm-font">{{'PATIENT.EDITPROFILE.fieldValidationMessageinchescheck' |
              translate}}</div>
          </div>
          <div class="col-md-4 height-text">
            <!-- WeightLabelText -->
            <p for="exampleInputEmail1" class="form-label mb-1"> {{
              "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.WeightLabelText"| translate }}</p>
            <input type="number" class="form-control measure"
              placeholder=" {{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.PoundsPlaceholderText' | translate}}"
              id="inputZip" formControlName="weight" />
            <!-- WeightValidationText -->
            <div
              *ngIf="patientRegistrationForm.controls['weight'].hasError('required') && patientRegistrationForm.controls['weight'].touched"
              class="text-danger ml-2 validation-sm-font">{{'PATIENT.EDITPROFILE.fieldValidationMessage' | translate}}
            </div>
            <div class="text-danger ml-2 validation-sm-font"
              *ngIf="patientRegistrationForm.controls['weight'].hasError('min') || patientRegistrationForm.controls['weight'].hasError('max')">
              {{'PATIENT.EDITPROFILE.fieldValidationMessageweightcheck' | translate}}</div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-lg-4 mb-2 col-md-6 col-sm-12">
            <!-- AlcoholLabelText -->
            <p class="mb-1">
              {{"AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.AlcoholLabelText" | translate}}
            </p>
            <div class="form-check-inline ">
              <label class="form-check-label font-600">
                <!-- YesText -->
                <input type="radio" class="form-check-input radio-btn" name="optradio5"
                  (change)="checkAlcholConsumption('yes')" />{{
                "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.YesText"
                | translate
                }}
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label font-600">
                <!-- NoText -->
                <input type="radio" checked class="form-check-input radio-btn" name="optradio5"
                  (change)="checkAlcholConsumption('no')" />{{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.NoText" |
                translate }}
              </label>
            </div>
          </div>
          <div class="col-lg-4 mb-2 col-md-6 col-sm-12">
            <!-- TobaccoLabelText -->
            <p class="mb-1">
              {{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.TobaccoLabelText"| translate }}
            </p>
            <div class="form-check-inline ">
              <label class="form-check-label font-600">
                <!-- YesText -->
                <input type="radio" class="form-check-input radio-btn" name="optradio1"
                  (change)="checkTobbacoConsumption('yes')" />{{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.YesText"|
                translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label font-600">
                <!-- NoText -->
                <input type="radio" checked class="form-check-input radio-btn" name="optradio1"
                  (change)="checkTobbacoConsumption('no')" />{{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.NoText" |
                translate }}
              </label>
            </div>
          </div>

          <div class="col-lg-4 mb-2 col-md-6 col-sm-12">
            <!-- GenderLabelText -->
            <p class="mb-1">
              {{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.GenderLabelText" | translate }}
            </p>
            <div class="form-check-inline ">
              <label class="form-check-label font-600">
                <!-- MaleLabelText -->
                <input type="radio" checked class="form-check-input radio-btn" name="optradio3" value="Male"
                  (change)="checkGender($event)" />{{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.MaleLabelText" |
                translate }}
              </label>
            </div>
            <div class="form-check-inline">
              <label class="form-check-label font-600">
                <!-- FemaleLabelText -->
                <input type="radio" class="form-check-input radio-btn" name="optradio3" value="Female"
                  (change)="checkGender($event)" />{{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.FemaleLabelText" |
                translate }}
              </label>
            </div>
          </div>
        </div>

        <!-- Measurment end -->
      </div>

      <div>
        <div class="col-md-12 mt-2 height-text">

          <!-- ful rows section -->

          <!-- medication -->
          <div class="row">
            <div class="col-md-6">
              <p class="mb-1 mt-2">
                <!-- MedicationsLabelText -->
                {{"AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.MedicationsLabelText"| translate}}
              </p>
              <div appSearchDropdown (clickOutside)="resetFields('medication')">
                <div class="mb-1 patient-registration-dropdown position-relative">
                  <div
                    class="editor-tag p-2 keyTerms d-flex family-member-patient-list-scroller custom-scroller scroller"
                    (click)="focusInputField('currentMedication')">
                    <span *ngFor="let medication of selectedMedications ; let i=index">
                      <span class="tag-item d-flex px-1 mr-1" *ngIf="medication.Status=='1'">
                        {{medication?.DisplayName || medication?.medicationName || "No Name"}}
                        <span class="remove-tag ml-1" (click)="removeSelectedMedication(medication)">×</span>
                      </span>
                    </span>
                    <input type="text" #medicationsSearch class="form-control input-tag"
                      placeholder='{{"PATIENT.EDITPROFILE.SearchMedicationsPlaceHolder" | translate}}'
                      formControlName="current_medication" />
                  </div>
                  <div *ngIf="medicationToPush?.length>0" class="auto-search p-2 mt-2" infiniteScroll
                    [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                    (scrolled)="onScrollDownSearchCurrentMedications(medicationsSearch.value)" [scrollWindow]="false">
                    <div class="item p-2" *ngFor="let item of medicationToPush;let i=index">
                      <input [id]="item.DisplayName" class="mr-1" type="checkbox" [checked]="item.checked==true"
                        (change)="selectedMedication(item,$event, 'current')" />
                        <label [for]="item.DisplayName">{{item?.DisplayName}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <p class="mb-1 mt-2">
                <!-- MedicationsLabelText -->
                {{"AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.PreviousMedications"| translate}}
              </p>
              <div appDropdownClose (clickOutside)="resetFields('previousMedication')">
                <div class="mb-1 family-member-dropdown position-relative">
                    <div class="editor-tag p-2 keyTerms d-flex family-member-patient-list-scroller custom-scroller scroller"
                        (click)="focusInputField('previousMedication')">
                        <span *ngFor="let medication of selectedMedications ; let i=index">
                            <span class="tag-item d-flex px-1 mr-1" *ngIf="medication.Status=='2'">
                                {{medication?.DisplayName || medication?.medicationName || "No Name" }}
                                <span class="remove-tag ml-1"
                                    (click)="removeSelectedMedication(medication)">×</span>
                            </span>
                        </span>
                        <input type="text" #previousMedicationsSearch class="form-control input-tag"
                            placeholder='{{"PATIENT.EDITPROFILE.SearchMedicationsPlaceHolder" | translate}}'
                            formControlName="previous_medication" />
                    </div>
                    <div *ngIf="previousMedicationToPush?.length>0" class="auto-search p-2 mt-2" infiniteScroll
                        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                        (scrolled)="onScrollDownSearchPreviousMedications(previousMedicationsSearch.value)"
                        [scrollWindow]="false">
                        <div class="item p-2" *ngFor="let item of previousMedicationToPush;let i=index">
                            <input [id]="item.DisplayName" type="checkbox" class="mr-1"
                                [checked]="item.checked==true"
                                (change)="selectedMedication(item,$event,'previous')" />
                            <label [for]="item.DisplayName"> {{item?.DisplayName}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>

          <!-- allergies -->
          <div class="row">
            <div class="col-md-6">
              <p class="mb-1 mt-3">
                <!-- AllergiesLabelText -->
                {{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.AllergiesLabelText' | translate}}
              </p>
              <div appSearchDropdown (clickOutside)="resetFields('allergy')">
                <div class="position-relative">
                  <div
                    class="editor-tag p-2 keyTerms d-flex family-member-patient-list-scroller custom-scroller scroller"
                    (click)="focusInputField('allergy')">
                    <span *ngFor="let allergy of selectedAllergies ; let i=index">
                      <span class="tag-item d-flex px-1 mr-1" *ngIf="allergy.StatusType=='1'">
                        {{allergy?.DisplayName || allergy?.allergyName}}
                        <span class="remove-tag ml-1" (click)="removeSelectedAllergy(allergy)">×</span>
                      </span>
                    </span>
                    <input type="text" #allergySearch class="form-control input-tag"
                      placeholder='{{"PATIENT.EDITPROFILE.SearchAllergiesPlaceHolder" | translate}}'
                      formControlName="current_allergies" />
                  </div>
                  <div *ngIf="allergies?.length>0" class="auto-search p-2 mt-2" infiniteScroll
                    [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                    (scrolled)="onScrollDownSearchAllergies(allergySearch.value)" [scrollWindow]="false">
                    <div class="item p-2" *ngFor="let item of allergies;let i=index">
                      <input [id]="item.AllergenId" type="checkbox" class="mr-1" [checked]="item.checked==true"
                        (change)="selectedAllergie(item,$event)" /><label [for]="item.AllergenId">
                        {{item?.allergyName}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <p class="mb-1 mt-3">
                <!-- AllergiesLabelText -->
                {{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.PreviousAllergies' | translate}}
              </p>
              <div appSearchDropdown (clickOutside)="resetFields('previous_allergy')">
                <div class="position-relative">
                  <div
                    class="editor-tag p-2 keyTerms d-flex family-member-patient-list-scroller custom-scroller scroller"
                    (click)="focusInputField('previousAllergy')">
                    <span *ngFor="let allergy of selectedAllergies ; let i=index">
                      <span class="tag-item d-flex px-1 mr-1" *ngIf="allergy.StatusType=='2'">
                        {{allergy?.DisplayName || allergy?.allergyName}}
                        <span class="remove-tag ml-1" (click)="removeSelectedAllergy(allergy)">×</span>
                      </span>
                    </span>
                    <input type="text" #previousAllergySearch class="form-control input-tag"
                      placeholder='{{"PATIENT.EDITPROFILE.SearchAllergiesPlaceHolder" | translate}}'
                      formControlName="previous_allergies" />
                  </div>
                  <div *ngIf="previousAllergies?.length>0" class="auto-search p-2 mt-2" infiniteScroll
                    [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                    (scrolled)="onScrollDownPreviousAllergies(previousAllergySearch.value)" [scrollWindow]="false">
                    <div class="item p-2" *ngFor="let item of previousAllergies;let i=index">
                      <input [id]="item.AllergenId" type="checkbox" class="mr-1" [checked]="item.checked==true"
                        (change)="selectedAllergie(item,$event)" /><label [for]="item.AllergenId">
                        {{item?.allergyName}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- add doc -->
          <p class="mt-3 mb-1">
            <!-- DoctorAddLabelText -->
            {{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.DoctorAddLabelText' | translate}}
          </p>
          <div class="row">
            <div class="col-lg-3 mt-1">
              <div class="form-check-inline ">
                <label class="form-check-label font-600">
                  <!-- YesText -->
                  <input type="radio" class="form-check-input radio-btn" name="optradio"
                    (change)="doctorSectionToggle('yes')">{{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.YesText' |
                  translate}}
                </label>
              </div>

              <div class="form-check-inline">
                <label class="form-check-label font-600">
                  <!-- NoText -->
                  <input type="radio" checked class="form-check-input radio-btn" name="optradio"
                    (change)="doctorSectionToggle('no')">{{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.NoText' |
                  translate}}
                </label>
              </div>
            </div>
          </div>

          <!-- DOB -->
          <div class="row" *ngIf="showDoctorVisitedDate">
            <!-- LastVisitToDoctorLabelText -->
            <p class="mb-1 mt-4">{{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.LastVisitToDoctorLabelText' |
              translate}}</p>
            <div class="col-md-4 mt-0">
              <div class="position-relative">
                <i class="desc-icon fa fa-2x fa-sort-desc" aria-hidden="true"></i>
                <select id="inputState" placeholder="Address 1" class="form-control form-control-lg"
                  formControlName="doctorVisitYear" (change)="dateofbirthSelection('year')">
                  <option *ngFor="let year of years">{{year}}</option>
                </select>
                <small
                  *ngIf="patientRegistrationForm.controls['doctorVisitYear'].hasError('required') && patientRegistrationForm.controls['doctorVisitYear'].touched"
                  class="text-danger ">{{'AUTHENTICATION.COMPLETEREGISTRATION.DOByearValidator' | translate}}</small>
              </div>
            </div>
            <div class="col-md-4 mt-0">
              <div class="position-relative">
                <i class="desc-icon fa fa-2x fa-sort-desc" aria-hidden="true"></i>
                <select id="inputState" placeholder="Address 1" class="form-control form-control-lg"
                  [ngClass]="(!patientRegistrationForm.controls['doctorVisitYear'].value || patientRegistrationForm.controls['doctorVisitYear'].value=='Year')?'class-disabled':''"
                  formControlName="doctorVisitMonth" (change)="dateofbirthSelection('month')">
                  <option *ngFor="let month of months">{{month}}</option>
                </select>
                <small
                  *ngIf="patientRegistrationForm.controls['doctorVisitMonth'].hasError('required') && patientRegistrationForm.controls['doctorVisitMonth'].touched"
                  class="text-danger ">{{'AUTHENTICATION.COMPLETEREGISTRATION.DOByearValidator' | translate}}</small>
              </div>
            </div>
            <div class="col-md-4 mt-0">
              <div class="position-relative">
                <i class="desc-icon fa fa-2x fa-sort-desc" aria-hidden="true"></i>
                <select id="inputState" placeholder="Address 1" class="form-control form-control-lg"
                  [ngClass]="(!patientRegistrationForm.controls['doctorVisitMonth'].value || patientRegistrationForm.controls['doctorVisitMonth'].value=='Year')?'class-disabled':''"
                  formControlName="doctorVisitDate" (change)="dateofbirthSelection('day')">
                  <option *ngFor="let day of days">{{day}}</option>
                </select>
                <small
                  *ngIf="patientRegistrationForm.controls['doctorVisitDate'].hasError('required') && patientRegistrationForm.controls['doctorVisitDate'].touched"
                  class="text-danger ">{{'AUTHENTICATION.COMPLETEREGISTRATION.DOByearValidator' | translate}}</small>
              </div>
            </div>
            <span *ngIf="!dateValidation" class="danger-text">{{'PATIENT.ADDFAMILYMEMBER.DateValidation' |
              translate}}</span>
          </div>
          <!-- checkboxs -->

          <div class="row mt-3">
            <!-- HealthProblemsLabelText -->
            <p class="mb-1">{{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.HealthProblemsLabelText' | translate}}</p>

            <div class="container">
              <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                <div class="col" *ngFor="let item of healthProblems;let i = index">
                  <div class="form-check pb-1">
                    <input class="form-check-input" type="checkbox" [value]="item.healthProblemId"
                      [id]="item.healthProblemId" (change)="checkHealthProblems($event)">
                    <label class="form-check-label" [for]="item.healthProblemId">
                      {{item.healthProblemName}}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!-- save btn-->
            <div class="d-flex justify-content-center p-2">
              <!-- SaveButtonText -->
              <button type="button" class="btn btn-success btn-lg mt-lg-n3 pre-auth-btn2 px-5 "
                [disabled]="patientRegistrationForm.invalid"
                (click)="navigateToLogin()">{{'AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.SaveButtonText'|
                translate}}</button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>

  <!-- Modal -->
  <div class="modal fade family-member-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content  ">
        <div class="modal-header">
          <!-- CropImageLabelText -->
          <h5 class="modal-title" id="exampleModalLabel">
            {{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.CropImageLabelText"| translate }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-8">
              <image-cropper [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                [resizeToWidth]="256" format="png" (imageCropped)="cropImg($event)">
              </image-cropper>
            </div>
            <div class="col-md-4">
              <!-- ImagePreviewLabelText -->
              <h6>
                {{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.ImagePreviewLabelText"| translate }}
              </h6>
              <img class="w-100" [src]="cropImgPreview" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- SaveButtonText -->
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="uploadImage()">
            {{ "AUTHENTICATION.PATIENTCOMPLETEREGISTRATION2.SaveButtonText"| translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>