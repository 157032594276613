<div *ngIf="bLoading">
      <div class="spinner-grow spinner-grow-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
</div>
<div *ngIf="!bLoading">
  <ng-container *ngIf="!oldBrowser">
    <router-outlet></router-outlet>
  </ng-container>
  <ng-container *ngIf="oldBrowser">
    <div class="center">
        <div class="card shadow">
           <div class="card-header ">
               <h1 class="mb-0 text-danger d-flex align-items-center"> <i class="fa-solid fa-triangle-exclamation mr-2" aria-hidden="true"></i> Incompatible Browser</h1>
           </div>
           <div class="card-body">
               <p class="text-center font-24 font-600">
                   The browser <span class="text-info"> {{broswerNameVersion.browserName}} {{broswerNameVersion.browserVersion}}</span> is not supported to run this application. <br>
                   Please upgrade to the latest version of <span class="text-info">{{broswerNameVersion.browserName}}.</span><br>
                   Thanks
               </p>
           </div>
        </div>
    </div>
</ng-container>
</div>