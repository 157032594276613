import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.css']
})
export class BlankComponent implements OnInit {
  displayHippaElement: boolean = true;

  constructor( private themeService: ThemeService, private dataService: DataService ) {
    this.dataService.displayHippaElement.subscribe(res => {
      setTimeout(() => {
        this.displayHippaElement = res;
        console.log("check received =====>>>.", res);
      }, 0);
    });
  }

  ngOnInit(): void {
    this.themeService.incommingBgBodyColor.subscribe(res => {
      let bodyBg = document.getElementsByTagName('body')[0];
      bodyBg.id = 'custom-class';
      bodyBg.style.background = res;
      console.log('incoming response:::', res);
    });
  }
}
