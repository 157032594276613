import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  incommingBgBodyColor = new BehaviorSubject<any>("");
  incommingMenuBarColor = new BehaviorSubject<any>("");
  incommingMenuBarTextColor = new BehaviorSubject<any>("");
  clientImageUrl = new BehaviorSubject<any>("");
  clientDetails = new BehaviorSubject<any>("");
  
  constructor(private  dataService : DataService, private location : Location) { }

  ThemeService_GetClientDetails()
  {
      console.log("Host is", this.location['_locationStrategy']['_platformLocation']['_location'].host);
    let host : string = this.location['_locationStrategy']['_platformLocation']['_location'].host;
    
    if(host.includes('localhost') || host.includes('127.0.0.1'))
    {host= 'http://'+host}
    else
    {host = 'https://'+host}
    let body = { domain: host};
      this.dataService.DataService_GetClientDetails(body).subscribe(result =>
        {
          console.log("ThemeService_GetClientDetails ==>>>> Response : ",result);
          if (result.status === "success") 
          {
            localStorage.setItem('backgroundColor',result.data.backgroundColor);
            localStorage.setItem('menuBarColor',result.data.menuBarColor);
            localStorage.setItem('menuBarTextColor',result.data.menuBarTextColor);
            localStorage.setItem('cloudName', result.data.name);
            localStorage.setItem('clientImage',result.data.profileImage);
            localStorage.setItem('Base64ClientImage',result.data.profileImageData);
            localStorage.setItem('lsLanguage',result.data.defaultLanguage?result.data.defaultLanguage:'English');
            localStorage.setItem('IdOfClient',result.data.clientId)
            this.clientImageUrl.next("data:image/png;base64,"+result.data.profileImageData)
            this.SetBackgroundColor()  
          }
          else
          {
            this.clientDetails.next(result)
          }
        },(error)=>{
          console.error("DataService_GetClientDetails: ERROR===>>>>",error);
          this.clientDetails.next(error)
        });
  }
  SetBackgroundColor()
  {
    this.incommingBgBodyColor.next(localStorage.getItem('backgroundColor'));
    this.incommingMenuBarColor.next(localStorage.getItem('menuBarColor'))
    this.incommingMenuBarTextColor.next(localStorage.getItem('menuBarTextColor'))
  }
}
