import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class cHeadersInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let Headers =
    {
      'Accept': '*/*',
      'x-app-name': 'main',
      'x-org-name': 'cloud',
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(environment.AccessToken)
    }

    if (request.url.includes('ajaxLogin') || request.url.includes('preauth_function')) delete Headers['Authorization'];
    if (request.url.includes('/upload')) delete Headers['content-type'];

    let requestWithHeaders = request.clone({ setHeaders: Headers });
    return next.handle(requestWithHeaders);

  };
}

