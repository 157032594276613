<div class="terms-condition">
    <h6 class="text-center font-700">Website Customer Service Terms of Use</h6>
    <article>
      <p>
        Welcome to the &lt;&lt; Corstet &gt;&gt; LLC (“&lt;&lt; Platform &gt;&gt; ”, “we”, “us”, and
        “our”) Website (the “Site”). (Note: “Site” as used herein shall include <a
          href="https://corstet.com/">https://corstet.com/</a> and all associated pages contained thereon.)
        Please review the following basic terms that govern your use of this Site (this “Agreement” or “Terms
        of Use”). This Site is operated and controlled by &lt;&lt; Platform &gt;&gt;. All uses of the &lt;
        &lt; Platform &gt;&gt; Website are subject to the following terms and conditions. &lt;&lt; Platform
        &gt;&gt; grants you permission to view this Site and to download and print individual pages from this
        Site and, if applicable, schedule appointments, communicate with &lt;&lt; Platform &gt;&gt;, or
        order products and/or services from this Site for your own personal, noncommercial use, provided that
        you agree to and accept without modification the notices, terms and conditions set forth in this
        Agreement. You may not modify, copy (except as set forth in the preceding sentence), distribute,
        transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or
        sell any information, material, software, products or services from this Site. No right title or
        interest in any downloaded materials is transferred to you as a result of any such downloading,
        copying or printing.
      </p>
      <p>
        <strong>
          Your access or other use of this Site shall constitute your agreement and acceptance without
          modification of the notices, terms and conditions set forth herein (“Terms & Conditions”).
        </strong>
        Any person or entity who interacts with the Site through the use of crawlers, robots, browsers, data
        mining or extraction tools, or other functionality, whether such functionality is installed or placed
        by such person or entity or a third party, is considered to be using the Site. In addition, as a
        condition of your use of this Site, you represent and warrant to &lt;&lt; Platform &gt;&gt; that you
        will not use this Site for any purpose that is unlawful, immoral, or prohibited by these terms,
        conditions and notices.
        <strong>
          If you do not agree and accept (or cannot comply) without modification to the notices, terms and
          conditions set forth herein, do not use this Site. If at any time you cease to agree with these
          terms and conditions, you must immediately cease using the Site.
        </strong>
      </p>

      <h6 class="text-center font-700">
        Use of this Site
      </h6>
      <p>
        By accepting these Terms of Use through your use of the Site, you certify that you are eighteen (18)
        years of age or older. If you are under eighteen (18), you may use the Site only with involvement of a
        parent or guardian. “&lt;&lt; Platform &gt;&gt; reserves the right to refuse service, remove or edit
        content in their sole discretion.
      </p>
      <p>
        All content or materials on this Site, including, but not limited to, images, text, illustrations,
        designs, icons, photographs, graphics, logos, programs, music clips or downloads, video clips, audio
        clips, data, software, and all other materials that are part of this Site (collectively, the
        “Contents”) is owned, licensed, or used with permission by &lt;&lt; Platform &gt;&gt;. &lt;&lt;
        Platform &gt;&gt; grants to you a limited license to access and make use of the Site and its
        Contents. You may download, order, or copy the Contents and other downloadable materials displayed on
        the Site for your personal, noncommercial use only and only in any manner permitted in the normal use
        and operation of the Site. No right, title or interest in any downloaded materials or software is
        transferred to you as a result of any such downloading, ordering or copying. You may not reproduce
        (except as noted above), publish, transmit, distribute, display, modify, create derivative works from,
        sell or participate in any sale of or exploit in any way, in whole or in part, any of the Contents,
        the Site or any related
        software. All software used on this Site is the property of &lt;&lt; Platform &gt;&gt; or its
        suppliers and protected by U.S. and international copyright laws.
      </p>
      <p class="text-center ">Disclaimer</p>
      <p>
        The information and content provided on or accessed through this Site are intended for general,
        noncommercial informational purposes only, and do not constitute the practice of medical or other
        professional judgment, advice, diagnosis or treatment and should not be considered or used as a
        substitute for independent professional judgment, advice, diagnosis or treatment of a duly licensed
        and qualified healthcare provider. The use of this Site alone does not create a doctor-patient
        relationship and does not obligate &lt;&lt; Platform &gt;&gt; to follow-up or contact users of the
        Site. Through this Site you may be referred to an independent healthcare provider. You should always
        talk to your healthcare professionals for diagnosis and treatment, including information regarding
        which drugs or treatment may be appropriate for you. Statements made by any persons on this Site is
        not intended to substitute for discussion or evaluation with your healthcare professional or provide
        any guarantee as to outcomes.
      </p>
      <p>
        If you are experiencing a medical emergency, you should seek appropriate emergency medical assistance
        immediately and call 911
      </p>

      <p>
        This Site is intended for use by individuals who are located within the United States. If you live
        outside the United States, you may see information on the Sites about products or therapies that are
        not authorized in your country.
      </p>

      <p>
        &lt;&lt; Platform &gt;&gt; does not recommend or endorse any specific tests, products, equipment,
        procedures, opinions or other information that may be mentioned on the Site. Reliance on any
        information provided by &lt;&lt; Platform &gt;&gt;, its employees, medical staff, and others appearing
        on the Site at the invitation of &lt;&lt; Platform &gt;&gt; or other visitors or contributors to the
        Site is solely at your own risk.
      </p>

      <h6 class="text-center font-700">
        Proper Use of This Site
      </h6>
      <p>
        This Site and its content are provided by &lt;&lt; Platform &gt;&gt; solely to users over the age of
        18. This website is not directed for use by children under the age of 18. Users under the age of 18
        should get the assistance of a parent or guardian to use this site.
      </p>
      <h6 class="text-center font-700">
        Site Security/Prohibited Uses
      </h6>
      <p>
        You are prohibited from violating or attempting to violate the security of the Site, including,
        without limitation;
      </p>
      <p>
        (a) accessing data not intended for such user or logging onto a server or an account which the user is
        not authorized to access;
      </p>
      <p>
        (b) attempting to probe, scan or test the vulnerability of a system or network or to breach security
        or authentication measures without proper authorization;
      </p>
      <p>
        (c) attempting to interfere with service to any user, host or network, including, without limitation,
        via means of submitting a virus to the Site, overloading, "flooding," "spamming," "mailbombing", or
        "crashing;"
      </p>
      <p>
        (d) sending unsolicited email, including promotions and/or advertising of products or services;
      </p>

      <p>
        (e) forging any TCP/IP packet header or any part of the header information in any email or newsgroup
        posting;
      </p>

      <p>
        (f) misrepresenting your identity or impersonating any other person or entity;
      </p>
      <p>
        (g) using any device, software or routine to interfere or attempt to interfere with the proper working
        of this Site or any activity being conducted on this Site;
      </p>
      <p>
        (h) restricting or inhibiting any person from using the Site and Platforms, disclosure of personal
        information obtain from the Site and Platforms or collecting information about users of the Site and
        platforms;
      </p>

      <p>
        (i) reverse engineering, disassembling or decompiling any section or technology on the Site or
        attempting to do any of the forgoing
      </p>
      <p>
        (j) gaining unauthorized access to the site, to other users’ accounts, names, personally identifiable
        information or other information, or to other computers or websites connected or linked to the Site;
      </p>
      <p>
        (k) accessing or attempt to access any portion of the Site to which you have not been explicitly
        granted access;
      </p>
      <p>
        (l) use this Site in violation of any local, state, national or international laws;
      </p>
      <p>
        (m) attempting to disable, “hack”, or otherwise interfere with the proper functioning of this Site;
      </p>
      <p>
        (n) use of this Site as a means to distribute advertising or other unsolicited material to any third
        party;
      </p>
      <p>
        (o) launching or using any automated system, including without limitation, “robots,” “spiders,” or
        “offline readers,” that access the Site in a manner that sends more request messages to our servicers
        in a given period of time than a human can reasonably produce in the same period by using a
        conventional browser;
      </p>
      <p>
        (p) posting or otherwise transmitting any information or software that contains a virus, worm, time
        bomb, Trojan horse, or other harmful or disruptive component that may compromise the security of the
        Site;
      </p>
      <p>
        (q) using the Site to violate the legal rights (including the rights of publicity and privacy) of
        others or violate the laws of any jurisdiction or location; or
      </p>
      <p>
        (r) using or attempting to use any engine, software, tool, agent or other device or mechanism
        (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or
        search this Site other than the search engine and search agents available from &lt;&lt; Platform
        &gt;&gt; on
        this Site and other than generally available third-party web browsers (e.g., Firefox, Internet
        Explorer, Chrome, or Safari).
      </p>
      <p>
        Such conduct is unacceptable and may result in the termination of your use of the Site. If you use any
        part of the Site requiring secure access, you are responsible for maintaining the confidentiality of
        your account and password and for restricting access to your computer, and you agree to accept
        responsibility for all activities that occur under your account or password.
      </p>
      <p>
        Violations of system or network security may result in civil or criminal liability. &lt;&lt; Platform
        &gt;&gt; will investigate occurrences that may involve such violations and may involve, and cooperate
        with, law enforcement authorities in prosecuting users who are involved in such violations.
      </p>
      <h6 class="font-700 text-center">Content Information</h6>
      <p>
        You understand that by using this Site or any services provided on the Site, you may encounter content
        that may be deemed by some to be offensive, indecent, or objectionable, which content may or may not
        be identified as such. You agree to use the Site and any service at your sole risk and that &lt;&lt;
        Platform &gt;&gt;, and its affiliates shall have no liability to you for content that may be deemed
        offensive, indecent, or objectionable.
      </p>
      <p>
        You agree that any content you may generate or add to or through the Site shall: be true and accurate;
        will comply with all applicable laws or regulations; not be submitted for any unlawful purpose; not
        violate the rights of any third party; not cause injury to any person or entity; not contain any
        profane or obscene content; not contain or be any form of malware, “spam”, or commercial solicitation;
        and, be in conformity with the Terms & Conditions contained herein. You will solely be responsible for
        any user generated content and &lt;&lt; Platform &gt;&gt; assumes no liability for any content
        submitted by you.
      </p>
      <h6 class="font-700 text-center">Unlawful or Prohibited Uses of Site</h6>
      <p>
        Your use of this Site must comply with any and all applicable laws and shall not be for any unlawful
        purpose. You agree that your use or access of this Site shall be in accordance with the limited
        license granted to you in these Terms & Conditions. You warrant to &lt;&lt; Platform &gt;&gt; that you
        shall not use this Site for any purpose that is unlawful or prohibited by these Terms & Conditions.
        You hereby agree to indemnify&lt;&lt; Platform &gt;&gt; for any liability which may ensue as a
        consequence of your unlawful or prohibited use of this Site. The following are examples of prohibited
        or unlawful use of the Site or its contents: use or attempted commercial use of the Site or any
        content on the Site; conducting fraudulent activities on the Site; use of the Site to send any
        unauthorized communications to any third-party; attempting to access the Site and obtain personal
        information from any other users of the Site; attempts to restrict use or limit access to the Site;
        downloading or use of any content on the Site for any commercial nonpersonal purpose; use or attempt
        to use any software, engine, tool, data, agent, or other device or mechanism (including browsers,
        spiders, robots, avatars or intelligent agents) to navigate or search the Site other than the search
        engine and search agents provided by &lt;&lt; Platform &gt;&gt; or generally publicly available
        browsers; any duplication of the Site or its contents for any commercial use; any data mining or
        extraction; any download or storage of Site content, except pursuant to the limited license granted by
        these Terms & Conditions; use of any meta tags or hidden text using any of &lt;&lt; Platform
        &gt;&gt;’s intellectual property; any attempts to tamper with the Site’s use or functionality; any
        attempts to place any unauthorized advertisement on the Site; any attempt to alter, modify, reverse
        engineer, disassemble, or otherwise change any portion of the Site; use of the Site to harass,
        threaten, abuse, defame, stalk or otherwise infringe upon the legal rights of other; any breach or
        attempted breach of the privacy rights of others; and, the tampering or attempted tampering of these
        Terms & Conditions or the removal of any copyright, trademark, servicemark, or other proprietary
        rights notice from the Site.

      </p>

      <h6 class="font-700 text-center">Intellectual Property</h6>
      <p>
        The Site and its entire contents, features, and functionality (including but not limited to all
        information, software, text, displays, images, video, and audio, and the design, selection, and
        arrangement thereof) (collectively, “Content”) are owned by &lt;&lt; Platform &gt;&gt;, its licensors,
        or other providers of such material and are protected by United States and international copyright,
        trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
      </p>
      <p>
        All trademarks, service marks and logos or copyrights displayed and used on these Sites are the
        property of their respective owners. Nothing on the Sites should be construed as granting any right or
        license to use any trademark without the written permission of its owner. Any other use of the
        materials is strictly prohibited without the prior written permission of &lt;&lt; Platform &gt;&gt;
        and the
        permission of the applicable rights holder(s). The content and design of the Site is protected by
        U.S. and international copyright laws. You may not copy, reproduce, republish, upload, post,
        display, transmit or frame any of these materials without prior written consent from the copyright
        owners, except that you may view, download, display and print a single copy of these materials on a
        single computer for personal, noncommercial use only, so long as: you do not alter or modify the
        materials in any way; you include all applicable notices and disclaimers (including copyright
        notices); and you do not use the materials in a way that suggests an association with &lt;&lt;
        Platform &gt;&gt;.
      </p>
      <h6 class="font-700 text-center">User Reviews, Feedback, Submissions</h6>
      <p>
        For all reviews, comments, feedback, postcards, suggestions, ideas, forum posts, and other submissions
        disclosed, submitted or offered to &lt;&lt; Platform &gt;&gt; on or through this Site, by e-mail,
        social media,
        telephone, or otherwise disclosed, submitted or offered in connection with your use
        of this Site (collectively, the “Comments”) you grant &lt;&lt; Platform &gt;&gt; a royalty-free,
        irrevocable,
        transferable right and license to use the Comments however &lt;&lt; Platform &gt;&gt; desires,
        including without
        limitation, to copy, modify, delete in its entirety, adapt, publish, translate, create
        derivative works from and/or sell and/or distribute such Comments and/or incorporate such
        Comments into any form, medium or technology throughout the world.
      </p>
      <p>
        &lt;&lt; Platform &gt;&gt; will be entitled to use, reproduce, disclose, modify, adapt, create
        derivative works
        from, publish, display and distribute any Comments you submit for any purpose whatsoever, without
        restriction and without compensating you in any way. &lt;&lt; Platform &gt;&gt; is and shall be under
        no
        obligation (1) to maintain any Comments in confidence; (2) to pay to user any compensation for any
        Comments; or (3) to respond to any user Comments. You agree that any Comments submitted by you to
        the Site will not violate the terms in this Agreement or any right of any third party, including
        without limitation, copyright, trademark, privacy or other personal or proprietary right(s), and
        will not cause injury to any person or entity. You further agree that no Comments submitted by you
        to the Site will be or contain libelous or otherwise unlawful, threatening, abusive or obscene
        material, or contain software viruses, political campaigning, commercial solicitation, chain
        letters, mass mailings or any form of “spam”.
      </p>
      <p>
        &lt;&lt; Platform &gt;&gt; does not regularly review posted Comments but does reserve the right (but
        not the
        obligation) to monitor and edit or remove any Comments submitted to the Site. You grant &lt;&lt;
        Platform &gt;&gt;
        the right to use the name that you submit in connection with any Comments. You agree not to use a
        false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of
        any Comments you submit. You are and shall remain solely responsible for the content of any
        Comments you make, and you agree to indemnify &lt;&lt; Platform &gt;&gt; and its affiliates for all
        claims
        resulting from any Comments you submit. &lt;&lt; Platform &gt;&gt; and
        its affiliates take no responsibility and assume no liability for any Comments submitted by

        you or any third party.
      </p>
      <h6 class="font-700 text-center">Indemnification</h6>
      <p>
        You agree to defend, indemnify and hold harmless &lt;&lt; Platform &gt;&gt; and its affiliates,
        employees,
        directors, officers, agents, vendors, volunteers, healthcare providers, medical staff, nurses,
        business associates, and suppliers from and against any and all claims, damages, liabilities,
        losses, investigations, costs and expenses, including attorneys’ fees, arising from or related to
        your use of this Site or any breach or alleged breach by you of this Agreement, including the Terms
        & Conditions and any user content submitted by you and any violation of any laws, rules,
        regulations, codes, statutes, ordinances, or orders of any governmental or quasi-governmental
        authorities, you violation of the rights of any third party, including any intellectual property,
        publicity, confidentiality, property, or privacy rights, or any misrepresentations made by you.
        &lt;&lt; Platform &gt;&gt; reserves the right to assume exclusive defense and control of any matter
        otherwise
        subject to indemnification by you and, in any case, you agree to cooperate with &lt;&lt; Platform
        &gt;&gt; if
        and as requested in the defense and settlement of such matter.
      </p>
      <h6 class="font-700 text-center">Digital Millennium Copyright Act Notice</h6>
      <p>
        Procedure for Making and Responding to Claims of Copyright Infringement:
      </p>
      <p>
        &nbsp;&nbsp;It is the policy of &lt;&lt; Platform &gt;&gt; to respond to claims of copyright
        infringement. We will
        promptly process and investigate notices of alleged infringement by third parties and will take
        appropriate actions under the Digital Millennium Copyright Act, Title 17, United States Code, Section
        512(c)(2) (the "DMCA"), where applicable.
      </p>
      <p>
        &nbsp;&nbsp;Pursuant to the DMCA, notifications of claimed copyright infringement by third parties
        should be sent
        to &lt;&lt; Platform &gt;&gt; Designated Agent. If you believe that your copyrighted work has been
        infringed under U.S. copyright law and is accessible on this Site, please notify us by contacting our
        Designated Agent. Even if you believe that the alleged infringing work was not posted by a third
        party, please send all notifications of claimed copyright infringement to &lt;&lt; Platform &gt;&gt;’s
        Designated Agent listed below.
      </p>
      <p>
        &nbsp;&nbsp;To be effective under the DMCA, notification of claims of copyright infringement by third
        parties must
        be a written communication to &lt;&lt; Platform &gt;&gt; Designated Agent that includes the following:
      </p>
      <ol>
        <li>
          A physical or electronic signature of a person authorized to act on behalf of the owner of an
          exclusive right that is allegedly infringed;
        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted
          works at a single online site are covered by a single notification, a representative list of such
          works at that site;
        </li>
        <li>
          Identification of the material that is claimed to be infringing or to be the subject of infringing
          activity and that is to be removed or access to which is to be disabled, and information reasonably
          sufficient to permit &lt;&lt; Platform &gt;&gt; to locate the material;
        </li>
        <li>
          Information reasonably sufficient to permit &lt;&lt; Platform &gt;&gt; to contact the complaining
          party, such as
          an address, telephone number, and, if available, an electronic mail address at which the
          complaining party may be contacted;
        </li>
        <li>
          A statement that the complaining party has a good faith belief that use of the material in the
          manner complained of is not authorized by the copyright owner, its agent, or the law; and
        </li>
        <li>
          A statement that the information in the notification is accurate, and under penalty of perjury, that
          the complaining party is authorized to act on behalf of the owner of an exclusive right that is
          allegedly infringed.
        </li>
      </ol>

      <p>
        &nbsp;&nbsp; &lt;&lt; Platform &gt;&gt;’s Designated Agent for notice of claims of copyright
        infringement can be reached as follows:
      </p>

      <p>By e-mail: &#123; info&#64;corstet.com &#125;</p>

      <strong>
        This contact information is only for reporting claims of copyright infringement. Contact information
        for other matters is provided elsewhere on this Site.
      </strong>

      <p>
        &nbsp;&nbsp; Upon receipt of a valid notification of alleged copyright infringement by a third party,
        &lt;&lt; Platform &gt;&gt; shall remove or disable access to the material identified in the notice,
        forward the written notification to the alleged infringer, and take reasonable efforts to notify the
        alleged infringer that it has removed or disabled access to this material.
      </p>

      <p>
        &nbsp;&nbsp;You should be aware that, under the DMCA, claimants who make misrepresentations concerning
        copyright infringement may be liable for any damages, including costs and attorneys' fees, incurred by
        the alleged infringer or by &lt;&lt; Platform &gt;&gt;, if injured by relying upon such
        misrepresentation in removing or disabling access to the material or activity claimed to be
        infringing.
      </p>
      <p>
        &nbsp;&nbsp;If a notice of copyright infringement has been filed against you, you may file a counter
        notification with the Designated Agent at the address listed above.
      </p>
      <p>
        &nbsp;&nbsp;To &nbsp; be &nbsp; effective, &nbsp; a &nbsp; counter &nbsp; notification &nbsp; must
        &nbsp; be &nbsp; a &nbsp; written communication provided to &lt;&lt; Platform &gt;&gt;’s Designated
        Agent that includes the following:
      </p>
      <ol>
        <li>A physical or electronic signature; </li>
        <li>Identification of the material that has been removed or to which access has been disabled and the
          location at which the material appeared before it was removed or access to it was disabled; </li>
        <li>A statement under penalty of perjury that you have a good faith belief that the material was
          removed or disabled as a result of
          mistake or misidentification of the material to be removed or disabled; and </li>
        <li>
          Your name, address, and telephone number, and a statement that you consent to the jurisdiction of
          Federal District Court for the judicial district in which the address is located and that you will
          accept service of process from the complainant who provided the original notification or an agent of
          such person or entity.
        </li>
      </ol>
      <p>
        &nbsp;&nbsp;If &lt;&lt; Platform &gt;&gt; receives a valid counter notification, it shall provide the
        complainant with a copy of the counter notification, inform the complainant that it will replace the
        removed material or cease disabling access to it in 10 to 14 days from receipt of the counter
        notification, and replace the removed material or cease disabling access to it in 10 to 14 business
        days, provided that &lt;&lt; Platform &gt;&gt; has not received notice from the complainant that an
        action has been filed seeking a court order to restrain the alleged infringer from engaging in
        infringing activity relating to the material on &lt;&lt; Platform &gt;&gt;’s system.
      </p>

      <p>
        &nbsp;&nbsp;You should be aware that the DMCA provides substantial penalties for a false counter
        notice filed in response to a notice of copyright infringement. Claimants who make misrepresentations
        in the counter notification statement may be liable for any damages, including costs and attorneys'
        fees, incurred by any copyright owner or copyright owner's authorized licensee, or by &lt;&lt;
        Platform &gt;&gt;, if injured by relying upon such misrepresentation in replacing the removed material
        or ceasing to disable access to it.
      </p>
      <h6 class="font-700 text-center">Termination/Survival</h6>
      <p>
        These Terms of Use are effective unless and until terminated by either you or &lt;&lt; Platform
        &gt;&gt;. You may terminate these Terms of Use at any time, provided that you discontinue any further
        use of this Site. &lt;&lt; Platform &gt;&gt; also may terminate these Terms of Use at any time and may
        do so immediately without notice, and accordingly deny you access to the Site,
        if in &lt;&lt; Platform &gt;&gt;’s sole discretion, you fail to comply with any term or provision of
        these Terms of Use. Upon any termination of these Terms of Use by either you or &lt;&lt; Platform
        &gt;&gt;, you must promptly destroy all materials downloaded or otherwise obtained from this Site, as
        well as all copies of such materials, whether made under the Terms of Use or otherwise. The following
        sections shall survive any termination of these Terms of Use: “Comments,” “Site Security,” “Content
        Information,” “Unlawful or Prohibited Uses of Site”, “User Reviews, Feedback and Submissions,”
        “Indemnification,” “Termination,” “Disclaimer”, “Digital Millennium Copyright Act Notice”, “Statute of
        Limitations”, “Limitation of Liability,” “Privacy”, “Healthcare Information” and “General.”
      </p>

      <p>
        We may suspend or terminate your access to the Site, in whole or in part, at any time, without notice,
        for any reason, and without any obligation to you or any third party. Any suspension or termination
        will not affect your obligations to &lt;&lt; Platform &gt;&gt; under these Terms or any applicable
        Additional Terms. Upon suspension or termination of your access to the Site, or upon notice from
        &lt;&lt; Platform &gt;&gt;, all rights granted to you under these Terms or any Additional Terms will
        cease immediately, and you agree that you will immediately discontinue use of the Site. The provisions
        of these Terms and any applicable Additional Terms which by their nature should survive your
        suspension or termination will survive, including the rights and licenses you grant to &lt;&lt;
        Platform &gt;&gt; in these Terms as well as the indemnities, releases, disclaimers, and limitations on
        liability and the provisions regarding jurisdiction, choice of law, no class action, jury waiver, and
        mandatory arbitration.
      </p>
      <h6 class="font-700 text-center">Disclaimer</h6>
      <p class="text-uppercase">
        AS PERMITTED BY APPLICABLE LAW, YOUR ACCESS TO AND USE OF THIS SITE IS AT YOUR SOLE RISK. THIS SITE IS
        PROVIDED BY &lt;&lt; Platform &gt;&gt; AND ALL CONTENT, MATERIALS, PRODUCTS, SERVICES, FUNCTIONALITY,
        AND OTHER ITEMS INCLUDED ON OR OTHERWISE MADE
        AVAILABLE THROUGH THIS SITE, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. &lt;&lt; Platform
        &gt;&gt; MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT
        LIMITED TO IMPLIED WARRANITES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON
        INFRINGMENT. &lt;&lt; Platform &gt;&gt; MAKES NO WARRANTIES AS TO THE OPERATION OF THE SITE OR THE
        INFORMATION, CONTENT, OR MATERIALS INCLUDED ON THIS SITE. &lt;&lt; Platform &gt;&gt; DISCLAIMS ANY AND
        ALL WARRANTIES, EXPRESSED OR IMPLIED. &lt;&lt; Platform &gt;&gt; ASSUMES NO LIABILITY FOR INACCURACIES
        OR MISSTATEMENTS ABOUT PRODUCTS. CUSTOMER REVIEWS ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY.
        CUSTOMER REVIEWS REFLECT THE INDIVIDUAL REVIEWER’S RESULTS AND EXPERIENCES ONLY AND ARE NOT VERIFIED
        OR ENDORSED BY &lt;&lt; Platform &gt;&gt;. &lt;&lt; Platform &gt;&gt; MAKES NO REPRESENTATIONS OR
        WARRANTIES THAT USE OF THIS SITE WILL BE UNINTURRUPTED OR ERROR-FREE, THAT EFECTS WILL BE CORRECTED,
        OR THAT THIS SITE OR THE TECHNOLOGY THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
        CONTENT. CUSTOMER ACKNOWLEDGES THAT THE USE OF THIS SITE IS AT THE CUSTOMER’S SOLE RISK AND THEY
        ASSUME FULL RESPONSIBILITY FOR THE USE OF THIS SITE. CUSTOMER ACKNOWLEDGES THAT INFORMATION SENT OR
        RECEIVED DURING USE OF THIS SITE MAY BE INTERCEPTED OR OTHERWISE ACCESSED BY UNAUTHORIZED PARTIES.
        CUSTOMER AGREES THAT &lt;&lt; Platform &gt;&gt; IS NOT RESPONSIBLE FOR ANY LOSS OR DAMAGE TO YOUR
        PROPERTY OR DATA THAT MAY RESULT FROM ANY MATERIALS ACCESSED OR DOWNLOADED FROM THIS SITE. USE OF THIS
        SITE DOES NOT CREATE A DOCTOR PAITENT
        RELATIONSHP. NO INFORMATION AVAILABLE ON THIS SITE SHOULD BE INTERPRETED AS MEDICAL ADVICE FROM A
        HEALTH CARE PROFESSIONAL. YOU ACKNOWLEDGE AND AGREE THAT ANY TRANSMISSION TO AND FROM THIS SITE IS NOT
        CONFIDENTIAL AND YOUR COMMUNICATIONS OR PERSONAL INFORMATION MAY BE READ OR INTERCEPTED BY OTHERS. YOU
        FURTHER ACKNOWLEDGE AND AGREE THAT BY SUBMITTING COMMUNICATIONS OR OTHER CONTENT TO US AND/OR BY
        POSTING INFORMATION ON THE SITE, NO CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED, OR OTHER
        RELATIONSHIP IS CREATED BETWEEN YOU AND &lt;&lt; Platform &gt;&gt;. THE DISCLAIMERS AS STATED HEREIN
        SHALL BE TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW.
      </p>
      <p>
        Limitations on implied warranties are not permitted in some states. Therefore, one of more of the
        above limitations may not apply to you. You should check your local laws for any restrictions or
        limitations on limitations of implied warranties.Postings on this Site are made at such times as
        &lt;&lt; Platform &gt;&gt; determines in its discretion. You should not assume that the information
        contained on this Site has been updated or otherwise contains current information. &lt;&lt; Platform
        &gt;&gt; does not review past postings to determine whether they remain accurate and information
        contained in such postings may have been superseded.
      </p>
      <p class="text-uppercase">
        THE INFORMATION AND MATERIALS IN THIS SITE ARE PROVIDED FOR YOUR REVIEW IN ACCORDANCE WITH THE
        NOTICES, TERMS AND CONDITIONS SET FORTH HEREIN. THESE MATERIALS ARE NOT GUARANTEED OR REPRESENTED TO
        BE COMPLETE, CORRECT OR UP TO DATE. THESE MATERIALS MAY BE CHANGED FROM TIME TO TIME WITHOUT NOTICE.
      </p>
      <h6 class="font-700 text-center">General Information</h6>
      <p>
        You agree that this Agreement and your use of this Site are governed by the laws of the State of
        Georgia, USA. You hereby consent to the exclusive jurisdiction and venue of the courts, tribunals,
        agencies and other dispute resolution organizations in Fulton County, Georgia, in all disputes (a)
        arising out of, relating to or concerning this Site and/or this Agreement, (b) in which this Site
        and/or this Agreement is an issue or a material fact, or (c) in which this Site and/or this Agreement
        is referenced in a paper filed in a court, tribunal, agency or other dispute resolution organization.
        Use of this Site is unauthorized in any jurisdiction that does not give full effect to all provisions
        of this Agreement, including without limitation this paragraph and the warranty disclaimers and
        liability exclusions above. &lt;&lt; Platform &gt;&gt; has endeavored to comply with all legal
        requirements known to
        it in creating and maintaining this Site but makes no representation that materials on this Site are
        appropriate or available for use in any particular jurisdiction. Use of this Site is unauthorized in
        any jurisdiction where all or any portion of this Site may violate any legal requirements and you
        agree not to access this Site in any such jurisdiction. You are responsible for compliance with
        applicable laws. Any use in contravention of this provision or any provision of this Agreement is at
        your own risk and, if any part of this Agreement is invalid or unenforceable under applicable law,
        the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision
        that most closely matches the intent of the original provision, and the remainder of the Agreement
        shall govern such use.
      </p>
      <p>
        This Agreement represents the complete understanding between you and &lt;&lt; Platform &gt;&gt; and
        supersedes all
        prior agreements and representations between the parties, you agree that &lt;&lt; Platform &gt;&gt;
        may at any
        time and without notice change the terms, conditions and notices under which this Site is offered.
        Your continued use of this Site shall be construed as an acceptance of any changes to the terms,
        conditions and notices under which this Site is offered.
        You agree that no joint venture, partnership, employment or agency relationship exists between you
        and &lt;&lt; Platform &gt;&gt; as a result of this Agreement or your use of this Site. You may not
        transfer or
        assign any rights or obligations under this Agreement. &lt;&lt; Platform &gt;&gt; may transfer or
        assign its
        rights and obligations under this Agreement.
      </p>

      <h6 class="font-700 text-center">Statute of Limitations</h6>
      <p>
        &nbsp;&nbsp;You hereby agree that you shall provide&lt;&lt; Platform &gt;&gt; at least thirty (30)
        days’ notice of any harm you allege to have suffered as a consequence of using this Site. Before
        seeking legal recourse for such alleged harm, you agree to give&lt;&lt; Platform &gt;&gt; at least
        thirty (30) days to cure the harm before initiating any action. You must initiate any cause of action
        within one year after the claim has arisen, or you will be barred from pursuing any cause of action.
        You acknowledge and agree that this one-year period operates as a statute of limitations for all
        claims.
      </p>

      <h6 class="font-700 text-center">Healthcare Information</h6>

      <p class="text-uppercase">
        <strong>
          &lt;&lt; Platform &gt;&gt; DOES NOT PROVIDE MEDICAL ADVICE THROUGH THIS SITE NOR DOES USE OF THIS
          SITE CREATE A
          DOCTOR PATIENT RELATIONSHIP.
        </strong> ANY HEALTHCARE INFORMATION WHICH MAY BE PROVIDED ON THIS SITE IS FOR EDUCATIONAL PURPOSES
        ONLY. SUCH INFORMATION IS NOT INTENDED TO BE OFFERED AS MEDICAL ADVICE TO YOU IN REGARD TO ANY
        SPECIFIC CONDITION OR TREATMENT. ANY INFORMATIVE INFORMATION PROVIDED IS NOT INTENDED TO TAKE THE
        PLACE OF MEDICAL ADVICE OR SERVICES PROVIDED BY MEDICAL PROFESSIONALS. YOU ARE ENCOURAGED TO DISCUSS
        ANY MEDICAL ISSUE YOU MAY HAVE WITH YOUR HEALTHCARE PROVIDER. ALWAYS CONSULT WITH A MEDICAL
        PROFESSIONAL BEFORE TAKING ANY MEDICATION, INCLUDING OVER THE COUNTER MEDICATIONS OR HERBAL
        SUPPLEMENTS. THIS SITE CANNOT PROVIDE MEDICAL ADVICE AND ONLY YOUR
        HEALTHCARE PROVIDER CAN PROVIDE YOU WITH DIAGNOSTIC OR TREATMENT INFORMATION REGARDING WHAT IS BEST OR
        RECOMMENDED FOR YOU. THIS SITE MAY PROVIDE YOU ACCESS TO AN INDEPENDENT HEALTHCARE PROVIDER WHO MAY
        THEREAFTER PROVIDE YOU WITH MEDICAL ADVICE. &lt;&lt; Platform &gt;&gt; DOES NOT PROVIDE MEDICAL
        ADVICE.
      </p>
      <p class="text-uppercase">
        IF YOU THINK YOU MAY BE HAVING A MEDICAL CONDITION, PLEASE CONTACT 911 OR SEEK IMMEDIATE CARE IF IT IS
        CRITICAL. OTHERWISE, PLEASE CONTACT A QUALIFIED MEDICAL PROVIDER PROMPTLY TO OBTAIN ASSISTANCE.
      </p>
      <h6 class="font-700 text-center">Links to Third Party Websites</h6>
      <p>
        This Site may contain, display, include, or make available content, data, information, applications,
        materials, or links to other third party websites content that is not owned, controlled, or operated
        by &lt;&lt; Platform &gt;&gt;. These links are provided and available to you for your convenience or
        informational
        purposes that &lt;&lt; Platform &gt;&gt; believes may be useful or interesting to you and are intended
        only to
        enable access to these third party sites and for no other purpose. &lt;&lt; Platform &gt;&gt; does not
        approve,
        endorse and is not responsible for the content contained on these other websites. &lt;&lt; Platform
        &gt;&gt;
        makes no representations or warranties to the information or services offered on any third
        party site nor to the accuracy of the content on the linked website. The conditions of use and
        privacy policy of any third party site may differ substantially from the conditions of use and
        legal notices that apply to your use of this site. You should review the conditions of use for
        all third party sites form more information about the terms and conditions that apply to your
        use of third party sites. You acknowledge and agree that you shall be solely responsible for
        any interactions which you may have with these linked websites. You are encouraged to verify
        the information contained therein and review the linked websites terms of use and privacy
        policies.
      </p>

      <h6 class="font-700 text-center">Limitation of Liability</h6>
      <p class="text-uppercase">
        THE USE OF THIS SITE IS ENTIRELY AT YOUR OWN RISK, AND IN NO EVENT WILL &lt;&lt; Platform &gt;&gt; BE
        LIABLE FOR
        DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR ANY OTHER MONETARY OR
        OTHER DAMAGES, FEES, FINES, PENALTITIES, OR LIABILITIES ARISING OUT OF OR RELATING TO IN ANY WAY TO:
        (I) OUR SITE; (II) THESE TERMS; (III) ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY; (IV) USE OF
        THE SITE, CONTENT. TOOLS, OR SERVICES WE OR ANY THIRD PARTY PROVIDES THROUGH THE SITE INCLUDING ANY
        DECISION MADE OR ACTION TAKEN BY YOU OR ANYONE ELSE IN RELIANCE UPON THE INFORMATION OR CONTENT
        CONTAINED ON OR ACCESSED THROUGH THIS SITE; OR (V) THE INTERACTION BETWEEN OUR SITE AND ANY
        THIRD-PARTY SERVICE BY A TOOL OR SERVICE ON OUR SITE. YOUR SOLE AND EXCLUSIVE REMEDEY FOR
        DISSATISFCATION WITH THE SERVICE IS TO STOP USING THE SERVICE. THESE LIMITATIONS AND EXCLUSIONS
        APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM BREACH OF CONTRACT, BREACH OF WARRANTY,
        STRICT LIABILITY, TORT, NEGLIGENCE, OR ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM EXTENT SUCH
        EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW.
      </p>
      <p>
        To the maximum extent permitted by applicable law, you hereby release, and waive all claims against
        &lt;&lt; Platform &gt;&gt; its affiliates and subsidiaries from any and all liability for any and all
        claims, suits,
        damages, fines, penalties, costs, expenses (including costs and attorneys’ fees) or other losses of
        any kind or nature (“Loss”), arising out of or in any way connected with you access to or use or
        disclosure of the Site or its content, whether or not &lt;&lt; Platform &gt;&gt; has been advised of
        the
        possibility of such Loss.
      </p>
      <p class="text-uppercase">
        UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL OR EQUITABLE THEORY, WHETHER IN TORT, CONTRACT, STRICT
        LIABILITY OR OTHERWISE, SHALL &lt;&lt; Platform &gt;&gt; OR ANY OF ITS AFFILIATES, EMPLOYEES,
        DIRECTORS, OFFICERS,
        AGENTS, VENDORS OR SUPPLIERS BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL,
        INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY NATURE ARISING OUT OF OR IN CONNECTION WITH THE
        USE OF OR INABILITY TO USE THE &lt;&lt; Platform &gt;&gt; WEB SITE, INCLUDING, WITHOUT LIMITATION,
        DAMAGES TO YOUR
        PERSON, CLAIMS OF MEDICAL MALPRACTICE, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, LOSS OF DATA,
        WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, EVEN IF AN AUTHORIZED
        REPRESENTATIVE OF &lt;&lt; Platform &gt;&gt; HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE
        POSSIBILITY OF SUCH
        DAMAGES. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT &lt;&lt; Platform &gt;&gt; SHALL NOT BE
        RESPONSIBLE FOR
        THE ANY ACTS, INCLUDING DEFAMATORY OR ILLEGAL CONDUCT, OF ANY THIRD PARTY ON THIS SITE. THE
        LIMITATIONS PROVIDED HEREIN SHALL BE TO THE FULLEST EXTENT PROVIDED BY APPLICABLE LAW.
      </p>
      <h6 class="font-700 text-center">Third-Party Articles</h6>
      <p>
        From time to time the Site may contain articles or other information from third parties that we feel
        may be of interest to you. While we attempt to provide information that is accurate, we do not
        represent that all information contained in such articles or obtained from third parties is correct
        and the publishing of third-party articles shall not in any event be deemed an endorsement by
        &lt;&lt; Platform &gt;&gt; of the material contained therein. The opinions contained in any such
        article are not
        necessarily the opinion of &lt;&lt; Platform &gt;&gt;.
      </p>
      <h6 class="font-700 text-center">Correction of Errors and Inaccuracies</h6>
      <p>
        The information on the Site may contain typographical errors or inaccuracies and may not be complete
        or current. We therefore reserve the right to correct any errors, inaccuracies or omissions and to
        change or update information at any time without prior notice (including after you have submitted your
        order). We apologize for any inconvenience this may cause you.
      </p>
      <h6 class="font-700 text-center">Parental Controls</h6>

      <p>
        You are hereby provided notice that parental control protections (filtering services, software, and
        computer hardware) are commercially available and may assist you in limited access to certain
        materials that may be harmful to minors.&lt;&lt; Platform &gt;&gt; does not endorse or recommend any
        specific
        product or service, however a list of select providers of such products &nbsp;&nbsp;is
        &nbsp;&nbsp;available at

        <a
          href="https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers">https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers</a>
      </p>

      <h6 class="font-700 text-center">Notices and Electronic Communications</h6>
      <p>
        All notices for&lt;&lt; Platform &gt;&gt;, unless explicated stated otherwise, shall be sent by U.S.
        Mail, postage prepaid, certified mail, return receipt requested to &lt;&lt; Platform &gt;&gt;, Attn:
        Notices _178 East Hanover Avenue #103-174, Cedar Knolls, NJ 07927-2013__________________________.
        Should&lt;&lt; Platform &gt;&gt; need to send you any notice, you hereby consent to receive any
        notices or other communications through a general post on the Site, sending an email to the email
        address you listed in your profile for your account, or, where applicable, mailing a notice to you at
        your address as
        provided in your account. Should any notices be required to be sent to you in writing, you hereby
        agree that all agreements, notices, disclosures and other communications&lt;&lt; Platform &gt;&gt;
        provides to you in accordance with the prior sentence satisfy any legal requirement that such
        communications be in writing. Any notice sent in accordance with this provision shall be deemed given
        (i) 24 hours after the notice is posted on the Site or an electronic message is sent, unless the
        sending party is notified that the message did not reach the recipient, or (ii) in the case of
        mailing, three days after the date of mailing. You agree that a printed version of this Agreement,
        including the Terms & Conditions and/or any notice given in electronic form shall be admissible in
        judicial or administrative proceedings based upon or relating to this Agreement or Terms & Conditions
        to the same extent and subject to the same conditions as other business documents and records
        originally generated and maintained in printed form.
      </p>

      <h6 class="font-700 text-center">Notice for California Residents</h6>
      <p>
        Under California Civil Code Section 1789.3, California users are entitled to the following consumer
        rights notice: If you have a question or complaint regarding the Site, please send an email to
        [info&#64;corstet.com]. You may also contact us by writing to &lt;&lt; Platform &gt;&gt; Attn: Notices,
        178 East Hanover
        Ave. #103-174, Cedar Knolls, NJ 07927-2013 or by calling us at NEED PHONE. California residents may
        reach the Complaint Assistance Unit of the Division of Consumer Services of the California
        Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by
        telephone at (916) 445-1254 or (800) 952-5210.
      </p>
      <h6 class="font-700 text-center">International Issues</h6>
      <p>
        This Site and the information and content provided on or accessed through this Site are intended for
        use within the United States of America
        (“USA”). Use of this Site and the services described on this Site may be subject to additional laws
        and regulations or otherwise prohibited or restricted in countries outside the USA. If you use or
        access this Site from outside the USA, you do so at your own risk and are fully responsible for
        complying with the laws and regulations of the territory from which you access or use this Site. It is
        your responsibility to comply with local, state, federal and international laws applicable to his or
        her use of this Site and the information and content provided on or accessed through this Site.
      </p>

      <h6 class="font-700 text-center">Electronic Contracting</h6>
      <p>
        You agree that all agreements, notices, disclosures, and other communications that we provide to you
        electronically satisfy any legal requirement that such communications be in writing. You agree that
        any time you electronically transact, agree, or consent via the Site it is intended to be an
        electronic signature which binds you as if you had signed on paper. You agree that your use of the
        Site, other than to read the Terms (or Additional Terms) and Privacy Policy, constitutes agreement to
        the Terms, and any applicable Additional Terms, then posted without further action by you.
      </p>

      <h6 class="font-700 text-center">Updates to these Terms</h6>
      <p>
        &lt;&lt; Platform &gt;&gt; reserves the right to update or modify these Terms at any time without
        notice. Updates and modifications will be effective upon our posting the modified Terms on the Site,
        as reflected by the “Last Updated” date at the top of this page. Your continued use of the Site
        constitutes your agreement to be bound by these Terms.
      </p>
      <h6 class="font-700 text-center">Updates to these Terms</h6>
      <p>
        &lt;&lt; Platform &gt;&gt; reserves the right to update or modify these Terms at any time without
        notice. Updates and modifications will be effective upon our posting the modified Terms on the Site,
        as reflected by the “Last Updated” date at the top of this page. Your continued use of the Site
        constitutes your agreement to be bound by these Terms.
      </p>
      <h6 class="font-700 text-center">Contact Us</h6>
      <p>
        If you have any questions, comments or concerns regarding &lt;&lt; Platform &gt;&gt; or your use of
        the Site, please contact us directly as
        provided herein with a detailed description. &lt;&lt; Platform &gt;&gt; values its customers and the
        visitors to this site.
      </p>
      <address>
        <strong>Address:</strong> 178 East Hanover Ave. #103-174, Cedar Knolls, NJ 07927-2013 <br>
        <strong>Telephone:</strong> (973) 306-4440 <br>
        <strong>Fax:</strong> (973) 306-4440 <br>
        <strong>Email:</strong> info&#64;corstet.com
      </address>


      <h6 class="font-700 text-center">Changes to the Terms of Use</h6>
      <p>
        The Effective Date of this Agreement and the Terms & Conditions contained herein is March 1, 2022.
        This Agreement was last updated on March 1, 2022. Please check back periodically for changes to our
        Terms of Use.
      </p>

    </article>

  </div>