import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { cLoginComponent } from './login/login.component';
import { cSignupComponent } from './signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationRoutes } from './authentication-routing.module';
import { cNotfoundComponent } from './404/notfound.component';
import { cConfirmationCodeComponent } from './confirmation-code/confirmation-code.component';
import { cEnterEmailComponent } from './enter-email/enter-email.component';
import { cConfirmationMessageComponent } from './confirmation-message/confirmation-message.component';
import { cPatientRegistrationComponent } from './patient-registration/patient-registration.component';
import { cCompleteRegistartionComponent } from './complete-registartion/complete-registartion.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { SearchDropdownDirective } from './search-dropdown.directive';
import { InternalServerComponent } from './internal-server/internal-server.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InputMaskModule } from '@ngneat/input-mask';
// import { TextMaskModule } from 'angular2-text-mask';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(AuthenticationRoutes),
    ReactiveFormsModule,
    NgbModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
    TranslateModule,
    RecaptchaModule,
    // TextMaskModule,
    InputMaskModule,
    InfiniteScrollModule,
  ],
  declarations: [
    cNotfoundComponent,
    cLoginComponent,
    cSignupComponent,
    cConfirmationCodeComponent,
    cEnterEmailComponent,
    cConfirmationMessageComponent,
    cPatientRegistrationComponent,
    cCompleteRegistartionComponent,
    SearchDropdownDirective,
    InternalServerComponent,
    TermsAndConditionComponent,
  ]
})
export class AuthenticationModule { }
