import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { MustMatch } from "src/app/services/must-match.service";
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-complete-registartion',
  templateUrl: './complete-registartion.component.html',
  styleUrls: ['./complete-registartion.component.css']
})
export class cCompleteRegistartionComponent implements OnInit {
  completeRegistrationForm: FormGroup;
  registerAccount: any;
  range: any;
  years: any = [];
  days: any = [];
  months: any = [];
  states: any = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID",
    "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT",
    "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA",
    "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "DC", "WV", "WI",
    "WY"];
  userEmail: any;
  userCode: any;
  responseAlert: boolean = false;
  alertColor: string;
  resMessage: string;
  constructor(private router: Router, private formBuilder: FormBuilder, private dataService: DataService, private activatedRoute: ActivatedRoute, private authService: AuthService, private sessionService: SessionService) {
    this.activatedRoute.queryParams.subscribe(params => {
      let code = params['code'];
      // this.userCode = code.code;
      this.userCode = code?.code??"";
    });
  }

  ngOnInit(): void {
    this.initializeRegistrationForm();
    this.getYearsMonthsDays();
    this.checkUserDetailsURL();
    this.registerAccount = JSON.parse(localStorage.getItem("RegisterAccount"))
    this.dataService.displayHippaElement.next(false)
  }

  // ****************************************** Form ***************************************

  initializeRegistrationForm() {
    const passRejex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{9,50}$/;
    const zipCodeRejex = /^[0-9]{5}$/
    let textRejex = /^[a-zA-Z0-9]+.*(.|\n)*$/;
    this.completeRegistrationForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      day: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      phone: [''],
      prefix: [''],
      middleName: [''],
      suffix: [''],
      street_address: ['', [Validators.required, Validators.pattern(textRejex)]],
      street_address2: [''],
      city: ['', [Validators.required, Validators.pattern(textRejex)]],
      zipcode: ['', [Validators.required, Validators.pattern(zipCodeRejex)]],
      password: ['', [Validators.required, Validators.minLength(9), Validators.pattern(passRejex)]],
      confirm_password: ['', Validators.required],
      state: ['']
    },
      {
        validator: MustMatch('password', 'confirm_password')
      });
  }

  // ****************************************** Registartion Login ***************************************

  onCompleteRegistartionLogin() {
    let dateOfBirth = this.completeRegistrationForm.controls['year'].value + "/" + this.completeRegistrationForm.controls['month'].value + "/" + this.completeRegistrationForm.controls['day'].value;
    let ageCheck = this.dataService.underAgeValidate(dateOfBirth);
    if (this.completeRegistrationForm.valid && (this.completeRegistrationForm.controls['state'].value !== '' && this.completeRegistrationForm.controls['state'].value !== 'State')) {
      if (ageCheck == true) {

        let body = {
          email: this.completeRegistrationForm.controls['email'].value,
          password: this.completeRegistrationForm.controls['password'].value,
          rptPassword: this.completeRegistrationForm.controls['confirm_password'].value,
          mobilePhone : this.registerAccount?.phone??this.activatedRoute.snapshot.queryParams['phone'],
          userId: this.completeRegistrationForm.controls['email'].value,
          code: this.userCode,
          role: 'user',
          firstName: this.completeRegistrationForm.controls['first_name'].value,
          lastName: this.completeRegistrationForm.controls['last_name'].value,
          tenantName: this.authService.AuthService_ReturnClientName()
        }
        this.dataService.DataService_SignUp(body).subscribe(result => {
          if (result.status == 'failed') {
            this.displayAlertMessage('alert-danger', result?.detailMessage ?? "An internal server error occured. Please try again later.")
          }
          else {
            if(!this.completeRegistrationForm.controls['phone'].value || this.completeRegistrationForm.controls['phone'].value == '')
              {
                this.completeRegistrationForm.patchValue({
                  phone : this.registerAccount?.phone??this.activatedRoute.snapshot.queryParams['phone'],
                })
              }
            this.sessionService.cSessionService_SetSignupDetails(this.completeRegistrationForm.value);
            let body = {
              j_username: this.completeRegistrationForm.controls['email'].value,
              j_password: this.completeRegistrationForm.controls['password'].value,
              tenant_display_name: this.authService.AuthService_ReturnClientName(),
              terms: true
            }
            this.authService.AuthService_Login(JSON.stringify(body)).subscribe(
              (data) => {
                let accessToken = data.accessToken;
                let userRole = data.user.role;
                let userId = data.user.id;
                localStorage.setItem(environment.AccessToken, accessToken);
                localStorage.setItem("username", body.j_username);
                localStorage.setItem("userRole", userRole);
                localStorage.setItem("userId", userId);
                localStorage.setItem("lsTenant", data.user.tenantName);
                if (data.accessToken) {
                  localStorage.setItem(environment.AccessToken, accessToken);
                  this.router.navigateByUrl('/authentication/registration2');
                }
                else {
                  localStorage.removeItem(environment.AccessToken);
                  let providerId = localStorage.getItem('providerId');
                  console.log("Provider ID", providerId);
                  if (providerId !== null || providerId !== undefined) {
                    let body =
                    {
                      providerId: providerId
                    }
                    this.dataService.DataService_RemoveProviderToken(body).subscribe(
                      result => {
                        if (result.status == 'success') {
                          console.log("Token Removed", result);
                        }
                        else {
                          console.log("DataService_RemoveProviderToken===>Error", result);

                        }
                      },
                      (error) => {
                        console.log("DataService_RemoveProviderToken===>Error", error);
                      }
                    )
                  }
                  localStorage.clear();
                  this.router.navigateByUrl('authentication/login');
                  window.location.reload();
                }
              },
              (error) => {
                localStorage.removeItem(environment.AccessToken);
                console.log("AJAXLogin : Login Error ==>  ", error);
              }
            );
          }
        },
          (error) => {
            this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
          })
      }
      else {
        this.displayAlertMessage('alert-danger', "Person should be at least 18 years old.")
      }
    }
    else {
      this.completeRegistrationForm.markAllAsTouched();
    }
  }

  // ****************************************** Functions ***************************************

  dateofBirthSelection(incommingCheck) {
    let selectedYear;
    let selectedMonth;
    selectedYear = this.completeRegistrationForm.controls['year'].value;
    selectedMonth = this.completeRegistrationForm.controls['month'].value;
    if (incommingCheck == 'month') {
      this.getDays(31);
      if (selectedYear !== 'Year' && selectedMonth !== 'Month') {
        this.validateYearAndMonth(selectedYear, selectedMonth);
      }
      if (this.completeRegistrationForm.controls['month'].value == 'Month') this.completeRegistrationForm.controls['day'].reset();
    }
    if (incommingCheck == 'year') {
      if (this.completeRegistrationForm.controls['year'].value == 'Year') {
        this.completeRegistrationForm.controls['month'].reset();
        this.completeRegistrationForm.controls['day'].reset();
      }
      if (this.completeRegistrationForm.controls['year'].value && this.completeRegistrationForm.controls['month'].value) this.completeRegistrationForm.controls['day'].reset();
      if (this.completeRegistrationForm.controls['year'].value !== 'Year' || this.completeRegistrationForm.controls['year'].value !== '' || this.completeRegistrationForm.controls['year'].value !== null) {
        this.validateYearAndMonth(selectedYear, selectedMonth);
      }
    }
    if (incommingCheck == 'day') {
      if (this.completeRegistrationForm.controls['day'].value == 'Date') { this.completeRegistrationForm.controls['day'].reset(); }
    }
  }

  patchFormvalues(checkValue: string) {
    if (checkValue === 'URL') {
      this.completeRegistrationForm.patchValue({
        first_name: this.activatedRoute.snapshot.queryParams['patientFirstName'],
        last_name: this.activatedRoute.snapshot.queryParams['patientLastName'],
        email: this.activatedRoute.snapshot.queryParams['email'],
        phone : this.activatedRoute.snapshot.queryParams['phone'],
        code: this.activatedRoute.snapshot.queryParams['code']
      })
      this.userEmail = this.activatedRoute.snapshot.queryParams['email'] ?? "";
    }
    else {
      this.registerAccount = JSON.parse(localStorage.getItem("RegisterAccount"))
      this.userEmail = localStorage.getItem('userEmail') ?? "";
      if (this.registerAccount) {
        this.completeRegistrationForm.patchValue({
          first_name: this.registerAccount.first_name,
          last_name: this.registerAccount.last_name,
          phone: this.registerAccount.phone,
          email: this.userEmail
        })
      }
    }
  }
 
  validateYearAndMonth(selectedYear, selectedMonth) {
    if (selectedYear % 4 == 0 && selectedMonth == 2) { this.getDays(29); this.completeRegistrationForm.controls['day'].value == 'Date'; }
    if (selectedYear % 4 !== 0 && selectedMonth == 2) { this.getDays(28); this.completeRegistrationForm.controls['day'].value == 'Date'; }
    if (selectedMonth == 9 || selectedMonth == 4 || selectedMonth == 6 || selectedMonth == 11) { this.getDays(30); this.completeRegistrationForm.controls['day'].value == 'Date'; }
  }

  getYearsMonthsDays() {
    this.months = [];
    this.years = [];
    let currentYear: number = new Date().getFullYear();
    for (let monthIndex = 1; monthIndex <= 12; monthIndex++) { this.months.push(monthIndex); }
    this.months.unshift('Month')
    for (let i = (currentYear - 125); i < (currentYear + 1); i++) { this.years.unshift(i); }
    this.years.unshift('Year');
    this.getDays(31);
  }
  getDays(incommingDays) {
    this.days = [];
    for (let daysIndex = 1; daysIndex <= incommingDays; daysIndex++) { this.days.push(daysIndex) }
    this.days.unshift('Date')
  }

  dismissAlert() {
    this.responseAlert = false;
  }

  checkUserDetailsURL() {
    if (Object.keys(this.activatedRoute.snapshot.queryParams).length > 1) {
      this.patchFormvalues('URL')
    }
    else {
      this.patchFormvalues('LocalStorage')
    }
  }

  // ****************************************** Alert Message ***************************************


  displayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }

}
