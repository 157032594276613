<div class="d-none container login-screen authentication-labels">
  <div class="row d-flex justify-content-center align-items-center authentication-cards">
    <div class="col-12 col-md-9 col-lg-7 col-xl-6">
      <h2 class="text-center mb-4 heading-text">{{'AUTHENTICATION.LOGIN.MainHeading' | translate}}</h2>
      <div class="register-card card">
        <div class="card-body p-lg-5">
          <form class="Input-form" [formGroup]="loginForm">
            <div class="form-outline mb-2">
              <!-- Email address label -->
              <label for="exampleInputEmail1" class="form-label"> {{'AUTHENTICATION.LOGIN.Emaillabel' | translate}}</label>
              <input type="email" placeholder="{{'AUTHENTICATION.LOGIN.EmailPlaceHolderText' | translate}}"
                class="form-control text-center form-control-lg" formControlName="j_username" maxlength="150" >
              <span
                *ngIf="loginForm.controls['j_username'].hasError('required') && loginForm.controls['j_username'].touched"
                class="text-danger ml-1">{{'AUTHENTICATION.LOGIN.EmailFieldValidationMessage' | translate}}</span>
              <span
                *ngIf="loginForm.controls['j_username'].hasError('email') && loginForm.controls['j_username'].touched"
                class="text-danger ml-1">{{'AUTHENTICATION.LOGIN.EmailFieldValidationMessage' | translate}}</span>
            </div>
            <div class="form-outline mb-3">
              <!-- Password label -->
              <label for="inputPassword" class="col-sm-2 col-form-label"> {{'AUTHENTICATION.LOGIN.Passwordlabel' | translate}}</label>
              <input type="password" class="form-control form-control-lg text-center"
                placeholder="{{'AUTHENTICATION.LOGIN.PasswordPlaceHolderText' | translate}}"
                formControlName="j_password">
              <span
                *ngIf="loginForm.controls['j_password'].hasError('required') && loginForm.controls['j_password'].touched"
                class="text-danger ml-1">{{'AUTHENTICATION.LOGIN.PasswordFieldValidationMessage' | translate}}</span>
            </div>
            <div class="form-check register-checkbox mb-2 d-flex justify-content-center ">
              <input class="form-check-input" type="checkbox" value="" formControlName="terms" id="flexCheckDefault">
              <label class="form-check-label terms-condition ml-4 font-1 font-400" for="flexCheckDefault">
                {{'AUTHENTICATION.LOGIN.IAgree' | translate}} <span href="javascript:void(0)"
                  class="text-body cursor-point"
                  (click)="checkTermsAndConditions()"><u>{{'AUTHENTICATION.LOGIN.TermsAndConditions' | translate}}</u></span>
              </label>
            </div>
            <div class="mb-2 font-16 d-flex justify-content-center">
              <a href="javascript:void(0)" class="ForgetPassword text-body"
                (click)="navigateRequestResetPassword()"><u>{{'AUTHENTICATION.LOGIN.ForgotPassword' | translate}}</u></a>
            </div>
            <span *ngIf="loginForm.controls['terms'].hasError('required') && loginForm.controls['terms'].touched"
              class="text-danger d-flex justify-content-center">{{'AUTHENTICATION.LOGIN.TermsAndConditionFieldValidationMessage' | translate}}</span>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-success btn-lg pre-auth-btn2 m-3"
                (click)="sendLoginRequest()">{{'AUTHENTICATION.LOGIN.LoginButtonText' | translate}}</button>
            </div>
          </form>
          <div *ngIf="alert" class="alert alert-danger alert-dismissible fade show" role="alert">
            {{'AUTHENTICATION.LOGIN.ErrorMessage' | translate}}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-5">
        <button type="button" class="btn btn-primary btn-sm register-btn w-100"
          (click)="navigatetoSignUp()">{{'AUTHENTICATION.LOGIN.RegisterButtonText' | translate}}</button>
      </div>

      <!-- disclaimer -->
      <div class="disclaimer-bg">
        <div class="mt-3">
          <h3 class="mb-0 font-800 d-flex justify-content-center pt-4 pb-3"><i class="fa-solid fa-triangle-exclamation"
              aria-hidden="true"></i>{{'AUTHENTICATION.LOGIN.DisclaimerText' | translate}}</h3>
        </div>
        <div class="d-flex justify-content-center disclaimer-text">
          <span class="mb-4 w-100 px-5">{{'AUTHENTICATION.LOGIN.DisclaimerParagrapgh' | translate}}
            <a href="http://corstet.com" class="link-color">http://corstet.com</a> <span
              class="ml-2">{{'AUTHENTICATION.LOGIN.OrText' | translate}}</span>
            <a href="http://corstet.com" class="link-color ml-2">{{'AUTHENTICATION.LOGIN.ClickhereText' |
              translate}}</a>
          </span>
        </div>
      </div>

      <!-- logout Popup -->
      <div class="d-none d-flex justify-content-center mt-2">
        <button type="button" class="btn btn-primary btn-sm logout-btn" data-bs-toggle="modal"
          data-bs-target="#logoutid">Logout</button>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="logoutid" data-bs-keyboard="false" tabindex="-1" aria-labelledby="BackdropLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content modal-content-bg">
            <div class="modal-header mt-4 ml-3 p-0 border-bottom-0" >
              <h2 class="modal-title modal-title-notification mb-0 ml-5 font-600 position-relative" id="BackdropLabel">
                <strong>{{'AUTHENTICATION.LOGIN.LogoutPopUpHeaderText' | translate}} </strong>
              </h2>
              <i class="fa-solid fa-times-circle-o " data-bs-dismiss="modal" aria-hidden="true"></i>
            </div>
            <div class="modal-body modal-notification mb-5 ml-5">
              <p>{{'AUTHENTICATION.LOGIN.LogoutPopUpNewPharmacy' | translate}}</p>
              <p>{{'AUTHENTICATION.LOGIN.LogoutPopUpPleaseLogout' | translate}}</p>
              <p>{{'AUTHENTICATION.LOGIN.LogoutPopUpThankYou' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Updated design -->
<div class="container login-screen authentication-labels">
  <div class="row d-flex justify-content-center align-items-center authentication-cards">
    <div class="col-12">
      <div class="">
        <div class="">
          <div class="row main-section">
            <div class="col-md-6 bg-disclaimer p-5">
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-success btn-lg btn-login-register w-100 mt-2"
                  (click)="navigatetoSignUp()">{{'AUTHENTICATION.LOGIN.RegisterButtonText' | translate}}</button>
              </div>
              <div>
                <img class="img-fluid mt-4" src="../../../../assets/images/patient-animated.png" alt="">
              </div>
              <div class="disclaimer-bg mt-4">
                <div class="d-flex justify-content-center disclaimer-text">
                  <span class="mb-4 w-100">{{'AUTHENTICATION.LOGIN.DisclaimerParagrapgh' | translate}}
                    <a href="http://corstet.com" class="link-color">http://corstet.com</a> <span
                      class="ml-2">{{'AUTHENTICATION.LOGIN.OrText' | translate}}</span>
                    <a href="http://corstet.com" class="link-color ml-2">{{'AUTHENTICATION.LOGIN.ClickhereText' | translate}}</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 p-5 login-card login-bg">
              <h2 class="mb-5 mt-4 login-heading-text">{{'AUTHENTICATION.LOGIN.MainHeading' | translate}}</h2>
              <form class="Input-form" [formGroup]="loginForm">
                <div class="form-outline mb-4">
                  <!-- Email address label -->
                  <label for="exampleInputEmail1" class="form-label"> {{'AUTHENTICATION.LOGIN.Emaillabel' | translate}}</label>
                  <input type="email" placeholder="{{'AUTHENTICATION.LOGIN.EmailPlaceHolderText' | translate}}"
                    class="form-control form-control-lg" formControlName="j_username" maxlength="150">
                  <span
                    *ngIf="loginForm.controls['j_username'].hasError('required') && loginForm.controls['j_username'].touched"
                    class="text-danger ml-1">{{'AUTHENTICATION.LOGIN.EmailFieldValidationMessage' | translate}}</span>
                  <span
                    *ngIf="loginForm.controls['j_username'].hasError('email') && loginForm.controls['j_username'].touched"
                    class="text-danger ml-1">{{'AUTHENTICATION.LOGIN.EmailFieldValidationMessage' | translate}}</span>
                </div>

                <div class="form-outline mb-4">
                  <!-- Password label -->
                  <label for="inputPassword" class="col-sm-2 col-form-label"> {{'AUTHENTICATION.LOGIN.Passwordlabel' | translate}}</label>
                  <input type="password" class="form-control form-control-lg"
                    placeholder="{{'AUTHENTICATION.LOGIN.PasswordPlaceHolderText' | translate}}"
                    formControlName="j_password">
                  <span
                    *ngIf="loginForm.controls['j_password'].hasError('required') && loginForm.controls['j_password'].touched"
                    class="text-danger ml-1">{{'AUTHENTICATION.LOGIN.PasswordFieldValidationMessage' | translate}}</span>
                </div>
                <div class="form-check register-checkbox mb-2">
                  <input class="form-check-input" type="checkbox" value="" formControlName="terms"
                    id="flexCheckDefault1">
                  <label class="form-check-label terms-condition ml-3 font-400">
                    {{'AUTHENTICATION.LOGIN.IAgree' | translate}} <span href="javascript:void(0)"
                      class="text-body cursor-point" data-bs-toggle="modal" data-bs-target="#termsandCondition"
                      (click)="checkTermsAndConditions()"><u>{{'AUTHENTICATION.LOGIN.TermsAndConditions' | translate}}</u></span>
                  </label>
                </div>
                <div class="mb-2 font-16 ml-4 pl-3">
                  <a href="javascript:void(0)" class="ForgetPassword text-body"
                    (click)="navigateRequestResetPassword()"><u>{{'AUTHENTICATION.LOGIN.ForgotPassword' | translate}}</u></a>
                </div>
                <span *ngIf="loginForm.controls['terms'].hasError('required') && loginForm.controls['terms'].touched"
                  class="text-danger pl-2 ml-5">{{'AUTHENTICATION.LOGIN.TermsAndConditionFieldValidationMessage'| translate}}</span>
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-success btn-lg btn-login-register mt-5 w-100"
                    (click)="sendLoginRequest()">{{'AUTHENTICATION.LOGIN.LoginButtonText' | translate}}</button>
                </div>
              </form>
              <div *ngIf="alert" class="alert mt-4 alert-danger alert-dismissible fade show" role="alert"
                style="border-radius: 10px;">
                {{'AUTHENTICATION.LOGIN.ErrorMessage' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Terms and condition modal -->
<div class="modal fade" id="termsandCondition" tabindex="-1" aria-labelledby="termsandConditionLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content border-0 modal-border-color">
      <div class="modal-header position-relative pb-0 pb-2 pt-3 d-flex align-items-center justify-content-end border-bottom-0" >
        <div class="d-flex justify-content-end align-items-center cross-icon download-rx cursor">
          <div data-bs-dismiss="modal">
            <svg height="20" viewBox="0 0 24 24" class="cursor" width="20"
              xmlns="http://www.w3.org/2000/svg">
              <g id="Layer_2" data-name="Layer 2" fill="white">
                <path
                  d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm4.242 13.829a1 1 0 1 1 -1.414 1.414l-2.828-2.829-2.828 2.829a1 1 0 0 1 -1.414-1.414l2.828-2.829-2.828-2.829a1 1 0 1 1 1.414-1.414l2.828 2.829 2.828-2.829a1 1 0 1 1 1.414 1.414l-2.828 2.829z" />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="modal-body scroller pt-0 pb-0">
        <div class="card bg-c4c4c4 rounded-cards p-1">
          <div class="card-body card-bg-color">
            <app-terms-and-condition></app-terms-and-condition>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>