import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-confirmation-code',
  templateUrl: './confirmation-code.component.html',
  styleUrls: ['./confirmation-code.component.css']
})
export class cConfirmationCodeComponent implements OnInit {
  verificationCodeForm: FormGroup;
  otpCode: any;
  responseAlert: boolean = false;
  alertColor: string;
  resMessage: string;
  sessionInfo: string;
  constructor(private router: Router, private formBuilder: FormBuilder, private dataService: DataService) { }
  ngOnInit() {
    this.initializeVerificationCodeForm();
    this.sessionInfo = localStorage.getItem('sessionInfo')
    this.dataService.displayHippaElement.next(true),
    this.otpCode = localStorage.getItem('Code')
  }

  // ****************************************** Form ***************************************

  initializeVerificationCodeForm() {
    this.verificationCodeForm = this.formBuilder.group({
      pin: [null, Validators.required],
    })
  }

  // ****************************************** Verify OneTime Pin ***************************************

  verifyOneTimePinCode() {
    if (this.verificationCodeForm.valid) {
      let body = {
        pin: this.verificationCodeForm.controls['pin'].value,
        code: this.otpCode,
      }
      this.dataService.DataService_VerifyOneTimePin(body).subscribe(result => {
        if (result.success = true) {
          this.singInWithPhoneNumber();
          this.router.navigate(['/authentication/registration1'], { queryParams: { code: this.otpCode } })
        }
        else {
          let message = "Please entered the correct OTP code.";
          this.displayAlertMessage('alert-danger', message)
        }
      },
        (error) => {
          this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
        })
    }
    else {
      this.verificationCodeForm.markAllAsTouched()
    }
  }
  // ****************************************** Verify Code ***************************************


  singInWithPhoneNumber() {
    let body ={
      phoneNumber: localStorage.getItem('phoneNumber'),
      sessionInfo: this.sessionInfo,
      code: "123456"
    };
    this.dataService.DataService_SingInWithPhoneNumber(body).subscribe(result => {
      if (result.status == 'success') {
        console.log("Incomming Result::", result)
      }
      else {
        this.displayAlertMessage('alert-danger', result.message)
      }
    },
      (error) => {
        this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
      })
  }

  // ****************************************** Functions ***************************************

  navigateToVerifyEmail() {
    this.router.navigateByUrl('/authentication/verify-email');
  }

  dismissAlert() {
    this.responseAlert = false;
  }

  // ****************************************** Alert Messages ***************************************

  displayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }
}
