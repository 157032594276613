import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { SessionService } from 'src/app/services/session.service';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class cSignupComponent implements OnInit {
  registerAccountForm: FormGroup;
  verificationCodeForm: FormGroup;
  howRegisterForm: boolean = false;
  responseAlert: boolean = false;
  alertColor: string;
  resMessage: string;
  recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  phoneNumberString: string;
  verificationSection: boolean = false;
  fireBaseConfirmationResult: any;
  phoneNumberMask:any = createMask('999-999[-9999]');

  constructor(private router: Router, private formBuilder: FormBuilder, private dataService: DataService, private sessionServive: SessionService, private route: ActivatedRoute) { }


  ngOnInit(): void {
    this.intializeFireBase();
    // Get Referral ID Start
    const referralId = this.route.snapshot.queryParams['referralId'];
    referralId ? localStorage.setItem('referralId', referralId) : localStorage.removeItem('referralId');
    // Get Referral ID End
    this.initializeSignupForm();
    this.initializeVerifyOTPForm();
    this.dataService.displayHippaElement.next(true);
  }

  // ****************************************** Forms  ***************************************

  initializeVerifyOTPForm() {
    this.verificationCodeForm = this.formBuilder.group({
      pin: [null, Validators.required],
    })
  }

  initializeSignupForm() {
    const phoneRejex = /^[(]?[0-9]{3}[\-][)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im;
    let textRejex = /^[a-zA-Z0-9]+.*(.|\n)*$/;
    this.registerAccountForm = this.formBuilder.group(
      {
        first_name: ["", [Validators.required, Validators.pattern(textRejex)]],
        last_name: ["", [Validators.required, Validators.pattern(textRejex)]],
        phone: ["", [Validators.required, Validators.pattern(phoneRejex)]],
        terms: [false, [Validators.required, Validators.requiredTrue]]
      })
  }

  // ****************************************** SignUp Request ***************************************

  sendSignupRequest() {
    if (this.registerAccountForm.valid) {
      let phoneNumber: string = this.registerAccountForm.controls['phone'].value;
      while (phoneNumber.includes('-')) {
        phoneNumber = phoneNumber.replace('-', '')
      }
      let body =
      {
        phone: '+1' + phoneNumber
      }
      this.dataService.DataService_SendOneTimePin(body).subscribe(result => {
        if (result.status === 'failed') {
          this.displayAlertMessage('alert-danger', result.message);
        } else {
          console.log("Incoming Code ==>>>>", result);
          localStorage.setItem("RegisterAccount", JSON.stringify(this.registerAccountForm.value));
          localStorage.setItem('Code', result.code);
          this.getVerificationCode();
        }
      });
    } else {
      this.registerAccountForm.markAllAsTouched();
    }
  }

  // ****************************************** Get VerificationCode  ***************************************

  getVerificationCode() {
    let phoneNumber: string = this.registerAccountForm.controls['phone'].value;
    this.sessionServive.cSessionService_SetPhoneNumber(phoneNumber);
    while (phoneNumber.includes('-')) {
      phoneNumber = phoneNumber.replace('-', '')
    }
    phoneNumber = '+1' + phoneNumber;

    // ------------ OTP -----------------------//
    firebase.auth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier)
      .then(async (confirmationResult) => {
        if (this.recaptchaVerifier.verify()) {
          this.verificationSection = true;
          this.fireBaseConfirmationResult = confirmationResult;
          this.displayAlertMessage('alert-success', 'OTP code has been sent to the phone.')
        }
      },
        (error) => {
          this.displayAlertMessage('alert-danger', 'Invalid phone number or format!')
        })
      .catch((error) => {
        console.log("error===>>>>> ", error);
      });
  }


  // ****************************************** Verify Login Token ***************************************

  verifyToken() {
    if (this.verificationCodeForm.valid) {
      this.fireBaseConfirmationResult.confirm(this.verificationCodeForm.controls['pin'].value).then(async (result) => {
        localStorage.setItem('sessionInfo', "sessionInfo");
        this.router.navigateByUrl('/authentication/registration1');
      },
        (error) => {
          console.log("Error catched===>>>>>", error);
          this.displayAlertMessage('alert-danger', 'Invalid code!')

        })
        .catch((error) => {
          console.log("error: ", error);
          this.displayAlertMessage('alert-danger', 'An internal server error occured. Please try again later.')
        });
    }
    else {
      this.verificationCodeForm.markAllAsTouched();
    }
  }

  // ****************************************** FireBase Intialization ***************************************

  intializeFireBase() {
    // OTP start
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    } else {
      firebase.app();
    }
    //wait for the captcha container in html to render
    setTimeout(() => {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container-login');
      console.log("recaptchaVerifier", this.recaptchaVerifier);
    }, 2000)
  }

  // ****************************************** Functions ***************************************

  dismissAlert() {
    this.responseAlert = false;
  }

  navigateToVerifyEmail() {
    this.router.navigateByUrl('/authentication/verify-email');
  }

  // ****************************************** ALERT MESSAGES  ***************************************

  displayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }
}
