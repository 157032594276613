
  <app-header></app-header>
  <div class="container" style="max-width: 1440px; margin: 0 auto; padding: 80px 30px 0px 30px !important;">
<router-outlet></router-outlet>
</div>

<a *ngIf="displayHippaElement" target="" href="javascript:void()" class="d-flex justify-content-center hippa-complaint">
    <svg  version="1.1"  id="Layer_1" height xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 276.1 257.5" style="enable-background:new 0 0 276.1 257.5;" xml:space="preserve">
  <style type="text/css">
   .st0{fill:#FFFFFF;stroke:#285cb7;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;}
   .st1{stroke:#285cb7;stroke-width:2;stroke-miterlimit:10;}
   .st2{font-family:'OpenSans-Bold';}
   .st3{font-size:58.3298px;}
   .st4{font-size:31.1022px;}
  </style>
  <g>
   <path class="st0" d="M201.1,100.5h45.1c6.6,0,12,5.4,12,12v115.7c0,6.6-5.4,12-12,12H30.8c-6.6,0-12-5.4-12-12V112.5
     c0-6.6,5.4-12,12-12h49.8"/>
   <g>
     <g>
       <path class="st1" d="M124,69.2h11.1c1.1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9H124c-1.1,0-1.9,0.9-1.9,1.9S123,69.2,124,69.2
         L124,69.2z"/>
       <path class="st1" d="M124,78.6h45.2c1.1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9H124c-1.1,0-1.9,0.9-1.9,1.9S123,78.6,124,78.6
         L124,78.6z"/>
       <path class="st1" d="M169.2,84.1h-12c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9h12c1.1,0,1.9-0.9,1.9-1.9
         C171.2,85,170.3,84.1,169.2,84.1z"/>
       <path class="st1" d="M212.8,20.9L187.7,8.3c-1.3-0.6-2.8-0.6-4.1,0l-25.1,12.6c0,0,0,0,0,0c-1.5,0.8-2.5,2.3-2.5,4.1v3.6h-27.3
         c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9H156v5.9c0,2.8,0.2,5.5,0.6,8.1H124c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9
         h33.4c0.5,1.9,1.2,3.8,1.9,5.5H124c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9h37.3c0.7,1.2,1.4,2.3,2.2,3.4
         c0.5,0.7,1.1,1.4,1.7,2.1h-22.4c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9H169c3.7,3.4,8.3,6.8,14.3,10.5c0.7,0.4,1.6,0.7,2.4,0.7
         s1.6-0.2,2.4-0.7c0.2-0.1,0.4-0.2,0.6-0.4v47.2h-50.8v-3.6c0-1.1-0.9-1.9-1.9-1.9s-1.9,0.9-1.9,1.9V136c0,0.4-0.3,0.6-0.6,0.6
         H93.2c-0.4,0-0.6-0.3-0.6-0.6v-28c0-0.4,0.3-0.6,0.6-0.6h40.1c0.4,0,0.6,0.3,0.6,0.6v5.8c0,1.1,0.9,1.9,1.9,1.9s1.9-0.9,1.9-1.9
         V108c0-0.4-0.1-0.8-0.1-1.1h31.6c1.1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9h-37.7v-5.5h37.7c1.1,0,1.9-0.9,1.9-1.9
         c0-1.1-0.9-1.9-1.9-1.9h-37.8c-0.2-1.9-0.7-3.8-1.4-5.5h20.1c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9h-22.4
         c-3.4-4.4-8.6-7.2-14.6-7.2c-3.1,0-6,0.8-8.6,2.1V32.5h15c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9H104
         c-1.8,0-3.2,1.5-3.2,3.2v50c-3.6,3.3-5.9,8.1-5.9,13.4v8.2h-1.7c-2.5,0-4.5,2-4.5,4.5v28c0,2.5,2,4.5,4.5,4.5h40.1
         c2.5,0,4.5-2,4.5-4.5v-5.6h51.5c1.8,0,3.2-1.5,3.2-3.2V76.8c6.9-4.6,11.7-8.9,15.2-13.7c5.1-6.9,7.6-15,7.6-24.8V25
         C215.4,23.2,214.4,21.7,212.8,20.9L212.8,20.9z M119.9,103.5h-13.3v-8.2c0-3.7,3-6.7,6.7-6.7c3.7,0,6.7,3,6.7,6.7L119.9,103.5
         L119.9,103.5z M98.8,95.2c0-8,6.5-14.4,14.4-14.4s14.4,6.5,14.4,14.4v8.2h-3.9v-8.2c0-5.8-4.7-10.6-10.6-10.6
         s-10.6,4.7-10.6,10.6v8.2h-3.9V95.2z M211.5,38.4c0,18.7-9.7,28.4-25.5,38c-0.2,0.1-0.5,0.1-0.7,0c-15.7-9.6-25.5-19.3-25.5-38
         V25c0-0.2,0.1-0.5,0.4-0.6l25.1-12.6c0.2-0.1,0.4-0.1,0.6,0l25.1,12.6c0.2,0.1,0.4,0.3,0.4,0.6L211.5,38.4L211.5,38.4z"/>
       <path class="st1" d="M185.6,21.4c-10.8,0-19.7,8.8-19.7,19.7s8.8,19.7,19.7,19.7c8.3,0,15.7-5.2,18.5-13.1c0.4-1-0.2-2.1-1.2-2.5
         s-2.1,0.2-2.5,1.2c-2.2,6.3-8.2,10.5-14.9,10.5c-8.7,0-15.8-7.1-15.8-15.8s7.1-15.8,15.8-15.8c7.7,0,14.2,5.5,15.5,13
         c0.2,1.1,1.2,1.8,2.3,1.6c1.1-0.2,1.8-1.2,1.6-2.3C203.2,28.2,195.1,21.4,185.6,21.4L185.6,21.4z"/>
       <path class="st1" d="M192.3,35.7l-7.7,7.7l-4.5-4.5c-0.8-0.8-2-0.8-2.7,0c-0.8,0.8-0.8,2,0,2.7l5.8,5.8c0.4,0.4,0.9,0.6,1.4,0.6
         s1-0.2,1.4-0.6l9-9c0.8-0.8,0.8-2,0-2.7C194.3,35,193.1,35,192.3,35.7L192.3,35.7z"/>
     </g>
   </g>
   <text transform="matrix(1 0 0 1 47.9321 192.0039)" class="st2 st3">HIPAA</text>
   <text transform="matrix(1 0 0 1 45.436 218.6982)" class="st2 st4">COMPLIANT</text>
  </g>
  </svg>  </a>
