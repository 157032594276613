import { Routes } from '@angular/router';
import { cConfirmationCodeComponent } from './confirmation-code/confirmation-code.component';
import { cNotfoundComponent } from './404/notfound.component';
import { cLoginComponent } from './login/login.component';
import { cRequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { cSignupComponent } from './signup/signup.component';
import { cSuccessResetPasswordComponent } from './success-reset-password/success-reset-password.component';
import { cEnterEmailComponent } from './enter-email/enter-email.component';
import { cConfirmationMessageComponent } from './confirmation-message/confirmation-message.component';
import { cPatientRegistrationComponent } from './patient-registration/patient-registration.component';
import { cCompleteRegistartionComponent } from './complete-registartion/complete-registartion.component';
import { cConfirmResetPasswordComponent } from './confirm-reset-password/confirm-reset-password.component';
import { InternalServerComponent } from './internal-server/internal-server.component';
export const AuthenticationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '404', 
        component: cNotfoundComponent
      },
      
      {
        path: 'login',
        component: cLoginComponent
      },
     
      {
        path: 'reset-password',
        component: cRequestResetPasswordComponent
      },

      {
        path: 'confirm-reset-password',
        component: cConfirmResetPasswordComponent
      },
      
      {
        path: 'reset-password-success',
        component: cSuccessResetPasswordComponent
      },

      {
        path: 'verify-otp',
        component: cConfirmationCodeComponent
      },

      {
        path: 'verify-email',
        component: cEnterEmailComponent
      },
      
      {
        path: 'verify-email-success',
        component: cConfirmationMessageComponent
      },

      {
        path: 'registration1',
        component: cCompleteRegistartionComponent
      },
      
      {
        path: 'registration2',
        component: cPatientRegistrationComponent
      },
      
      {
        path: 'signup',
        component: cSignupComponent
      },
      {
        path: '500',
        component: InternalServerComponent
      },
      {
        path: '**', 
        redirectTo : 'login',
        pathMatch : 'full',
      },
    ]
  }
];
