import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// service/guards/interceptors
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { cHeadersInterceptor } from './interceptors/headers.interceptor';
//components
import { cAboutComponent } from './components/about/about.component';
import { cHeaderComponent } from './shared/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { cRequestResetPasswordComponent } from './components/authentication/request-reset-password/request-reset-password.component';
import { cSuccessResetPasswordComponent } from './components/authentication/success-reset-password/success-reset-password.component';
import { cConfirmResetPasswordComponent } from './components/authentication/confirm-reset-password/confirm-reset-password.component';
import { BlankComponent } from './Layouts/blank/blank.component';
import { FullComponent } from './Layouts/full/full.component';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { TalkService } from './services/talk.service';

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { Safe } from './shared/pipe/safe';
import { AuthenticationModule } from './components/authentication/authentication.module';
import { Idle, NgIdleModule } from '@ng-idle/core';
initializeApp(environment.firebase);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    cAboutComponent,
    cHeaderComponent,
    cRequestResetPasswordComponent,
    cSuccessResetPasswordComponent,
    cConfirmResetPasswordComponent,
    BlankComponent,
    FullComponent,
    Safe
  ],
   
  imports: [
    BrowserModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    RouterModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AuthenticationModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    DatePipe,
    AuthGuard,
    AuthService,
    DataService,
    TalkService,
    { provide: HTTP_INTERCEPTORS, useClass: cHeadersInterceptor, multi: true },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS, useClass: cHeadersInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
