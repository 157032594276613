import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { SessionService } from 'src/app/services/session.service';
import { TalkService } from 'src/app/services/talk.service';
import { environment } from "../../../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { interval, Subscription } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import { startWith, switchMap } from 'rxjs/operators';

declare let $ : any ;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class cHeaderComponent implements OnInit, OnDestroy {
  displayHeaderOptions: boolean = true;
  userImage: string = "";
  selectedLanguage: string = "English";
  userRole: string;
  usersRole: string;
  unreadMessages: any = 0;
  isProvider: boolean = false;
  vacationStatus: boolean = false;
  providerId: any;
  message:any = null;
  userId: string;
  adminId: any;
  subscription: Subscription;
  menuBarColor: any;
  menuBarTextColor: any;
  clientImageUrl: any;
  responseAlert: boolean;
  alertColor: string;
  resMessage: string;
  providerDoseSpotId: string;
  notificationCount:any;
  doseSpotIframeUrl: any;
  timeInterval: any;
  providerValidation: Subscription;
  logout: boolean =false;
  deviceToken : string = "";
  userDeviceId : any;
  clientDetailscheck:any
  constructor(private themeService : ThemeService, private router: Router, public translateService: TranslateService, private talkService: TalkService, private dataService: DataService,private sessionService : SessionService) {
    //change language
    translateService.addLangs(['English', 'Spanish']);
    translateService.setDefaultLang(localStorage.getItem('lsLanguage'));

    const browserLang = translateService.getBrowserLang();

    if (localStorage.getItem('lsLanguage')) {
      this.selectedLanguage = localStorage.getItem('lsLanguage');
      translateService.use(localStorage.getItem('lsLanguage'));
    }
    else {
      translateService.use(browserLang.match(/English|Spanish/) ? browserLang : 'English');
      this.cHeaderComponent_SetLanguage('English');
    }
    //hide controls on authentication module
    if (this.router.url.includes('authentication')) {
      this.displayHeaderOptions = false
    }

  }

  ngOnInit(): void {
    // menu bar color
    this.themeService.incommingMenuBarColor.subscribe(res=>{
      this.menuBarColor = res
    })
    // menu bar text color
    this.themeService.incommingMenuBarTextColor.subscribe(res=>{
      this.menuBarTextColor = res
    });
    // client image
    this.themeService.clientImageUrl.subscribe(res =>{
      this.clientImageUrl = res
    })
    this.themeService.clientDetails.subscribe(res =>{
     this.clientDetailscheck = res
    })
    this.dataService.profileImage.subscribe(res => {
      this.userImage = res
    })
    this.userRole = localStorage.getItem('userRole');
    if(this.userRole == 'editor')
    {
      this.providerDoseSpotId = JSON.parse(localStorage.getItem('ProviderDoseSpotID'));
      this.sProviderDashboardComponent_GettingProviderDetails();
    }
    let sToken = localStorage.getItem(environment.AccessToken);
    if(sToken)
    {
      this.cHeaderComponent__GetHeaderDetails();
      
      this.cHeaderComponent_ListenForNotifications();
    }
  }
  sProviderDashboardComponent_GettingProviderDetails()
  {
   
      this.providerValidation = this.dataService.providerDetails.subscribe(result => 
        {
          console.log("details recieved ===>>>>", result);
          this.providerDoseSpotId = result.doseSpotProviderId;
          this.userRole = result.userRole;
          console.log("User ROle ==>>>",this.userRole)
          if(this.providerDoseSpotId !== null && this.providerDoseSpotId !== undefined && this.providerDoseSpotId !== '') 
          {
            console.log("notification count");
           if(!this.timeInterval && this.userRole == 'editor')this.ProviderDashboardComponent_GetDoseSpotNotificationCount();
          }
        })
    
  }
  ProviderDashboardComponent_GetDoseSpotNotificationCount() 
  {
    let body = {providerId: this.providerDoseSpotId?.toString()}
    this.timeInterval = interval(300000).
    pipe(startWith(0),switchMap(()=> this.dataService.DataService_GetDoseSpotNotificationCount(body))).subscribe(result =>
      {
        if(result.success)
        {
          let notificationCount = result.data.PendingPrescriptionsCount + result.data.PendingRxChangeCount + result.data.RefillRequestsCount + result.data.TransactionErrorsCount;
          this.notificationCount = notificationCount;
          console.log("notification count from doseSpot =====>>>>>>", this.notificationCount);

        }
      },(error)=>
      {
      })
  }
  cProviderDashboardComponent_GetDoseSpotUrl()
  {
    try 
    {
      let body = 
      {
        providerId: this.providerDoseSpotId
      }
      this.dataService.DataService_GetDoseSpotNotificationPortal(body).subscribe(result =>
        {
          if(result.status == 'success')
          {
            console.log("cProviderDashboardComponent_GetDoseSpotUrl: Response ====>>>>>", result);
            this.doseSpotIframeUrl = result.data.url;
            $('#notificationModal').modal('show');
          }
        },
        (error)=>
        {
          console.error("cProviderDashboardComponent_GetDoseSpotUrl: ERROR====>>>>", error);
          
        })
    } 
    catch (error) 
    {
      console.error("cProviderDashboardComponent_GetDoseSpotUrl: Error====>>>>",error);
      
    }
  }
  
  cHeaderComponent__GetHeaderDetails() {
    try {
      let body = {};
      this.dataService.DataService_GetHeaderDetails(body).subscribe((result) => {
        if (result.status === "success") {
          this.usersRole = result.data.role;
          this.userImage = result.data.profileImage;
          if (this.usersRole === "editor") {
            this.isProvider = true;
            this.vacationStatus = result.data.onVacation === 0 ? false : true;
            this.providerId = result.data.providerId;
            this.userDeviceId = this.providerId;
            console.log("cHeaderComponent__GetHeaderDetails RESULT", result);
            this.cHeaderComponent_RequestForNotificationPermission();
          }
          else if(this.usersRole === "admin")
          {
            this.adminId = result.data.adminId;
            this.userDeviceId = this.adminId;
            console.log("ADMIN ID::",this.adminId);
            this.cHeaderComponent_RequestForNotificationPermission();
          }
          else
          {
            this.cHeaderComponent_SetLanguage(result.data.language??"English");
            this.translateService.use(result.data.language??"English");
          }
          let permissions = {};
          permissions = result.data.permissions;
          this.sessionService.cSessionService_SetPermissions(permissions);
        }
        else {
          console.log("cHeaderComponent__GetHeaderDetails ERROR", result.message);
          this.cHeaderComponent_DisplayAlertMessage('alert-danger', result.message)
        }
        
      },
      (error)=>
      {
        this.cHeaderComponent_DisplayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
      });
    }
    catch (catchError) {
      console.log("cHeaderComponent__GetHeaderDetails ERROR", catchError);
    }
  }
  cHeaderComponent_RequestForNotificationPermission() {
    const messaging = getMessaging();
    getToken(messaging, 
      { vapidKey: environment.firebase.vapidKey}).then(
        (currentToken) => {
          if (currentToken) {
            this.deviceToken = currentToken;
            console.log("Hurraaa!!! we got the token.....",currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          
        }).then((res) =>{
            this.cHeaderComponent_SaveDeviceToken(this.userDeviceId,this.deviceToken);
        });
      }
      cHeaderComponent_ListenForNotifications() {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          this.message=payload;
          if(this.message.data.type==="Popup")
          {
            $('#NotificationPopUp').modal('show');
          }
    });
  }
  cHeaderComponent_Navigate(screen) {
    if (screen==='dashboard') 
    {
      if (this.usersRole === "editor") {
        this.router.navigateByUrl('provider/');
      }
      else if (this.usersRole === "user") {
        this.router.navigateByUrl('patient/');
      }
      else if (this.usersRole === "admin") {
        this.router.navigateByUrl('admin/');
      }
    }
    else if(screen==='availability')
    {
      if (this.usersRole === "editor") {
        this.router.navigateByUrl('provider/availability');
      }
      else if (this.usersRole === "admin") {
        this.router.navigateByUrl('admin/settings/availability');
      }
      else{
        this.router.navigateByUrl('');
      }
    }
  }

  cHeaderComponent_SetLanguage(incommingSelectedlanguage: string, menuConfigurations?:any) {
    this.selectedLanguage = incommingSelectedlanguage;
    localStorage.setItem('lsLanguage', incommingSelectedlanguage);
    if(menuConfigurations)
    {
      localStorage.setItem('backgroundColor',menuConfigurations.backgroundColor);
      localStorage.setItem('menuBarColor',menuConfigurations.menuBarColor);
      localStorage.setItem('menuBarTextColor',menuConfigurations.menuBarTextColor);
      localStorage.setItem('cloudName',menuConfigurations.cloudName);
      localStorage.setItem('Base64ClientImage',menuConfigurations.Base64ClientImage);
      localStorage.setItem('clientImage',menuConfigurations.clientImage);
    }
    this.dataService.languageSelected.next(incommingSelectedlanguage);
  }
  cHeaderComponent_logout() 
  {
    if(this.timeInterval)this.timeInterval.unsubscribe();
    if(this.providerValidation)this.providerValidation.unsubscribe();
    this.logout = true;
    const selectedLanguage: string = localStorage.getItem('lsLanguage');
    let menuConfigurations = this.cHeaderComponent_ReturnMenuConfigurations()
    let providerId = localStorage.getItem('providerId');
    console.log("Provider ID",providerId);
    if(providerId !== null || providerId !== undefined)
    {
      let oBody = 
      {
        providerId:providerId
      }
      this.dataService.DataService_RemoveProviderToken(oBody).subscribe(
        result=>{
          if (result.status=='success')
          {
            console.log("Token Removed",result);
          } 
          else 
          {
            console.log("DataService_RemoveProviderToken===>Error",result);
            
          }
        },
        (error)=>
        {
          console.log("DataService_RemoveProviderToken===>Error",error);
        }
      )
    }
    localStorage.clear();
    this.cHeaderComponent_SetLanguage(selectedLanguage, menuConfigurations);
    this.talkService.TalkService_DestroyAlloadedPopups();
    window.location.reload();
  }
  cHeaderComponent_ReturnMenuConfigurations()
  {
    let oConfigurations =
    {
      backgroundColor: localStorage.getItem("backgroundColor"),
      menuBarColor: localStorage.getItem("menuBarColor",),
      menuBarTextColor:localStorage.getItem("menuBarTextColor"),
      cloudName: localStorage.getItem("cloudName"),
      Base64ClientImage:localStorage.getItem("Base64ClientImage"),
      clientImage:localStorage.getItem("clientImage"),
    }
    return oConfigurations;
  }
  NavigateToChat() {
    if (this.userRole === 'editor') {
      this.router.navigateByUrl('/provider/chat')
    }
    else if (this.userRole === 'user') {
      this.router.navigateByUrl('/patient/chat')
    }
    else {
      this.router.navigateByUrl('/admin/chat')
    }
  }
 

  cHeaderComponent_ChangeVacationStatus() {
    try {
      let body = {
        providerId: this.providerId,
        onVacation: !this.vacationStatus
      }

      this.dataService.DataService_SetVacationStatus(body).subscribe((result) => {
        if (result.status === "success") {
          if (this.vacationStatus === false)
            this.vacationStatus = true;
          else
            this.vacationStatus = false;
        }
        else {
          console.log("cHeaderComponent_ChangeVacationStatus===>Failed:", result.message);
          this.cHeaderComponent_DisplayAlertMessage('alert-danger', result.message)
        }
      },
      (error)=>
      {
        this.cHeaderComponent_DisplayAlertMessage('alert-danger',"An internal server error occured. Please try again later.")
      })
    }
    catch (catchError) {
      console.log("cHeaderComponent_ChangeVacationStatus ERROR", catchError);
    }
  }

  ngOnDestroy() {
    this.talkService.TalkService_DestroyAlloadedPopups();
    if(this.subscription)this.subscription.unsubscribe();
    if(this.timeInterval)this.timeInterval.unsubscribe();
    if(this.providerValidation)this.providerValidation.unsubscribe();
    $('#notificationModal').modal('hide');
  }
  cHeaderComponent_NavigateToPatientList()
  {
    this.userRole === 'admin' ? this.router.navigateByUrl('/admin/patients') :  this.router.navigateByUrl('/provider');
  }
  cHeaderComponent_SaveDeviceToken(incomingId,incommingToken)
  {
    console.log("Incoming Token Will Be ==>>>",incommingToken)
    let body =
    {
    id : incomingId,
    token: incommingToken 
    }
    console.log("BODY::",body);
    
    try {
      this.dataService.DataService_SaveDeviceToken(body).subscribe(result => {
        if (result.status == 'failed') {
          console.log("cHeaderComponent_SaveDeviceToken: ERROR ===>>>", result.message);
          this.cHeaderComponent_DisplayAlertMessage('alert-danger', result.message)
        }
        else {
          console.log("cHeaderComponent_SaveDeviceToken: Response ===>>",);
        }
      },
        (error) => {
          console.error("cHeaderComponent_SaveDeviceToken: ERROR ===>>>", error);
          this.cHeaderComponent_DisplayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
        }
      )
    }
    catch (CatchError) {
      console.error("cHeaderComponent_SaveDeviceToken: Error ===>>>", CatchError);
    }
  }
  DefaultImage()
  {
    this.clientImageUrl = '../../../assets/images/default-logo.png'
  }
  cHeaderComponent_DisplayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }
  cHeaderComponent_DismissAlert()
  {
    this.responseAlert=false;
  }
  cHeaderComponent_RefreshonLogo(){
    this.clientDetailscheck==''?
    this.router.navigateByUrl('/authentication/login'):
    this.router.navigateByUrl('/authentication/500')
  }

}
