import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-enter-email',
  templateUrl: './enter-email.component.html',
  styleUrls: ['./enter-email.component.css']
})
export class cEnterEmailComponent implements OnInit {
  emailForm: FormGroup;
  responseAlert: boolean = false;
  alertColor: string;
  resMessage: string;
  userDetails: any;
  constructor(private router: Router, private formBuilder: FormBuilder, private dataService: DataService, private authService: AuthService) { }

  ngOnInit(): void {
    this.dataService.displayHippaElement.next(true)
    this.userDetails = JSON.parse(localStorage.getItem('RegisterAccount'));
    this.initializeEmailForm();
  }

  // ****************************************** Form ***************************************

  initializeEmailForm() {
    let clientWeb = location.href;
    clientWeb = clientWeb.substring(0, clientWeb.indexOf(this.router.url))
    const emailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$)/;
    this.emailForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      patientFirstName: this.userDetails?.first_name ?? "",
      patientLastName: this.userDetails?.last_name ?? "",
      clientName: this.authService.AuthService_ReturnClientName(),
      phone: this.userDetails.phone??'',
      clientWeb: clientWeb
    })
  }

  sendOneTimePinViaEmail() {
    if (this.emailForm.valid) {
      this.dataService.DataService_SendOneTimePinViaEmail(this.emailForm.value).subscribe(result => {
        if (result.status == 'failed') {
          this.displayAlertMessage('alert-danger', result.message)
        }
        else {
          localStorage.setItem('userEmail', this.emailForm.controls['email'].value);
          this.router.navigate(['/authentication/verify-email-success']);
        }
      },
        (error) => {
          this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
        })
    }
    else {
      this.emailForm.markAllAsTouched();
    }
  }

  // ****************************************** Functions ***************************************

  dismissAlert() {
    this.responseAlert = false;
  }

  // ****************************************** Alert Message ***************************************

  displayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }
}
