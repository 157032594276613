import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import version from 'package.json';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class DataService {
  constructor(private http: HttpClient) {
    console.log("Corstet Application Version Number::::>>>", version.version);
  }
  displayHippaElement = new BehaviorSubject(true);
  profileImage = new BehaviorSubject<any>("");
  routerCheckPatient = new BehaviorSubject(false);
  passwordText = new BehaviorSubject<any>("");
  providerDetails = new BehaviorSubject<any>("");
  languageSelected = new BehaviorSubject<any>("");
  consultationResult = new BehaviorSubject<any>("");
  pharmacyAddress = new BehaviorSubject<any>("");
  clearSignatureInput = new BehaviorSubject<boolean>(false);

  // Get Patient Details
  DataSevice_GetPatientDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPatientDetailsV5', body);
  }

  DataService_UpdatePatientImage(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload', body)
  }
  DataService_RegisterNewPatient(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddPatientDetailsV5', body)
  }

  // Update patient Image
  DataService_GetChecklistDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDetailsForChecklistV5', body)
  }

  // Send OneTimePin
  DataService_SendOneTimePin(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/sendOneTimePin', body)
  }

  // Verify OneTimePin
  DataService_VerifyOneTimePin(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/verifyOneTimePin', body)
  }

  // Send OneTimePin By Email
  DataService_SendOneTimePinViaEmail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/sendOneTimeCodeByEmail', body)
  }
  // Signup
  DataService_SignUp(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/signup', body)
  }
  DataService_AddNewUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body)
  }
  DataService_resetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body)
  }
  DataService_AddProviderDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddProviderDetailsV5', body)
  }
  //getting householdhistory
  DataService_GetPatientConsultationList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPatientConsultationListV5', body)
  }
  //getting specific consultations details
  DataService_GetSpecificConsultationDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConsultationDetailV5', body)
  }

  //Get Provider Dashboard Details
  DataService_GetProviderDashboardDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderDashboardV5', body)
  }

  //Get Provider Chart Data
  DataService_GetProviderChartData(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderDashboardChartV5', body)
  }

  //Get Provider Patient List
  DataService_GetProviderPatientList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderPatientsV5', body)
  }

  //Get Provider Selected Patient Details
  DataService_GetProviderSelectedPatientDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderPatientDetailsV5', body)
  }

  //Get Admin Provider List
  DataService_GetAdminProviderList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminProvidersV5', body)
  }

  //Get provider profile details
  DataService_GetProviderProfileDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderDetailsV5', body)
  }

  DataService_UpdateProviderDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateProviderDetailsV5', body)
  }

  DataService_changePassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/changePassword', body)
  }

  //Get Selected Admin Provider Details
  DataService_GetSelectedAdminProviderDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderDetailsV5', body)
  }

  //Update Admin Provider Password
  DataService_updateAdminProviderPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body)
  }

  //Get Admin Dashboard Details  
  DataService_GetAdminDashboardDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminDashboardV5', body)
  }

  //Get Admin Patient List  
  DataService_GetAdminPatientList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminPatientListV5', body)
  }
  // Get Admin Patient Details
  DataService_GetAdminSelectedPatientDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminPatientDetailV5', body)
  }
  // Get Admin Patient Consult Notes
  DataService_GetAdminSelectedPatientConsultNotes(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConsultNotesV5', body)
  }

  // Add Admin Patient Consult Notes
  DataService_AddAdminSelectedPatientConsultNotes(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddConsultNotesV5', body)
  }

  // Patient-Intake Component
  DataService_ConsultGetTherapyTypes(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetTherapyTypesV5', body)
  }
  DataService_ConsultGetQuestions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetIntakeQuestionsV5', body)
  }
  DataService_GetCommTypes(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetComTypesV5', body)
  }
  DataService_GetInvoiceDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConsultInvoiceV5', body);
  }
  DataService_SaveConsult(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveConsultV5', body);
  }

  // Get Admin Medications list
  DataService_GetAdminMedicationsList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMedicationList', body)
  }
  // Get Admin Medications Details
  DataService_GetAdminMedicationsDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMedicationDetails', body)
  }
  // Get Admin Medications Save and update
  DataService_AdminMedicationsSaveAndUpdate(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveMedicationdetail', body)
  }
  // Get Admin settings list
  DataService_GetAdminSettingList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminAdminsV5', body)
  }
  // Get Admin settings list
  DataService_GetAdminSettingDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminDetailsV5', body)
  }
  // Get Admin settings list
  DataService_SaveAndUpdateAdminSetting(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveAdminDetailsV5', body)
  }
  // Get Admin Client list
  DataService_GetAdminClientlist(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminClientsV5', body)
  }

  //cEditProfileComponent_
  //Get household members
  DataService_GetHouseholdMembers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetHouseHoldMembersV5', body)
  }
  DataService_GetHouseholdMemberDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPatientProfileV5', body)
  }
  DataService_SavePatientProfile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SavePatientProfileV5', body)
  }
  //toggle medication or allergy Status of House hold members 
  DataService_ToggleHouseHoldMemberMedicationOrAllergyStatus(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DoseSpotToggleAllergyAndMedicationV5', body)
  }
  // Get Admin settings patient list
  DataService_GetAdminSettingPatientList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminTotalPatientsV5', body)
  }
  // Get Admin settings patient details
  DataService_GetAdminSettingPatientDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminPatientDetailsV5', body)
  }

  // Get Admin Client Details
  DataService_GetAdminClientDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminClientDetailsV5', body)
  }
  // Add Admin Client
  DataService_AddAdminClient(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveAdminClientV5', body)
  }
  // Add Tenant For New Admin Client
  DataService_AddTenantForNewAdminClient(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/save/auth/vault/Org', body)
  }
  DataService_GetHeaderDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetHeaderDetailV5', body)
  }
  DataService_SetVacationStatus(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetVacationV5', body)
  }
  DataService_GetConsultationIntakeDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConsultationIntakeDetailsV5', body)
  }
  //Get Admin Notifications
  DataService_GetAdminNotifications(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminNotificationsV5', body)
  }
  //Save Admin Notificaitons
  DataService_SaveAdminNotifications(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SendNotificationsV5', body)
  }

  //Get Available Providers
  DataService_GetAvailableProviders(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAvailableProvidersV5', body)
  }
  //Assign Notificaitons
  DataService_AssignProvider(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AssignProvidersV5', body)
  }
  //download report
  DataService_DownloadReport(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminCustomReportsV5', body)
  }
  //update consultation status
  DataService_UpdateConsultationStatus(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateConsultStatusV5', body)
  }
  //update communication type 
  DataService_UpdateCommunicationType(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateCommStatusV5', body)
  }
  //get provider availability
  DataService_GetProviderAvailability(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderAvailabilityV5', body)
  }
  // set provider Availability
  DataService_SetProviderAvailability(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetProviderAvailabilityV5', body)
  }
  // delete provider availability
  DataService_DeleteProviderAvailability(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteProviderAvailabilityV5', body)
  }
  // save admin notifications
  DataService_SaveDeviceToken(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveDeviceTokenV5', body)
  }
  // get available slots
  DataService_GetAvailableSlots(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAvailableSlotsV5', body)
  }

  // Get Admin Providers Availability
  DataService_GetProvidersAvailability(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProviderAvailabilityV5', body)
  }
  //update provider availability
  DataService_UpdateProviderAvailability(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UpdateProviderAvailabilityV5', body)
  }
  // Get Filtered Client Availability
  DataService_GetFilteredClientAvailability(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetClientAvailabilityV5', body)
  }
  // Get Client details
  DataService_GetClientDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/preauth_function/shared/GetClientDetailsV5', body)
  }
  // Send verification Code
  DataService_SendVerificationCode(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/preauth_function/shared/SendVerificationCode', body)
  }
  // Sign In with phone number
  DataService_SingInWithPhoneNumber(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/preauth_function/shared/VerifyCode', body)
  }
  // Activate And Deactivate Provider
  DataService_ActivateAndDeactivateProvider(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body)
  }
  //forget password
  DataService_ForgetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/forgotPassword', body);
  }

  //reset password
  DataService_ResetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body);
  }
  //reassign provider
  DataService_ReassignProvider(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ReassignProvidersV5', body)
  }
  //medication search 
  DataService_SearchMedication(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DoseSpotSearchSimpleMedicationV5', body)
  }
  //allergies search 
  DataService_SearchAllergies(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DoseSpotSearchAllergyV5', body)
  }
  //Get Pharacy List
  DataService_GetPharacyList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DoseSpotSearchPharmacyV5', body)
  }

  //Add Patient Pharmacy
  DataService_AddPatientPharmacy(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddPatientPharmacyV5', body)
  }
  //DoseSpot Pharmacy Search:
  DataService_GetDoseSpotIframeUrl(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DoseSpotIframeUrlV5', body)
  }
  //DoseSpot medication:
  DataService_PrescriptionConfirmation(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/PrescriptionCheckV5', body)
  }
  //Get Previous Medication And Allergies:
  DataService_GetPreviousMedicationAndAllergies(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPreviousMedicationsV5', body)
  }
  //DeleteUser
  //https://app.corstet-dev.trilloapps.com/_service/um/deleteUser?id=55
  DataService_DeleteUser(nIncomingUserId): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser?id=' + nIncomingUserId)
  }
  //Get Previous LifeStyle:
  DataService_GetPreviousLifeStyle(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPreviousLifestylesV5', body)
  }
  //get dosespot notification count
  DataService_GetDoseSpotNotificationCount(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DoseSpotProviderNotificationCountV5', body)
  }
  //get dosespot notification portal
  DataService_GetDoseSpotNotificationPortal(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DoseSpotProviderNotificationPortalV5', body)
  }
  //add admin settings questions 
  DataService_AddQuestions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AddQuestionV5', body)
  }
  DataService_RemoveProviderToken(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/UserLogOutV5', body)
  }
  DataService_GetConsultTypeList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConsultTypesListV5', body)
  }
  DataService_SaveConsultType(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveConsultTypesV5', body)
  }
  DataService_GetTwilioToken(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetTwilioTokenV5', body)
  }
  //Admin Intake Questions
  DataService_GetAdminIntakeQuestions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminIntakeQuestionsV5', body)
  }
  //Delete Intake Question
  DataService_DeleteQuestion(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteQuestionV5', body)
  }
  DeleteCreatedClientOrg(nClientId): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/ds/delete/auth/vault/Org?permanent=true&id=' + nClientId)
  }
  // Get Fill Questionaire Details
  DataService_GetFaxForumDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetFaxFormDetailsV5', body)
  }

  DataService_GetAffiliatelist(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminAffiliateV5', body)
  }
  DataService_GetAffiliateDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAdminSpecificAffiliateV5', body)
  }
  DataService_SaveAffiliateDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SaveAffiliateDetailsV5', body)
  }
  DataService_SendFax(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SendFaxV5', body)
  }

  // Age Validation 
  underAgeValidate(birthday) {
    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    var optimizedBirthday = birthday.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday: any = new Date(optimizedBirthday);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate: any = new Date()

    // calculate age comparing current date and borthday
    var myAge = ~~((currentDate - myBirthday) / (31557600000));

    if (myAge < 18) {
      return false;
    } else {
      return true;
    }

  }
  DataService_GetConsultFaxPDFReport(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetConsultFaxUrlV5', body)
  }
}
