import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from './services/theme.service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { DataService } from './services/data.service';
import { environment } from 'src/environments/environment';
import { BrowserCompatibilityService } from './services/browser-compatibility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  title = 'corstet';
  bLoading: boolean = true;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  selectedLanguage: string = "English";
  oldBrowser: boolean;
  broswerNameVersion: { browserName: string; browserVersion: number; };

  constructor(private broswerVersionService: BrowserCompatibilityService, private checkIdleState: Idle, private keepalive: Keepalive, private router : Router,private themeService : ThemeService, private dataService:DataService) 
  {
    this.oldBrowser = this.broswerVersionService.isOldBrowser();
    this.broswerNameVersion =this.broswerVersionService.GetBrowserNameAndVersion();
     // sets an idle timeout of 5 seconds, for testing purposes.
     checkIdleState.setIdle(5);
     // sets a timeout period of 15 minutes. After 15 minutes and 5 seconds of inactivity, the user will be considered timed out.
     checkIdleState.setTimeout(900);
     // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
     checkIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);
 
     checkIdleState.onIdleEnd.subscribe(() => 
     {
       this.idleState = 'No longer idle.'
       console.log("On idle end Idle state : ==> ", this.idleState);
     });
     checkIdleState.onTimeout.subscribe(() => {
       this.idleState = 'Timed out!';
       this.timedOut = true;
       console.log("On Time out Idle state : ==> ", this.idleState);
       const selectedLanguage: string = localStorage.getItem('lsLanguage');
       let providerId = localStorage.getItem('providerId');
        console.log("Provider ID",providerId);
        if(localStorage.getItem('userRole')=="editor")
        {
          let body = 
          {
            providerId:providerId
          }
          this.dataService.DataService_RemoveProviderToken(body).subscribe(
            result=>{
              if (result.status=='success')
              {
                console.log("Token Removed",result);
              } 
              else 
              {
                console.log("DataService_RemoveProviderToken===>Error",result);
                
              }
            },
            (error)=>
            {
              console.log("DataService_RemoveProviderToken===>Error",error);
            }
          )
        }
        setTimeout(() => {
          localStorage.clear();
          localStorage.setItem('lsLanguage', selectedLanguage);
          this.router.navigateByUrl('/authentication/login');
          this.AppComponent_ResetTimer();
          window.location.reload();
        }, 2000);
     });
     checkIdleState.onIdleStart.subscribe(() => 
     {
       this.idleState = 'You\'ve gone idle!'
       console.log("On idle start Idle state : ==> ", this.idleState);
     });
     checkIdleState.onTimeoutWarning.subscribe((countdown) => 
     {
       this.idleState = 'You will time out in ' + countdown + ' seconds!';
     });
 
     // sets the ping interval to 15 seconds
     keepalive.interval(5);
 
     keepalive.onPing.subscribe(
       () => 
       {
         this.lastPing = new Date();
         const sessionExpiryTime = this.lastPing.getTime() + 900000;
         localStorage.setItem("lsCorstetSessionExpiryTime",JSON.stringify(sessionExpiryTime))
       });
 
      this.AppComponent_ResetTimer();
  }
  AppComponent_ResetTimer() 
  {
    this.AppComponent_CheckSessionExpiry()
    this.checkIdleState.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    console.log("AppComponent_ResetTimer Called!!");
  }

  AppComponent_CheckSessionExpiry() {
    const date = new Date();
    const sessionExpiryTime = JSON.parse(localStorage.getItem("lsCorstetSessionExpiryTime"));
    const currentTime = date.getTime();

    if (sessionExpiryTime !== null) {
      console.log("Session_CheckSessionExpiry: Value of lsCorstetSessionExpiryTime {%s}", sessionExpiryTime);
      console.log("Session_CheckSessionExpiry: Current Time {%s}", currentTime);
      // compare the expiry time of the item with the current time
      if (currentTime > sessionExpiryTime) {
        localStorage.removeItem("lsCorstetSessionExpiryTime");
        let providerId = localStorage.getItem('providerId');
        console.log("Provider ID",providerId);
        if(providerId !== null || providerId !== undefined)
        {
          let body = 
          {
            providerId:providerId
          }
          this.dataService.DataService_RemoveProviderToken(body).subscribe(
            result=>{
              if (result.status=='success')
              {
                console.log("Token Removed",result);
              } 
              else 
              {
                console.log("DataService_RemoveProviderToken===>Error",result);
                
              }
            },
            (error)=>
            {
              console.log("DataService_RemoveProviderToken===>Error",error);
            }
          )
        }
        localStorage.clear();
        console.log("Session_CheckSessionExpiry: Current Session is expired. So, logging out");
        this.router.navigateByUrl('/authentication/login');
        window.location.reload();
      }
    }
  }

  ngOnInit(): void {
    this.themeService.ThemeService_GetClientDetails();
    this.themeService.incommingBgBodyColor.subscribe(res=>{
      if(res)
      {
        this.bLoading = false
      }
    })
    this.themeService.clientDetails.subscribe(errorEmitted =>
      {
        if(errorEmitted!=='')
        {
          this.bLoading = false;
          this.router.navigateByUrl('authentication/500')

        }
      })
  }
}
