import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.css']
})
export class FullComponent implements OnInit {

  constructor(private themeService : ThemeService) { }

  ngOnInit(): void {
    this.themeService.incommingBgBodyColor.subscribe(res=>{
      let bodyBg  = document.getElementsByTagName('body')[0];
      bodyBg.id = 'custom-class'
      bodyBg.style.background = res;
      console.log('incomming response:::',res)
    })
  }

}
