import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { MustMatch } from "src/app/services/must-match.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.css']
})
export class cConfirmResetPasswordComponent implements OnInit {
  resetPasswordForm : FormGroup;
  emailVerifyKey : string = "";
  responseAlert: boolean;
  alertColor: string;
  resMessage: string;
  checkLanguage: any;
  constructor(private router: Router , private dataService: DataService, private formBuilder: FormBuilder,private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.dataService.displayHippaElement.next(true);
    this.route.queryParams.subscribe(params=>
      {
        this.emailVerifyKey = params.p;
      });
    this.initializeResetPasswordForm();
  }

// ****************************************** Form ***************************************

  initializeResetPasswordForm(){
    const passRejex =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{9,50}$/;
    this.resetPasswordForm = this.formBuilder.group(
      {
      emailVerifyKey : [this.emailVerifyKey,[ Validators.required]],
      password: [null, [Validators.required, Validators.minLength(9),Validators.pattern(passRejex)]],
      rptPassword:  [null, [Validators.required]],
      },
        {validator: MustMatch('password', 'rptPassword')}
    )};

// ****************************************** Reset Password ***************************************

resetPassword() {
  if (this.resetPasswordForm.valid) {
    this.dataService.DataService_ResetPassword(this.resetPasswordForm.value).subscribe(
      result => {
        if (result.status === 'success') {
          this.router.navigateByUrl('/authentication/reset-password-success');
          this.dataService.languageSelected.subscribe(res => {
            this.checkLanguage = res;
            const passwordText = (this.checkLanguage === 'Spanish') ? 'restablecimiento de contraseña con éxito' : 'Password reset successfully.';
            this.dataService.passwordText.next(passwordText);
          });
          this.checkLanguage = localStorage.getItem('lsLanguage');
        } else {
          this.displayAlertMessage('alert-danger', result.message);
        }
      },
      error => {
        console.error("resetPassword: ERROR ===>>>", error);
        this.displayAlertMessage('alert-danger', "An internal server error occurred. Please try again later.");
      }
    );
  } else {
    this.resetPasswordForm.markAllAsTouched();
  }
}

 // ****************************************** Functions ***************************************

  dismissAlert(){
    this.responseAlert = false;
  }

  // ****************************************** Alert Messages ***************************************

  displayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }

}
