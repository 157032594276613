import { Component, OnInit } from '@angular/core';
import * as notes from '../../../assets/ReleaseNotes.json';
import  version  from 'package.json';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class cAboutComponent implements OnInit {
  applicationBuildNumber: any = '0.0.0';
  platfromBuildNumber: any = '0.0.0';
  buildVersion : string;
  displayBuildNumber : boolean =false;
  releaseNotes : any = [];
  notesPageSize = 5;
  notesCurrentPage = 1;
  bLoading: boolean = false
  constructor() { }

  ngOnInit(): void {
    this.buildVersion = version.version;
    this.releaseNotes = notes;
    console.log('AboutComponent : Release notes  ==> ',this.releaseNotes);
  }

}
