<!-- <app-header></app-header> -->
<div *ngIf="responseAlert" class="alert data-table alert-messages {{alertColor}}"  role="alert"> {{resMessage | titlecase}}
  <button type="button" class="btn-close ml-1 mt-1" data-dismiss="alert" aria-label="Close" (click)="dismissAlert()">
     </button>
</div>
<!-- second form -->
<section>
      <div class="complete-registration authentication-labels">
        <div class="row authentication-cards">
          <div class="col-lg-12">
            <h2 class="text-center mb-4 heading-text">{{'AUTHENTICATION.COMPLETEREGISTRATION.MainHeading' | translate}}</h2>

            <div class="register-card card">
              <div class="card-body p-4">
                <form [formGroup]="completeRegistrationForm">
                  <!-- NameLabelText -->
                  <div class="row"> 
                    <div class="form-group col-md-1">
                      <div class="form-outline mb-4">
                        <label class="ml-1 mb-0" style="white-space: nowrap;" for="inputAddress">{{'AUTHENTICATION.COMPLETEREGISTRATION.Prefix' | translate}}</label>
                        <input type="text" id="form3Example1cg"  placeholder="{{'PATIENT.ADDFAMILYMEMBER.Prefix' | translate}}" class="form-control form-control-lg mb-1" formControlName="prefix" maxlength="10">                       
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <div class="form-outline mb-4 readonly">
                        <label class="  ml-1 mb-0 " for="inputAddress">{{'AUTHENTICATION.COMPLETEREGISTRATION.FirstNameLabelText' | translate}}</label>
                        <input type="text" id="form3Example1cg" readonly placeholder="{{'PATIENT.ADDFAMILYMEMBER.FirstNamePlaceholderText' | translate}}" class="form-control form-control-lg mb-1" formControlName="first_name">
                        <!-- FirstNameFieldValidationMessage -->                                  
                        <span *ngIf="completeRegistrationForm.controls['first_name'].hasError('required') && completeRegistrationForm.controls['first_name'].touched" class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.FirstNameLabelText' | translate}}</span>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <div class="form-outline mb-4">
                        <label class="  ml-1 mb-0" for="inputAddress">{{'AUTHENTICATION.COMPLETEREGISTRATION.MiddleName' | translate}}</label>
                        <input type="text" id="form3Example1cg" placeholder="{{'PATIENT.ADDFAMILYMEMBER.MiddleName' | translate}}"class="form-control form-control-lg mb-1" formControlName="middleName" maxlength="35">                       
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <div class="form-outline mb-4 readonly">
                        <!-- LastNameFieldValidationMessage -->
                        <label for="exampleInputEmail1" class="form-label ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.LastNameLabelText' | translate}}</label>
                        <input type="text" id="form3Example1cg" readonly placeholder="{{'PATIENT.ADDFAMILYMEMBER.LastNamePlaceholderText' | translate}}" class="form-control form-control-lg mb-1" formControlName="last_name">
                        <span *ngIf="completeRegistrationForm.controls['last_name'].hasError('required') && completeRegistrationForm.controls['last_name'].touched" class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.LastNameLabelText' | translate}}</span>

                      </div>
                    </div>
                    <div class="form-group col-md-2">
                      <div class="form-outline mb-4">
                        <label class="  ml-1 mb-0" for="inputAddress">{{'AUTHENTICATION.COMPLETEREGISTRATION.Suffix' | translate}}</label>
                        <input type="text" id="form3Example1cg" placeholder="{{'PATIENT.ADDFAMILYMEMBER.Suffix' | translate}}"class="form-control form-control-lg mb-1" formControlName="suffix" maxlength="10">                       
                      </div>
                    </div>
                  </div>
                  <div class="form-group email-address">
                    <!-- EmailAddressLabelText -->
                    <label class="  ml-1 mb-0" for="inputAddress">{{'AUTHENTICATION.COMPLETEREGISTRATION.EmailAddressLabelText' | translate}}</label>
                    <div class="form-outline">
                      <input type="text" id="form3Example1cg" [class.readonly]="userEmail !== ''"  [readonly]="userEmail !== ''" placeholder="{{'AUTHENTICATION.LOGIN.EmailPlaceHolderText' | translate}}" class="form-control form-control-lg mb-1" formControlName="email">
                      <!-- EmailFieldValidationMessage -->
                      <span *ngIf="completeRegistrationForm.controls['email'].hasError('required') && completeRegistrationForm.controls['email'].touched" class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.EmailFieldValidationMessage' | translate}}</span>
                      <!-- EmailFieldPatternValidationMessage -->
                      <span *ngIf="completeRegistrationForm.controls['email'].hasError('email') && completeRegistrationForm.controls['email'].touched" class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.EmailFieldPatternValidationMessage' | translate}}</span>
                    </div>    
                  
                  
                    <div class="row mt-1"> 
                      <!-- DateofBirthLabelText -->
                    <label class="  ml-1 mb-0 " for="dateofbirth">{{'AUTHENTICATION.COMPLETEREGISTRATION.DateofBirthLabelText' | translate}}</label>
                    <div class="form-group col-md-4  input-field">
  
                      <i class="year-icon fa fa-2x fa-sort-desc" aria-hidden="true"></i>
                      <select id="inputState"  class="form-control select-option form-control-lg" formControlName="year" (change)="dateofBirthSelection('year')" >
                        <option *ngFor="let year of years">{{year}}</option>
                      </select>
                      <span *ngIf="completeRegistrationForm.controls['year'].hasError('required') && completeRegistrationForm.controls['year'].touched" class="text-danger ">{{'AUTHENTICATION.COMPLETEREGISTRATION.DOByearValidator' | translate}}</span>

                    </div>
                      <div class="form-group col-md-4 input-field ">
                        <i class="month-icon fa fa-2x fa-sort-desc" aria-hidden="true"></i>
                        <select id="inputState" placeholder="Month" [ngClass]="(!completeRegistrationForm.controls['year'].value || completeRegistrationForm.controls['year'].value=='Year')?'class-disabled':''"
                         class="form-control select-option form-control-lg" formControlName="month" (change)="dateofBirthSelection('month')">
                          <option *ngFor="let month of months">{{month}}</option>
                        </select>
                        <span *ngIf="completeRegistrationForm.controls['month'].hasError('required') && completeRegistrationForm.controls['month'].touched" class="text-danger">{{'AUTHENTICATION.COMPLETEREGISTRATION.DOBmonthValidator' | translate}}</span>

                      </div>
                      <div class="form-group col-md-4 input-field">
                        <i class="day-icon fa fa-2x fa-sort-desc" aria-hidden="true"></i>
                        <select id="inputState" placeholder="Date" [ngClass]="(!completeRegistrationForm.controls['month'].value || completeRegistrationForm.controls['month'].value=='Month' || completeRegistrationForm.controls['year'].value=='Year')?'class-disabled':''"
                         class="form-control select-option form-control-lg" formControlName="day" (change)="dateofBirthSelection('day')">
                          <option *ngFor="let day of days">{{day}}</option>
                        </select>
                        <span *ngIf="completeRegistrationForm.controls['day'].hasError('required') && completeRegistrationForm.controls['day'].touched" class="text-danger">{{'AUTHENTICATION.COMPLETEREGISTRATION.DOBdayValidator' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group ">
                    <!-- AddressLabelText -->
                    <label class="  ml-1 mb-0 mt-1" for="inputAddress2">{{'AUTHENTICATION.COMPLETEREGISTRATION.AddressLabelText' | translate}}</label>
                    <input type="text" class="form-control form-control-lg mb-1 " id="inputAddress2" placeholder="{{'PATIENT.EDITPROFILE.PatientStreetAddressPlaceholderText' | translate}}" formControlName="street_address">
                    <!-- AddressFieldValidationMessage -->
                    <span *ngIf="completeRegistrationForm.controls['street_address'].hasError('required') && completeRegistrationForm.controls['street_address'].touched"
                     class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.AddressFieldValidationMessage' | translate}}</span>
                     <small *ngIf="completeRegistrationForm.controls['street_address'].hasError('pattern')" class="text-danger">{{"ADMIN.SETTINGS.ConsultationTypes.TextValidation" | translate}}</small>

                  </div>
                  <div class="form-group ">
                    <!-- AddressLabelText -->
                    <input type="text" class="form-control form-control-lg mb-1 " id="inputAddress2" placeholder="{{'PATIENT.EDITPROFILE.PatientStreetAddressPlaceholderText2' | translate}}" formControlName="street_address2">
                    
                  </div>

                  <div class="row mt-1">
                    <div class="form-group col-md-6 ">
                      <input type="text" placeholder="{{'PATIENT.EDITPROFILE.CityPlaceholderText' | translate}}" class="form-control form-control-lg mb-1" id="inputCity" formControlName="city">
                      <!-- CityFieldValidationMessage -->
                      <span *ngIf="completeRegistrationForm.controls['city'].hasError('required') && completeRegistrationForm.controls['city'].touched" class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.CityFieldValidationMessage' | translate}}</span>
                      <!-- CityFieldPatternValidationMessage -->
                      <small *ngIf="completeRegistrationForm.controls['city'].hasError('pattern')" class="text-danger">{{"ADMIN.SETTINGS.ConsultationTypes.TextValidation" | translate}}</small>

                    </div>
                    <div class="form-group col-md-2 pb-1 input-field ">
                      <i class="state fa fa-2x fa-sort-desc " aria-hidden="true"></i>
                      <select id="inputState" placeholder="Address 1" class="form-control select-option form-control-lg" formControlName="state">
                        <option selected>{{'PATIENT.EDITPROFILE.StatePlaceholderText' | translate}} </option>
                        <option *ngFor="let state of states">{{state}}</option>
                      </select>
                      <div *ngIf="completeRegistrationForm.controls['state'].value == '' || completeRegistrationForm.controls['state'].value == 'State' " class="text-danger validation-sm-font ml-1">{{ "ADMIN.SETTINGS.PROVIDERS.StateValidation" | translate }}</div>
                    </div>
                    <div class="form-group col-md-4">
                      <input type="text" placeholder="{{'PATIENT.EDITPROFILE.ZipcodePlaceholderText' | translate}}" class="form-control form-control-lg mb-1" id="inputZip" formControlName="zipcode"
                      onkeypress="return event.charCode <= 57 && event.charCode >=48" maxlength="5" minlength="5">
                      <!-- ZipcodeValidationMessage -->
                      <span *ngIf="completeRegistrationForm.controls['zipcode'].hasError('required') && completeRegistrationForm.controls['zipcode'].touched " class="text-danger ml-1">{{'ADMIN.SETTINGS.PROVIDERS.ZipValidation' | translate}}</span>
                      <div *ngIf="(completeRegistrationForm.controls['zipcode'].hasError('minlength') || completeRegistrationForm.controls['zipcode'].hasError('pattern')) && completeRegistrationForm.controls['zipcode'].touched" class="text-danger validation-sm-font ml-1">{{ "AUTHENTICATION.COMPLETEREGISTRATION.ZipcodeValidationMessage" | translate }}</div>
                      <!-- ZipcodeFormatValidationMessage -->
                    </div>
                  </div>
                  <!-- PasswordLabelText -->
                  
                  <div class="row mt-1"> 
                    <div class="form-group col-md-6">
                      
                      <div class="form-outline">
                        <label class="  ml-1 mb-0 mt-1" for="inputnewpassword">{{'AUTHENTICATION.COMPLETEREGISTRATION.PasswordLabelText' | translate}}</label>
                        <input type="password" id="form3Example1cg" placeholder="{{'PATIENT.EDITPROFILE.PasswordPlaceholderText' | translate}}" class="form-control form-control-lg mb-1" formControlName="password">
                        <!-- PasswordFieldValidationMessage -->
                        <span *ngIf="completeRegistrationForm.controls['password'].hasError('required') && completeRegistrationForm.controls['password'].touched" class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.PasswordFieldValidationMessage' | translate}}</span>
                        <!-- PasswordFormatValidationMessage -->
                        <div *ngIf="completeRegistrationForm.controls['password'].hasError('pattern') && completeRegistrationForm.controls['password'].touched" 
                        class="validation-sm-font text-danger">{{'AUTHENTICATION.COMPLETEREGISTRATION.PasswordFormatValidationMessage' | translate}}</div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <div class="form-outline mb-4">
                        <label for="exampleInputEmail1" class="form-label ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.ReEnterPasswordLabel' | translate}}</label>
                        <input type="password" id="form3Example1cg" placeholder="{{'PATIENT.EDITPROFILE.ConfirmPasswordPlaceholderText' | translate}}" class="form-control form-control-lg mb-1" formControlName="confirm_password">
                        <!-- ConfirmPasswordFieldValidationMessage -->
                        <span *ngIf="completeRegistrationForm.controls['confirm_password'].hasError('required') && completeRegistrationForm.controls['confirm_password'].touched"
                         class="text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.ConfirmPasswordFieldValidationMessage' | translate}}</span>
                        <!-- ConfirmPasswordFieldNotMatchedValidationMessage -->
                         <div *ngIf="completeRegistrationForm.controls['confirm_password'].errors?.mustMatch && completeRegistrationForm.controls['confirm_password'].dirty"
                         class="validation-sm-font text-danger ml-1">{{'AUTHENTICATION.COMPLETEREGISTRATION.ConfirmPasswordFieldNotMatchedValidationMessage' | translate}}</div>
                      </div>
                    </div>
                  </div>
  
                  <div class="d-flex justify-content-center">
                    <!-- NextButtonText -->
                    <button type="button" class="btn btn-success btn-lg mt-lg-n3 pre-auth-btn2" (click)="onCompleteRegistartionLogin()" >{{'AUTHENTICATION.COMPLETEREGISTRATION.NextButtonText' | translate}}</button>
                  </div>
  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
 
  </section>