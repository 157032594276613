import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DataService } from 'src/app/services/data.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SessionService } from 'src/app/services/session.service';
declare var $: any;

@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.css']
})
export class cPatientRegistrationComponent implements OnInit {
  @ViewChild('medicationsSearch') medicationsSearch: ElementRef;
  @ViewChild('previousMedicationsSearch') previousMedicationsSearch: ElementRef;
  @ViewChild('previousAllergySearch') previousAllergySearch: ElementRef;
  @ViewChild('allergySearch') allergySearch: ElementRef;
  patientRegistrationForm: FormGroup;
  healthProblems: any;
  years: any = [];
  days: any = [];
  months: any = [];
  healthProblemsToPush: any = [];
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  newImage: {
    base64: string;
    image: HTMLImageElement; size: import("ngx-image-cropper").Dimensions;
  };
  imgChangeEvt: any;
  cropImgPreview: any;
  incomingFile: any;
  fileName: any;
  fileType: any;
  patientImage: string = '';
  patientProfileDetails: any;
  medicationToPush: any = [];
  userId: any;
  responseAlert: boolean = false;
  alertColor: string;
  resMessage: string;
  showDoctorVisitedDate: boolean = false;
  selectedMedications: any = [];
  allergies: any = [];
  selectedAllergies: any = [];
  previousMedicationToPush: any = [];
  previousAllergies: any = [];
  dateValidation: boolean = true;
  loader: boolean = false;
  currentPage = 1; totalPages: number;
  constructor(private router: Router, private formBuilder: FormBuilder, private dataService: DataService, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.initalizePatientRegistrationForm();
    this.getYearsMonthsDays();
    this.searchCurrentMedications();
    this.searchPreviousMedications();
    this.searchAllergies();
    this.searchPreviousAllergies();
    this.healthProblem();
    this.healthProblemsToPush = [];
    this.patientRegistrationForm.patchValue({
      healthProblems: this.healthProblemsToPush,
    });
    this.patientProfileDetails = this.sessionService.cSessionService_GetSignupDetails();
    this.dataService.languageSelected.subscribe(res => {
      this.healthProblem();
      this.healthProblemsToPush = [];
      this.patientRegistrationForm.patchValue({
        healthProblems: this.healthProblemsToPush,
      });
    })
    this.userId = localStorage.getItem('userId')
    this.dataService.displayHippaElement.next(false);
  }

  reset() {
    this.myInputVariable.nativeElement.value = "";
  }

  // ****************************************** Form ***************************************

  initalizePatientRegistrationForm() {
    const feetRegex = /^[1-9]$/;
    const inchRegex = /^(1[01]|[0-9])$/;
    this.patientRegistrationForm = this.formBuilder.group(
      {
        heightFeet: [null, [Validators.required, Validators.pattern(feetRegex)]],
        heightInch: [null, [Validators.required, Validators.pattern(inchRegex)]],
        weight: [null, [Validators.required, Validators.max(999), Validators.min(1)]],
        alcohol: ['No'],
        tobacco: ['No'],
        gender: ['Male'],
        hasDoctor: [false],
        current_medication: [],
        previous_medication: [],
        current_allergies: [''],
        previous_allergies: [''],
        doctorVisitMonth: [''],
        doctorVisitDate: [''],
        doctorVisitYear: [''],
        healthProblems: [[]]
      });
  }

  // ****************************************** NavigateToLogin ***************************************

  navigateToLogin() {
    this.dateValidation = this.patientRegistrationForm.controls['hasDoctor'].value ? this.selectDateForVisitingDoctor() : true;
    if (this.dateValidation == true) {
      let selectedMedicationsToSend: any = this.selectedMedications.map(val => { return { DispensableDrugId: val.DispensableDrugId, Status: val.Status } })
      this.selectedAllergies.forEach(element => { delete element.checked; delete element.RxCUI, delete element.allergyName; delete element.allergyId; })
      let selectedAllergiesToSend: any = [... this.selectedAllergies]
      let referralId = localStorage.getItem('referralId');
      let payload = {
        id: this.userId,
        profileImage: this.patientImage,
        firstName: this.patientProfileDetails.first_name,
        lastName: this.patientProfileDetails.last_name,
        birthMonth: this.patientProfileDetails.month,
        birthDate: this.patientProfileDetails.day,
        birthYear: this.patientProfileDetails.year,
        address1: this.patientProfileDetails.street_address,
        address2: this.patientProfileDetails.street_address2,
        city: this.patientProfileDetails.city,
        state: this.patientProfileDetails.state,
        zipCode: this.patientProfileDetails.zipcode,
        prefix: this.patientProfileDetails.prefix,
        suffix: this.patientProfileDetails.suffix,
        middleName: this.patientProfileDetails.middleName,
        heightFeet: this.patientRegistrationForm.controls['heightFeet'].value,
        heightInch: this.patientRegistrationForm.controls['heightInch'].value,
        weight: this.patientRegistrationForm.controls['weight'].value,
        gender: this.patientRegistrationForm.controls['gender'].value,
        alcohol: this.patientRegistrationForm.controls['alcohol'].value,
        tobacco: this.patientRegistrationForm.controls['tobacco'].value,
        allergies: selectedAllergiesToSend ?? [],
        hasDoctor: this.patientRegistrationForm.controls['hasDoctor'].value,
        medication: selectedMedicationsToSend ?? [],
        doctorVisitMonth: this.patientRegistrationForm.controls['doctorVisitMonth'].value,
        doctorVisitDate: this.patientRegistrationForm.controls['doctorVisitDate'].value,
        doctorVisitYear: this.patientRegistrationForm.controls['doctorVisitYear'].value,
        healthProblems: this.patientRegistrationForm.controls['healthProblems'].value,
        clientName: localStorage.getItem("lsTenant"),
        language: localStorage.getItem('lsLanguage'),
        phone: this.patientProfileDetails.phone,
        email: this.patientProfileDetails.email,
        referralId: referralId ? referralId : ''
      }
      try {
        this.loader = true;
        this.dataService.DataService_RegisterNewPatient(JSON.stringify(payload)).subscribe(result => {
          this.loader = false;
          if (result.status == 'failed') {
            if (result.message != undefined) {
              this.displayAlertMessage('alert-danger', result.message);
            }
            else {
              this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
            }
          }
          else {
            let msg = "Patient created successfully."
            this.displayAlertMessage('alert-success', msg)
            localStorage.removeItem('lsSignupUserDetails');
            localStorage.removeItem('RegisterAccount');
            localStorage.removeItem('lsPhone');
            if (referralId) localStorage.removeItem('referralId');
            this.router.navigateByUrl('/patient');
          }
        },
          (error) => {
            this.loader = false;
            this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
          }
        );
      }
      catch (error) {
        this.loader = false;
        console.log("Register Member : Catch Error ==>  ", error);
      }
    }
    else {
      if (!this.dateValidation) this.displayAlertMessage('alert-danger', "Your last visit date to the doctor must be in the past.")
      this.patientRegistrationForm.markAllAsTouched()
    }
  }
  // ****************************************** Upload Image ***************************************

  uploadImage() {
    if (this.incomingFile) {
      try {
        let formData = new FormData();
        formData.append('file', this.incomingFile)
        formData.append('folder', 'public/images')
        formData.append('makePublic', 'true')
        this.dataService.DataService_UpdatePatientImage(formData).subscribe(result => {
          if (result.status === 'failed') {
            this.displayAlertMessage("alert-success", result.message);
            this.patientImage = "";
          }
          else {
            this.patientImage = "https://storage.googleapis.com/" + result.bucketName + '/' + result.folderName + '/' + result.fileName;
            this.displayAlertMessage("alert-success", "Profile image updated successfully.");
          }
        },
          (error) => {
            this.displayAlertMessage("alert-danger", error.error.message);
          });
      }
      catch (error) {
        this.displayAlertMessage("alert-danger", error.error.message);
      }
    }
  }

  // ****************************************** Search Current Medications ***************************************

  // searchCurrentMedications() {
  //   this.patientRegistrationForm.get('current_medication').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
  //     if (data !== '' && data?.length >= 3) {
  //       let body = { name: data }
  //       try {
  //         this.dataService.DataService_SearchMedication(body).subscribe((data) => {
  //           if (data.status == 'success') {
  //             this.medicationToPush = [];
  //             let medications: any = data.data.Items;
  //             if (medications.length == 0) this.displayAlertMessage('alert-warning', 'No medications found!');
  //             medications?.forEach(element => { this.medicationToPush.push({ DisplayName: element.Name, checked: false, Status: '1' }) });
  //           }
  //           if (data.status == 'failed') {
  //             this.displayAlertMessage('alert-danger', data.message)
  //           }
  //         },
  //           (error) => {
  //             this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
  //           }
  //         );
  //       }
  //       catch (error) {
  //         console.log("searchCurrentMedications : Catch Error ==>  ", error);
  //       }
  //     }
  //     else {
  //       this.medicationToPush = [];
  //     }
  //   })
  // }

  searchCurrentMedications() {
    this.patientRegistrationForm.get('current_medication').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      if (data !== '' && data?.length >= 3) {
        this.currentPage = 1;  // Reset the current page for new search
        this.fetchMedications(data);
      } else {
        this.currentPage = 1;
        this.medicationToPush = [];
      }
    });
  }

  fetchMedications(query: string) {
    let body = {
      name: query,
      pageNumber: this.currentPage
    };

    try {
      this.dataService.DataService_SearchMedication(body).subscribe(
        (data) => {
          if (data.status == 'success') {
            console.log("fetchMedications: Response ===>>>", data);
            if (this.currentPage === 1) {
              this.medicationToPush = []; // Reset medication list for new search
            }
            let medications = data.data.medications;
            if (medications.length == 0 && this.currentPage === 1) {
              this.displayAlertMessage('alert-warning', 'No medications found!');
            }
            medications?.forEach(element => {
              this.medicationToPush.push({ 
                DisplayName: element.medicationName, 
                checked: false,
                 Status: '1',
                 DispensableDrugId: element.medicationId 
                })
            });
            this.totalPages = data.data.PageResult.TotalPages;
          } else if (data.status == 'failed') {
            this.displayAlertMessage('alert-danger', data.message)
          }
        },
        (error) => {
          console.error("fetchMedications: ERROR ===>>>>", error);
          this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
        }
      );
    } catch (error) {
      console.log("fetchMedications : Catch Error ==>  ", error);
    }
  }

  onScrollDownSearchCurrentMedications(medicationsSearch: string) {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchMedications(medicationsSearch);
    }
  }

  // ****************************************** Search Previous Medications ***************************************

  // searchPreviousMedications() {
  //   this.patientRegistrationForm.get('previous_medication').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
  //     if (data !== '' && data?.length >= 3) {
  //       let body = { name: data }
  //       try {
  //         this.dataService.DataService_SearchMedication(body).subscribe((data) => {
  //           if (data.status == 'success') {
  //             this.previousMedicationToPush = [];
  //             let medications = data.data.Items;
  //             if (medications.length == 0) this.displayAlertMessage('alert-warning', 'No medications found!');
  //             medications?.forEach(element => { this.previousMedicationToPush.push({ DisplayName: element.Name, checked: false, Status: '2' }) });
  //           }
  //           if (data.status == 'failed') {
  //             this.displayAlertMessage('alert-danger', data.message)
  //           }
  //         },
  //           (error) => {
  //             this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
  //           }
  //         );
  //       }
  //       catch (error) {
  //         console.log("searchPreviousMedications : Catch Error ==>  ", error);
  //       }
  //     }
  //     else {
  //       this.previousMedicationToPush = [];
  //     }
  //   })
  // }

  searchPreviousMedications() {
    this.patientRegistrationForm.get('previous_medication').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      if (data !== '' && data?.length >= 3) {
        this.currentPage = 1;  // Reset the current page for new search
        this.fetchMedicationsPreviousMedications(data);
      } else {
        this.currentPage = 1;
        this.previousMedicationToPush = [];
      }
    });
  }

  fetchMedicationsPreviousMedications(query: string) {
    let body = {
      name: query,
      pageNumber: this.currentPage
    };

    try {
      this.dataService.DataService_SearchMedication(body).subscribe(
        (data) => {
          if (data.status == 'success') {
            console.log("fetchMedications: Response ===>>>", data);
            if (this.currentPage === 1) {
              this.previousMedicationToPush = []; // Reset medication list for new search
            }
            let medications = data.data.medications;
            if (medications.length == 0 && this.currentPage === 1) {
              this.displayAlertMessage('alert-warning', 'No medications found!');
            }
            medications?.forEach(element => {
              this.previousMedicationToPush.push({
                 DisplayName: element.medicationName,
                  checked: false, 
                  Status: '2', 
                  removeable: '1',
                  DispensableDrugId: element.medicationId 
                })
            });
            this.totalPages = data.data.PageResult.TotalPages;
          } else if (data.status == 'failed') {
            this.displayAlertMessage('alert-danger', data.message)
          }
        },
        (error) => {
          console.error("fetchMedications: ERROR ===>>>>", error);
          this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
        }
      );
    } catch (error) {
      console.log("fetchMedications : Catch Error ==>  ", error);
    }
  }

  onScrollDownSearchPreviousMedications(medicationsSearch: string) {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchMedicationsPreviousMedications(medicationsSearch);
    }
  }

  // ******************************************  Search Allergies ***************************************

  // searchAllergies() {
  //   this.patientRegistrationForm.get('current_allergies').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
  //     if (data !== '' && data?.length >= 3) {
  //       let body = {
  //         name: data
  //       }
  //       try {
  //         this.dataService.DataService_SearchAllergies(body).subscribe((data) => {
  //           if (data.status == 'success') {
  //             this.allergies = [];
  //             let allergiesList = data.data.Items;
  //             if (allergiesList.length == 0) this.displayAlertMessage('alert-warning', 'No allergies found!');
  //             allergiesList?.forEach(element => (element['checked'] = false, element['StatusType'] = '1'));
  //             this.allergies = allergiesList;
  //           }
  //           if (data.status == 'failed') {
  //             this.displayAlertMessage('alert-danger', data.message)
  //           }
  //         },
  //           (error) => {
  //             this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
  //           }
  //         );
  //       }
  //       catch (error) {
  //         console.log("search allergies : Catch Error ==>  ", error);
  //       }
  //     }
  //     else {
  //       this.allergies = [];
  //     }
  //   })
  // }

  searchAllergies() {
    this.patientRegistrationForm.get('current_allergies').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      if (data !== '' && data?.length >= 3) {
        this.currentPage = 1;  // Reset the current page for new search
        this.fetchAllergies(data);
      } else {
        this.allergies = [];
      }
    });
  }

  fetchAllergies(query: string) {
    let body = {
      name: query,
      pageNumber: this.currentPage
    };

    try {
      this.dataService.DataService_SearchAllergies(body).subscribe(
        (data) => {
          if (data.status == 'success') {
            if (this.currentPage === 1) {
              this.allergies = []; // Reset medication list for new search
            }
            let allergiesList = data.data.allergies;
            if (allergiesList.length == 0) this.displayAlertMessage('alert-warning', 'No allergies found!');
            // allergiesList?.forEach(element => (element['checked'] = false, element['StatusType'] = '1'));
            allergiesList?.forEach(element => {
              element['checked'] = false;
              element['allergyName'] = element.allergyName;
              element['AllergenId'] = element.allergyId;
              element['IsActive'] = element.IsActive;
              element['StatusType'] = '1';
              this.allergies.push(element); // Push modified element into allergies list
            });
            this.totalPages = data.data.PageResult.TotalPages;
          }
          if (data.status == 'failed') {
            this.displayAlertMessage('alert-danger', data.message)
          }
        },
        (error) => {
          this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
        }
      );
    } catch (error) {
      console.log("search allergies : Catch Error ==>  ", error);
    }
  }

  onScrollDownSearchAllergies(allergiesSearch: string) {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchAllergies(allergiesSearch);
    }
  }

  // ******************************************  Search Previous Allergies ***************************************

  // searchPreviousAllergies() {
  //   this.patientRegistrationForm.get('previous_allergies').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
  //     if (data !== '' && data?.length >= 3) {
  //       let body = {
  //         name: data
  //       }
  //       try {
  //         this.dataService.DataService_SearchAllergies(body).subscribe((data) => {
  //           if (data.status == 'success') {
  //             this.previousAllergies = [];
  //             let allergiesList = data.data.Items;
  //             if (allergiesList.length == 0) this.displayAlertMessage('alert-warning', 'No allergies found!');
  //             allergiesList?.forEach(element => (element['checked'] = false, element['StatusType'] = '2'));
  //             this.previousAllergies = allergiesList;
  //           }
  //           if (data.status == 'failed') {
  //             this.displayAlertMessage('alert-danger', data.message)
  //           }
  //         },
  //           (error) => {
  //             this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
  //           }
  //         );
  //       }
  //       catch (error) {
  //         console.log("search Previous Allergies : Catch Error ==>  ", error);
  //       }
  //     }
  //     else {
  //       this.previousAllergies = [];
  //     }
  //   })
  // }


  searchPreviousAllergies() {
    this.patientRegistrationForm.get('previous_allergies').valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      if (data !== '' && data?.length >= 3) {
        this.currentPage = 1;  // Reset the current page for new search
        this.fetchAllergiesPreviousAllergies(data);
      } else {
        this.allergies = [];
      }
    });
  }

  fetchAllergiesPreviousAllergies(query: string) {
    let body = {
      name: query,
      pageNumber: this.currentPage
    };

    try {
      this.dataService.DataService_SearchAllergies(body).subscribe(
        (data) => {
          if (data.status == 'success') {
            if (this.currentPage === 1) {
              this.previousAllergies = []; // Reset medication list for new search
            }
            let allergiesList = data.data.allergies;
            if (allergiesList.length == 0) this.displayAlertMessage('alert-warning', 'No allergies found!');
            // allergiesList?.forEach(element => (element['checked'] = false, element['StatusType'] = '2'));
            allergiesList?.forEach(element => {
              element['checked'] = false;
              element['allergyName'] = element.allergyName;
              element['AllergenId'] = element.allergyId;
              element['IsActive'] = element.IsActive;
              element['StatusType'] = '2';
              // element['checked'] = false;
              this.previousAllergies.push(element); // Push modified element into allergies list
            });
            this.totalPages = data.data.PageResult.TotalPages;
          }
          if (data.status == 'failed') {
            this.displayAlertMessage('alert-danger', data.message)
          }
        },
        (error) => {
          this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.");
        }
      );
    } catch (error) {
      console.log("search allergies : Catch Error ==>  ", error);
    }
  }

  onScrollDownPreviousAllergies(allergiesSearch: string) {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchAllergiesPreviousAllergies(allergiesSearch);
    }
  }

  // ****************************************** Functions ***************************************

  healthProblem() {
    let language = localStorage.getItem('lsLanguage');
    if (language == 'English') {
      this.healthProblems = [
        {
          "healthProblemId": "1",
          "healthProblemName": "Heart Disease"
        },
        {
          "healthProblemId": "2",
          "healthProblemName": "Cancer"
        },
        {
          "healthProblemId": "3",
          "healthProblemName": "Lung Disease"
        },
        {
          "healthProblemId": "4",
          "healthProblemName": "Stroke"
        },
        {
          "healthProblemId": "5",
          "healthProblemName": "Diabetes"
        },
        {
          "healthProblemId": "6",
          "healthProblemName": "Kidney Disease"
        }
      ];
    }
    else {
      this.healthProblems = [
        {
          "healthProblemId": "1",
          "healthProblemName": "Enfermedad del corazón"
        },
        {
          "healthProblemId": "2",
          "healthProblemName": "Cancer"
        },
        {
          "healthProblemId": "3",
          "healthProblemName": "Enfermedad pulmonar"
        },
        {
          "healthProblemId": "4",
          "healthProblemName": "Carrera"
        },
        {
          "healthProblemId": "5",
          "healthProblemName": "Diabetes"
        },
        {
          "healthProblemId": "6",
          "healthProblemName": "Enfermedad del riñon"
        }
      ];;
    }
  }

  dismissAlert() {
    this.responseAlert = false;
  }

  onSelectAll(items: any) {
    this.medicationToPush = [];
    for (let index = 0; index < items.length; index++) {
      this.medicationToPush.push(items[index].item_id);
    }
    console.log("Incoming Mediacation ====>>>>", this.medicationToPush);
    this.patientRegistrationForm.patchValue({
      medication: this.medicationToPush,
    })
  }

  checkTobbacoConsumption(incomingString) {
    if (incomingString == 'yes') {
      let language = localStorage.getItem('lsLanguage');
      let patchingString = "Yes";
      if (language == 'Spanish') patchingString = 'Sí'
      this.patientRegistrationForm.patchValue({
        tobacco: patchingString,
      });
    }
    else {
      this.patientRegistrationForm.patchValue({
        tobacco: 'No',
      });
    }
  }

  checkAlcholConsumption(incomingString) {
    if (incomingString == 'yes') {
      let language = localStorage.getItem('lsLanguage');
      let patchingString = "Yes";
      if (language == 'Spanish') patchingString = 'Sí'
      this.patientRegistrationForm.patchValue({
        alcohol: patchingString,
      });
    }
    else {
      this.patientRegistrationForm.patchValue({
        alcohol: 'No',
      });
    }
  }

  doctorSectionToggle(incomingString) {
    const hasDoctor = incomingString === 'yes';
    this.patientRegistrationForm.patchValue({
      hasDoctor: hasDoctor,
    });
    this.showDoctorVisitedDate = hasDoctor;
    const validators = hasDoctor ? [Validators.required] : [];
    ['doctorVisitMonth', 'doctorVisitDate', 'doctorVisitYear'].forEach(field => {
      const control = this.patientRegistrationForm.get(field);
      control.clearValidators();
      control.setValidators(validators);
      control.updateValueAndValidity();
    });
    this.patientRegistrationForm.markAsDirty();
  }

  checkGender(event) {
    this.patientRegistrationForm.patchValue({
      gender: event.target.value
    })
  }

  checkHealthProblems(event) {
    if (event.target.checked) {
      this.healthProblemsToPush.push(event.target.value)
    }
    else {
      var index = this.healthProblemsToPush.indexOf(event.target.value)
      this.healthProblemsToPush.splice(index, 1)
    }
    this.patientRegistrationForm.patchValue({
      healthProblems: this.healthProblemsToPush,
    });
  }

  uploadProfilePicture(event) {
    $('#exampleModal').modal('show')
    this.imgChangeEvt = event;
    this.incomingFile = event.target.files[0];
    this.fileName = Date.now() + event.target.files[0].name;
    this.fileType = event.target.files[0].type;
  }

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    let url = this.cropImgPreview;
    var file = this.dataURLtoFile(url, this.fileName);
    this.incomingFile = file;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: this.fileType });
  }


  selectedMedication(incommingMedication, event: any, incommingCheck) { 
    if (event.target.checked) {
      if (this.selectedMedications.length > 0) {
        let duplicateIndex = this.selectedMedications.findIndex(({ DisplayName }) => DisplayName === incommingMedication.DisplayName);
        if (duplicateIndex == -1) this.selectedMedications.push(incommingMedication);
      }
      else {
        this.selectedMedications.push(incommingMedication);
      }
      if (incommingCheck == 'current') {
        let index = this.medicationToPush.findIndex(({ DisplayName }) => DisplayName === incommingMedication.DisplayName)
        if (index !== -1) this.medicationToPush[index].checked = true;
      }
      else {
        let previousIndex = this.previousMedicationToPush.findIndex(({ DisplayName }) => DisplayName === incommingMedication.DisplayName)
        if (previousIndex !== -1) this.previousMedicationToPush[previousIndex].checked = true;
      }
    }
    else {
      let index = this.selectedMedications.findIndex(({ DisplayName }) => DisplayName === incommingMedication.DisplayName);
      if (index !== -1) this.selectedMedications.splice(index, 1);
    }
  }

  removeSelectedMedication(incommingMedication) {
    let medicationIndex = this.medicationToPush.findIndex(({ DisplayName }) => DisplayName === incommingMedication.DisplayName)
    if (medicationIndex !== -1) this.medicationToPush[medicationIndex].checked = false;
    let previousMedicationIndex = this.previousMedicationToPush.findIndex(({ DisplayName }) => DisplayName === incommingMedication.DisplayName)
    if (previousMedicationIndex !== -1) this.previousMedicationToPush[previousMedicationIndex].checked = false;
    let index = this.selectedMedications.findIndex(({ DisplayName }) => DisplayName === incommingMedication.DisplayName);
    if (index !== -1) this.selectedMedications.splice(index, 1);
  }



  selectedAllergie(incommingAllergy, event: any) {
    if (event.target.checked) {
      if (this.selectedAllergies.length > 0) {
        let duplicateIndex = this.selectedAllergies.findIndex(({ AllergenId }) => AllergenId == incommingAllergy.AllergenId)
        if (duplicateIndex == -1) this.selectedAllergies.push(incommingAllergy);
      }
      else {
        this.selectedAllergies.push(incommingAllergy);
      }
      let index = this.allergies.findIndex(({ AllergenId }) => AllergenId === incommingAllergy.AllergenId)
      if (index !== -1) this.allergies[index].checked = true;
      let previousIndex = this.previousAllergies.findIndex(({ AllergenId }) => AllergenId === incommingAllergy.AllergenId)
      if (previousIndex !== -1) this.previousAllergies[previousIndex].checked = true;
    }
    else {
      let index = this.selectedAllergies.findIndex(({ AllergenId }) => AllergenId === incommingAllergy.AllergenId);
      if (index !== -1) this.selectedAllergies.splice(index, 1);
    }
  }

  removeSelectedAllergy(incommingAllergy) {
    let allergyIndex = this.allergies.findIndex(({ AllergenId }) => AllergenId === incommingAllergy.AllergenId)
    if (allergyIndex !== -1) this.allergies[allergyIndex].checked = false;
    let previousAllergyIndex = this.previousAllergies.findIndex(({ AllergenId }) => AllergenId === incommingAllergy.AllergenId)
    if (previousAllergyIndex !== -1) this.previousAllergies[previousAllergyIndex].checked = false;
    let index = this.selectedAllergies.findIndex(({ AllergenId }) => AllergenId === incommingAllergy.AllergenId);
    if (index !== -1) this.selectedAllergies.splice(index, 1);
  }

  resetFields(incommingCheck) {
    switch (incommingCheck) {
      case 'medication':
        this.medicationToPush = [];
        this.medicationsSearch.nativeElement.value = '';
        break;
      case 'previousMedication':
        this.previousMedicationToPush = [];
        this.previousMedicationsSearch.nativeElement.value = '';
        break;
      case 'allergy':
        this.allergies = [];
        this.allergySearch.nativeElement.value = '';
        break;
      case 'previous_allergy':
        this.previousAllergies = [];
        this.previousAllergySearch.nativeElement.value = '';
        break;
    }
  }

  focusInputField(incommingCheck) {
    switch (incommingCheck) {
      case 'currentMedication':
        this.medicationsSearch.nativeElement.focus();
        break;
      case 'previousMedication':
        this.previousMedicationsSearch.nativeElement.focus();
        break;
      case 'allergy':
        this.allergySearch.nativeElement.focus();
        break;
      case 'previousAllergy':
        this.previousAllergySearch.nativeElement.focus();
        break;
    }
  }

  selectDateForVisitingDoctor() {
    let date = new Date();
    let incomingDate = this.patientRegistrationForm.controls['doctorVisitMonth'].value + '/' + this.patientRegistrationForm.controls['doctorVisitDate'].value + '/' + this.patientRegistrationForm.controls['doctorVisitYear'].value;
    return date > new Date(incomingDate) ? true : false
  }


  getYearsMonthsDays() {
    this.months = [];
    this.years = [];
    let currentYear: number = new Date().getFullYear();
    for (let monthIndex = 1; monthIndex <= 12; monthIndex++) { this.months.push(monthIndex); }
    this.months.unshift('Month')
    for (let i = (currentYear - 30); i < (currentYear + 1); i++) { this.years.unshift(i); }
    this.years.unshift('Year');
    this.getDays(31);
  }

  getDays(incommingDays) {
    this.days = [];
    for (let daysIndex = 1; daysIndex <= incommingDays; daysIndex++) { this.days.push(daysIndex) }
    this.days.unshift('Date')
  }

  dateofbirthSelection(incommingCheck) {
    let selectedYear;
    let selectedMonth;
    selectedYear = this.patientRegistrationForm.controls['doctorVisitYear'].value;
    selectedMonth = this.patientRegistrationForm.controls['doctorVisitMonth'].value;
    if (incommingCheck == 'month') {
      this.getDays(31);
      if (selectedYear !== 'Year' && selectedMonth !== 'Month') {
        this.validateYearAndMonth(selectedYear, selectedMonth);
      }
      if (this.patientRegistrationForm.controls['doctorVisitMonth'].value == 'Month') this.patientRegistrationForm.controls['doctorVisitDate'].reset();
    }
    if (incommingCheck == 'year') {
      if (this.patientRegistrationForm.controls['doctorVisitYear'].value == 'Year') {
        this.patientRegistrationForm.controls['doctorVisitMonth'].reset();
        this.patientRegistrationForm.controls['doctorVisitDate'].reset();
      }
      if (this.patientRegistrationForm.controls['doctorVisitYear'].value && this.patientRegistrationForm.controls['doctorVisitMonth'].value) this.patientRegistrationForm.controls['doctorVisitDate'].reset();
      if (this.patientRegistrationForm.controls['doctorVisitYear'].value !== 'Year' || this.patientRegistrationForm.controls['doctorVisitYear'].value !== '' || this.patientRegistrationForm.controls['doctorVisitYear'].value !== null) {
        this.validateYearAndMonth(selectedYear, selectedMonth);
      }
    }
    if (incommingCheck == 'day') {
      if (this.patientRegistrationForm.controls['doctorVisitDate'].value == 'Date') { this.patientRegistrationForm.controls['doctorVisitDate'].reset(); }
    }
  }

  validateYearAndMonth(selectedYear, selectedMonth) {
    if (selectedYear % 4 == 0 && selectedMonth == 2) { this.getDays(29); this.patientRegistrationForm.controls['doctorVisitDate'].value == 'Date'; }
    if (selectedYear % 4 !== 0 && selectedMonth == 2) { this.getDays(28); this.patientRegistrationForm.controls['doctorVisitDate'].value == 'Date'; }
    if (selectedMonth == 9 || selectedMonth == 4 || selectedMonth == 6 || selectedMonth == 11) { this.getDays(30); this.patientRegistrationForm.controls['doctorVisitDate'].value == 'Date'; }
  }
  // ****************************************** Alert Messages ***************************************

  displayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }
}
