export const environment = 
{
  production: window["env"]["production"] || false,
  APP_NAME: window["env"]["APP_NAME"] || 'Corstet',
  Title: window["env"]["Title"] || 'Corstet',
  BaseURL: window["env"]["BaseURL"] || 'https://app.corstet-dev.trilloapps.com',
  AccessToken : window["env"]["AccessToken"] || 'lsCloudCorstetAccessToken',
  Theme : window["env"]["Theme"] || 'style.css',
  PollingInterval : window["env"]["PollingInterval"] || 20000,
  firebase: {
    apiKey: window["env"]["apiKey"] || 'AIzaSyAQlxxE8YTPtG9GV_sRdZ0c-g8T8m4qHqY',
    authDomain: window["env"]["authDomain"] || 'gothic-well-354316.firebaseapp.com',
    projectId: window["env"]["projectId"] || 'gothic-well-354316',
    storageBucket: window["env"]["storageBucket"] || 'gothic-well-354316.appspot.com',
    messagingSenderId: window["env"]["messagingSenderId"] || '1085108922998',
    appId: window["env"]["appId"] || '1:1085108922998:web:6d27054c1618e4319acd57',
    vapidKey: window["env"]["vapidKey"] || 'BJNbpAHOZwRQaHcaXjAtHTRahlGaqxenZRXtUsBq0EvKifj7TtqhMqWlOGx2K1ZoZ4WJIJc1i1_epvc3IhV4PAQ',
  }
};