import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-app-name': 'auth',
      'x-org-name': 'cloud',
    })
  }

  constructor(private http: HttpClient, private oRouter : Router, private oLocation : Location) {}
  AuthService_Login(credentials : any): Observable<any> {
    return this.http.post(environment.BaseURL+'/ajaxLogin',
        `${credentials}`);
  }
  
   // access token login
   AuthService_AccessTokenLogin(body): Observable<any> {
    return this.http.post(environment.BaseURL+'/authorize', body , this.httpOptions);
  }

  //Return Tenant From URL
  AuthService_ReturnClientName()
  {
    
    // const sHOST : string = this.oLocation['_platformLocation'].location.host;
    // console.warn("HOST : ==> ", sHOST);
    // const lSplittedList: any  = sHOST.split('.');
    // if(lSplittedList[lSplittedList.length-2]?.includes('trilloapps'))
    // {sTenantName = lSplittedList.length>3 && lSplittedList[0] !== "192" ? lSplittedList[0] : 'cloud';}
    // else
    // {sTenantName = lSplittedList.length>2 && lSplittedList[0] !== "192" ? lSplittedList[0] : 'cloud';}
    let sTenantName : string = "";
    sTenantName = localStorage.getItem('cloudName')
    sTenantName = sTenantName.toLocaleLowerCase();
    console.warn("Tenant : ==> ", sTenantName);
    return sTenantName;
  }
}
