import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class cLoginComponent implements OnInit {
  loginForm: FormGroup;
  alert: boolean = false;
  msg: any;
  queryString: string;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dataService: DataService,
    private sessionService: SessionService,
  ) {
    let accessToken: string = localStorage.getItem(environment.AccessToken);
    if (accessToken) {
      let sUserRole: string = localStorage.getItem("userRole");
      switch (sUserRole) {
        case "user":
          this.router.navigateByUrl("/patient");
          break;
        case "admin":
          this.router.navigateByUrl("/admin");
          break;
        case "editor":
          this.router.navigateByUrl("/provider");
          break;
      }
    }
  }

  ngOnInit(): void {
    this.LoginComponent_InitializeLoginForm();
    this.dataService.displayHippaElement.next(true);
  }

  // ****************************************** Form ***************************************

  LoginComponent_InitializeLoginForm() {
    const passRejex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{9,50}$/;
    this.loginForm = this.formBuilder.group({
      j_username: [null, [Validators.required, Validators.email],],
      j_password: [null, [Validators.required]],
      tenant_display_name: this.authService.AuthService_ReturnClientName(),
      terms: [false, [Validators.required, Validators.requiredTrue]],
    });
  }


  // ****************************************** Send Login Request ***************************************

  sendLoginRequest() {
    if (this.loginForm.valid) {
      try {
        this.authService.AuthService_Login(JSON.stringify(this.loginForm.value)).subscribe((data) => {
          let accessToken = data.accessToken;
          let userRole = data.user.role;
          let userId = data.user.id;
          localStorage.setItem(environment.AccessToken, accessToken);
          localStorage.setItem("username", this.loginForm.controls["j_username"].value);
          localStorage.setItem("userRole", userRole);
          localStorage.setItem("userId", userId);
          this.sessionService.cSessionService_SetTenantId(data.user.tenantId);
          if (data.accessToken) {
            localStorage.setItem(environment.AccessToken, accessToken);
            switch (userRole) {
              case "user":
                this.router.navigateByUrl("/patient");
                break;
              case "admin":
                this.router.navigateByUrl("/admin");
                break;
              case "editor":
                this.router.navigateByUrl("/provider");
                break;
            }
          }
          else {
            localStorage.removeItem(environment.AccessToken);
            this.router.navigateByUrl("authentication/login");
          }
        },
          (error) => {
            localStorage.removeItem(environment.AccessToken);
            this.alert = true;
            setTimeout(() => {
              this.alert = false;
            }, 3000);
            this.msg = error.error.message;
            console.log("AJAXLogin : Error Message ==>  ", this.msg);
            console.log("AJAXLogin : Login Error ==>  ", error);
          }
        );
      } catch (error) {
        console.log("loginUser : Catch Error ==>  ", error);
      }
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  // ****************************************** Functions ***************************************

  checkTermsAndConditions() {
    this.loginForm.value.terms == false ? this.loginForm.value.terms = true : this.loginForm.value.terms = false
  }

  navigateRequestResetPassword() {
    this.router.navigate(["/authentication/reset-password"]);
  }

  navigatetoSignUp() {
    this.router.navigateByUrl("/authentication/signup");
  }
}
