<!-- <app-header></app-header> -->
<div *ngIf="responseAlert" class="alert data-table alert-messages {{alertColor}}"  role="alert"> {{resMessage}}
  <button type="button" class="btn-close ml-2 mt-1" data-dismiss="alert" aria-label="Close" (click)="dismissAlert()">
     </button>
</div>
<ng-container *ngIf="!verificationSection">
  <section *ngIf="!showRegisterForm signup-screen">
    <div class="">
      <div class="container authentication-labels">
        <div class="row d-flex justify-content-center align-items-center authentication-cards">
          <div class="col-12 col-md-9 col-lg-7 col-xl-6 ">
            <h2 class="text-center mb-4 heading-text">{{'AUTHENTICATION.SIGNUP.MainHeading' | translate}}</h2>
            <div class="register-card card">
              <div class="card-body p-lg-5">
                <form class="Input-form" [formGroup]="registerAccountForm">
                  <div class="form-outline mb-1">
                    <!-- First Name label -->
                    <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.SIGNUP.FirstNamelabel' | translate}}</label>
                    <input
                      type="text"
                      id="form3Example1cg"
                      placeholder="{{'PATIENT.ADDFAMILYMEMBER.FirstNamePlaceholderText' | translate}}"
                      class="form-control form-control-lg text-center"
                      formControlName="first_name"
                      maxlength="40"
                    />
                    <span *ngIf="registerAccountForm.controls['first_name'].hasError('required') && registerAccountForm.controls['first_name'].touched"
                      class="text-danger ml-1">{{'AUTHENTICATION.SIGNUP.FirstNameFieldValidationMessage' | translate}}</span>
                    <small *ngIf="registerAccountForm.controls['first_name'].hasError('pattern')" class="text-danger">{{"ADMIN.SETTINGS.ConsultationTypes.TextValidation" | translate}}</small>

                  </div>
  
                  <div class="form-outline mb-1">
                    <!-- Last Name label -->
                    <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.SIGNUP.lastnamelabel' | translate}}</label>
                    <input
                      type="text"
                      id="form3Example3cg"
                      placeholder="{{'PATIENT.ADDFAMILYMEMBER.LastNamePlaceholderText' | translate}}"
                      class="form-control form-control-lg text-center"
                      formControlName="last_name"
                      maxlength="40"
                    />
                    <span *ngIf="registerAccountForm.controls['last_name'].hasError('required') && registerAccountForm.controls['last_name'].touched"
                      class="text-danger ml-1">{{'AUTHENTICATION.SIGNUP.LastNameFieldValidationMessage' | translate}}</span >
                    <small *ngIf="registerAccountForm.controls['last_name'].hasError('pattern')" class="text-danger">{{"ADMIN.SETTINGS.ConsultationTypes.TextValidation" | translate}}</small>

                  </div>
  
                  <div class="form-outline mb-4">
                    <!-- Phone Number label -->
                    <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.SIGNUP.Phonenumlabel' | translate}}</label>
  
                    <input
                      type="tel"
                      id="form3Example4cg"
                      placeholder="000-000-0000"
                      [inputMask]="{mask: phoneNumberMask}"
                      class="form-control form-control-lg text-center"
                      formControlName="phone"
                    />
                    <span
                      *ngIf="registerAccountForm.controls['phone'].hasError(
                        'required'
                      ) && registerAccountForm.controls['phone'].touched
                      "
                      class="text-danger ml-1">{{'AUTHENTICATION.SIGNUP.PhonenumberFieldValidationMessage' | translate}}</span >
                    <span
                      *ngIf="
                        registerAccountForm.controls['phone'].hasError('pattern') && registerAccountForm.controls['phone'].touched"
                      class="text-danger ml-1" >{{'AUTHENTICATION.SIGNUP.PhonenumberFieldValidationMessageFormat' | translate}}</span >
                  </div>
                 
                  <div class="form-check register-checkbox  mb-3 d-flex justify-content-center align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      formControlName="terms"
                    />
                    <label class="form-check-label terms-condition ml-4 font-400">
                    {{'AUTHENTICATION.SIGNUP.IAgree' | translate}}
                    <span href="javascript:void(0)" class="text-body cursor-point"
                    data-bs-toggle="modal" data-bs-target="#termsandCondition">
                      <u>{{'AUTHENTICATION.SIGNUP.TermsAndConditions' | translate}}</u>
                    </span>
                    </label>
                  </div>
                  <span *ngIf="registerAccountForm.controls['terms'].hasError('required') && registerAccountForm.controls['terms'].touched" class="text-danger d-flex justify-content-center">{{'AUTHENTICATION.SIGNUP.TermsAndConditionFieldValidationMessage' | translate}}</span>
                  <div class="d-flex justify-content-center">
                    <button
                      type="button"
                      class="btn btn-success btn-lg pre-auth-btn2 mt-2"
                      (click)="sendSignupRequest()">
                      {{'AUTHENTICATION.SIGNUP.NextButtonText' | translate}}
                    </button>
                  </div>
  
                  <div class="d-flex mt-3 justify-content-center" id="recaptcha-container-login"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<section *ngIf="verificationSection">
  <div class="container authentication-labels">
    <div class="row d-flex justify-content-center align-items-center ">
        <div class="col-12 col-md-9 col-lg-7 col-xl-6 authentication-cards">
            <h2 class="text-center mb-4 heading-text">{{'AUTHENTICATION.VERIFYOTP.MainHeading' | translate}}</h2>
            <div class="register-card card" >
                <div class="card-body p-lg-5">
                    <form class="Input-form" [formGroup]="verificationCodeForm">
                        <div class="form-outline mb-4">
                            <!-- Enter OTP label -->
                            <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.VERIFYOTP.EnterOtplabel' | translate}}</label>
                            <input type="number" class="form-control form-control-lg text-center"  maxlength="6" placeholder="{{'AUTHENTICATION.VERIFYOTP.SixDigitCodePlaceHolderText' | translate}}" id="form3Example1cg" formControlName="pin">
                            <!-- CodeFieldValidationMessage -->
                            <span *ngIf="verificationCodeForm.controls['pin'].hasError('required') && verificationCodeForm.controls['pin'].touched"
                            class="text-danger" >{{'AUTHENTICATION.VERIFYOTP.CodeFieldValidationMessage' | translate}}</span>
                        </div>
                        <label class=" font-16 font-400 d-flex justify-content-center cursor" for="flexCheckDefault" (click)="navigateToVerifyEmail()">
                            <u>{{'AUTHENTICATION.VERIFYOTP.CodeNotReceivedLink' | translate}}</u>
                        </label>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn btn-success btn-lg pre-auth-btn mt-5 " (click)="verifyToken()">{{'AUTHENTICATION.VERIFYOTP.ContinueButtonText' | translate}}</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>
</section>
<!-- <app-hippa></app-hippa> -->

<!-- Terms and condition modal -->
<div class="modal fade" id="termsandCondition" tabindex="-1" aria-labelledby="termsandConditionLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
    <div class="modal-content border-0 modal-border-color">
      <div class="modal-header position-relative pb-0 pb-2 pt-3 d-flex align-items-center justify-content-end border-bottom-0" >
        <div class="d-flex justify-content-end align-items-center cross-icon download-rx cursor">
          <div data-bs-dismiss="modal">
            <svg  height="20" viewBox="0 0 24 24" class="cursor" width="20"
              xmlns="http://www.w3.org/2000/svg">
              <g id="Layer_2" data-name="Layer 2" fill="white">
                <path
                  d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm4.242 13.829a1 1 0 1 1 -1.414 1.414l-2.828-2.829-2.828 2.829a1 1 0 0 1 -1.414-1.414l2.828-2.829-2.828-2.829a1 1 0 1 1 1.414-1.414l2.828 2.829 2.828-2.829a1 1 0 1 1 1.414 1.414l-2.828 2.829z" />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="modal-body scroller pt-0 pb-0">
        <div class="card bg-c4c4c4 rounded-cards p-1">
          <div class="card-body card-bg-color"> 
              <app-terms-and-condition></app-terms-and-condition>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>