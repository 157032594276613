import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private routes: Router) 
  {
  }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean
  {
    if (localStorage.getItem(environment.AccessToken) != null && state.url.split('/')[1] === this.AuthGuard_ReturnUserRole()) 
    {
      return true;
    } 
    else 
    {
      this.routes.navigate(['/login']);
      return false;
    }
  }
  AuthGuard_ReturnUserRole()
  {
    let sUSerRole : string = localStorage.getItem('userRole');
    switch (sUSerRole) {
      case 'editor':
        sUSerRole = 'provider'
        break;
      case 'user':
        sUSerRole = 'patient'
        break;
      case 'admin':
        sUSerRole = 'admin'
        break;
    }
    return sUSerRole;
  }
}