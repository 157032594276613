<!-- <app-header></app-header> -->
<div *ngIf="responseAlert" class="alert data-table alert-messages {{alertColor}}" role="alert"> {{resMessage |
    titlecase}}
    <button type="button" class="btn-close ml-2 mt-1" data-dismiss="alert" aria-label="Close"
        (click)="dismissAlert()">
    </button>
</div>
<div class="container authentication-labels">
    <div class="row d-flex justify-content-center align-items-center ">
        <div class="col-12 col-md-9 col-lg-7 col-xl-6 authentication-cards">
            <h2 class="text-center mb-4 heading-text">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.MainHeading' | translate}}</h2>
            <div class="register-card card">
                <div class="card-body p-lg-5">
                    <form  [formGroup]="resetPasswordForm">
                        <div class="form-outline mb-3">
                            <!-- New Password label -->
                            <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.NewPasswordlabel' | translate}}</label>
                            <input type="password" class="form-control form-control-lg d-flex align-items-center text-center " placeholder="{{'AUTHENTICATION.CONFIRMRESETPASSWORD.NewPasswordlabelPlaceholder' | translate}}" id="form3Example1cg" 
                             formControlName="password">
                            <!-- PasswordFieldValidationMessage -->
                             <small *ngIf="resetPasswordForm.controls['password'].hasError('required') && resetPasswordForm.controls['password'].touched" class="text-danger ml-2">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.PasswordFieldValidationMessage' | translate}}</small>
                             <!-- PasswordFormatValidationMessage -->
                             <span *ngIf="resetPasswordForm.controls['password'].hasError('pattern') && resetPasswordForm.controls['password'].touched" 
                             class="text-danger">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.PasswordFormatValidationMessage' | translate}}</span>
                        
                          </div>
                        <div class="form-outline mb-3">
                            <!-- Confirm Password label -->
                            <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.confirmLabel' | translate}}</label>

                              <input type="password" class="form-control form-control-lg d-flex align-items-center text-center" placeholder="{{'AUTHENTICATION.CONFIRMRESETPASSWORD.confirmLabelPlaceholder' | translate}}" id="form3Example1cg" 
                               formControlName="rptPassword">
                               <!-- ConfirmPasswordFieldValidationMessage -->
                               <span *ngIf="resetPasswordForm.controls['rptPassword'].hasError('required') && resetPasswordForm.controls['rptPassword'].touched"
                               class="text-danger ml-2">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.ConfirmPasswordFieldValidationMessage' | translate}}</span>
                              <!-- //ConfirmPasswordFieldNotMatchedValidationMessage -->
                               <span *ngIf="resetPasswordForm.controls['rptPassword'].errors?.mustMatch && resetPasswordForm.controls['rptPassword'].dirty"
                               class="text-danger ml-2">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.ConfirmPasswordFieldNotMatchedValidationMessage' | translate}}</span>
                        
                            </div>

                    </form>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-success btn-lg pre-auth-btn  py-1" (click)="resetPassword()">{{'AUTHENTICATION.CONFIRMRESETPASSWORD.resetPasswordLabel' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-hippa></app-hippa> -->