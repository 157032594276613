import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.css']
})
export class cRequestResetPasswordComponent implements OnInit {
  emailForm: FormGroup
  responseAlert: boolean;
  alertColor: string;
  resMessage: string;
  checkLanguage: any;
  constructor(private router: Router, private formBuilder: FormBuilder, private dataService: DataService) { }

  ngOnInit(): void {
    this.initializeEmailForm();
    this.dataService.displayHippaElement.next(true)
  }

  // ****************************************** Form ***************************************

  initializeEmailForm() {
    const emailRejex = /(^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$)/;
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    })
  }

  // ****************************************** Reset Password ***************************************

  resetPassword() {
    if (this.emailForm.valid) {
      try {
        let clientWeb = location.href;
        clientWeb = clientWeb.substring(0, clientWeb.indexOf(this.router.url))
        let body = {
          email: this.emailForm.controls['email'].value,
          clientWeb: clientWeb
        }
        this.dataService.DataService_ForgetPassword(body).subscribe(result => {
          if (result.status == 'success') {
            this.router.navigateByUrl('/authentication/reset-password-success');
            this.dataService.languageSelected.subscribe(res => {
              this.checkLanguage = res
              if (this.checkLanguage == 'Spanish') {
                this.dataService.passwordText.next('Correo electrónico enviado para restablecer la contraseña con éxito.')
              }
              else {
                this.dataService.passwordText.next('Email sent for reset password successfully.');
              }
            })
            this.checkLanguage = localStorage.getItem('lsLanguage');
          }
          else {
            this.displayAlertMessage('alert-danger', result.message);
          }
        },
          (error) => {
            this.displayAlertMessage('alert-danger', "An internal server error occured. Please try again later.")
          });
      }
      catch (catchError) {
        console.log("resetPassword==>Error", catchError);
      }
    }
    else {
      this.emailForm.markAllAsTouched();
    }
  }

  // ****************************************** Alert Message ***************************************

  displayAlertMessage(alertType: string, alertMessage: string) {
    this.responseAlert = true;
    setTimeout(() => { this.responseAlert = false }, 3000);
    this.alertColor = alertType;
    this.resMessage = alertMessage;
  }
  dismissAlert() {
    this.responseAlert = false;
  }
}