<!-- <app-header></app-header> -->
<div *ngIf="responseAlert" class="alert data-table alert-messages {{alertColor}}" role="alert"> {{resMessage |
    titlecase}}
    <button type="button" class="btn-close ml-2 mt-1" data-dismiss="alert" aria-label="Close"
        (click)="dismissAlert()">
    </button>
</div>
<div class="container authentication-labels">
    <div class="row d-flex justify-content-center align-items-center authentication-cards">
        <div class="col-12 col-md-9 col-lg-7 col-xl-6 " >
            <!-- MainHeading -->
            <h2 class="text-center mb-4 heading-text">{{'AUTHENTICATION.PASSWORDRESETREQUEST.MainHeading' | translate}}</h2>

            <div class="register-card card" >
                <div class="card-body p-lg-5">
                    <form class="Input-form" [formGroup]="emailForm">
                        <div class="form-outline mb-2">
                            <!-- Email address label -->
                            <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.PASSWORDRESETREQUEST.Emailaddresslabel' | translate}}</label>
                            <input type="email" class="form-control text-center form-control-lg mb-1" formControlName="email" placeholder="{{'AUTHENTICATION.PASSWORDRESETREQUEST.ResetPasswordPlaceholderText' | translate}}" id="form3Example1cg">
                            <!-- EmailFieldValidationMessage -->
                            <span *ngIf="emailForm.controls['email'].hasError('required') && emailForm.controls['email'].touched" class="text-danger ml-1">{{'AUTHENTICATION.PASSWORDRESETREQUEST.EmailFieldValidationMessage' | translate}}</span>
                            <!-- EmailFieldPatternValidationMessage -->
                            <span *ngIf="emailForm.controls['email'].hasError('email') && emailForm.controls['email'].touched" class="text-danger ml-1">{{'AUTHENTICATION.PASSWORDRESETREQUEST.EmailFieldPatternValidationMessage' | translate}}</span>
                        </div>
                        <div class="d-flex justify-content-center">
                            <!-- ResetPasswordButton -->
                            <button type="button" class="btn btn-success btn-lg pre-auth-btn mt-4" (click)="resetPassword()">{{'AUTHENTICATION.PASSWORDRESETREQUEST.ResetPasswordButtonText' | translate}}</button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>
<!-- <app-hippa></app-hippa> -->