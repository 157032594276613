<!-- <app-header></app-header> -->
<div *ngIf="responseAlert" class="alert data-table alert-messages {{alertColor}}"  role="alert"> {{resMessage | titlecase}}
    <button type="button" class="btn-close ml-2 mt-1" data-dismiss="alert" aria-label="Close" (click)="dismissAlert()"> </button>
</div>

<div class="container authentication-labels">
    <div class="row d-flex justify-content-center align-items-center ">
        <div class="col-12 col-md-9 col-lg-7 col-xl-6 authentication-cards">
            <h2 class="text-center mb-4 heading-text">{{'AUTHENTICATION.VERIFYOTP.MainHeading' | translate}}</h2>

            <div class="register-card card" >
                <div class="card-body p-lg-5">

                    <form class="Input-form" [formGroup]="verificationCodeForm">
                        <div class="form-outline mb-4">
                            <!-- Enter OTP label -->
                            <label for="exampleInputEmail1" class="form-label">{{'AUTHENTICATION.VERIFYOTP.EnterOtplabel' | translate}}</label>
                            <input type="number" class="form-control form-control-lg text-center"  maxlength="6" placeholder="{{'AUTHENTICATION.VERIFYOTP.SixDigitCodePlaceHolderText' | translate}}" id="form3Example1cg" formControlName="pin">
                            <!-- CodeFieldValidationMessage -->
                            <span *ngIf="verificationCodeForm.controls['pin'].hasError('required') && verificationCodeForm.controls['pin'].touched">{{'AUTHENTICATION.VERIFYOTP.CodeFieldValidationMessage' | translate}}</span
                            >
                        </div>
                        <label class=" font-16 font-400 d-flex justify-content-center" for="flexCheckDefault" (click)="navigateToVerifyEmail()">
                            <!-- CodeNotReceivedLink -->
                            <u>{{'AUTHENTICATION.VERIFYOTP.CodeNotReceivedLink' | translate}}</u>
                        </label>
                        <div class="d-flex justify-content-center">
                            <!-- ContinueButtonText -->
                            <button type="button" class="btn btn-success btn-lg pre-auth-btn mt-5 " (click)="verifyOneTimePinCode()">{{'AUTHENTICATION.VERIFYOTP.ContinueButtonText' | translate}}</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- <app-hippa></app-hippa> -->