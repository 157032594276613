import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Talk from 'talkjs';
import { SessionService } from './session.service';
declare var $:any;
@Injectable({
  providedIn: 'root'
})
export class TalkService {

  userName : string = "";
  userEmail : string = "";
  userID  : string = "";
  userPhoto  : string = "";
  loadedPopups: any=[];
  userDetails : any;
  constructor(private sessionService : SessionService,private http: HttpClient, private httpBackend: HttpBackend) { 
    this.loadedPopups = [];
    this.http = new HttpClient(this.httpBackend);
  }
 TalkService_ChatPopup(user_id,user_email, user_photo, user_name)
 {
    this.userDetails = "";
    this.userDetails = this.sessionService.cSessionService_GetUserDetails();
    this.userName = "";
    this.userEmail = "";
    this.userID  = "";
    this.userPhoto = "";
    this.userName = this.userDetails.user_name;
    this.userEmail = this.userDetails.user_email;
    this.userID = this.userDetails.user_id;
    this.userPhoto = this.userDetails.user_photo;

    let meUser = 
    {
      id: this.userID,
      name: this.userName,
      email: this.userEmail,
      photoUrl: this.userPhoto,
    }
    if(!meUser.photoUrl) delete meUser.photoUrl;
    const me = new Talk.User(meUser);
    const session = new Talk.Session({
      appId: 'NlIxihxp',
      me: me,
      });
      let otherUser = 
      {
        id : user_id,
        name: user_name,
        email: user_email,
        photoUrl: user_photo
      }
      if(!otherUser.photoUrl) delete otherUser.photoUrl;
      const other = new Talk.User(otherUser);
      const conversation = session.getOrCreateConversation(
        Talk.oneOnOneId(me, other)
      );
      conversation.setParticipant(me);
      conversation.setParticipant(other);
        const popup = session.createPopup(conversation, { keepOpen: false, showCloseInHeader: true,launcher : "never" });
        popup.mount({ show: false });
        this.loadedPopups.push(popup);
        popup.show();

      session.unreads.onChange(function(unreadConversations) {
        const amountOfUnreads = unreadConversations.length;
        
        // update the text and hide the badge if there are
        // no unreads.
        $('#notifier-badge')
        .text(amountOfUnreads)
        .toggle(amountOfUnreads > 0);
        
        // update the tab title so users can easily see that they have
        // messages waiting
        if (amountOfUnreads > 0) {
          document.getElementById("unread-message-count").innerHTML = JSON.stringify(amountOfUnreads);
          console.log("Messages Number From Popup==>>>",amountOfUnreads)
        } else {
          console.log("Messages Number From Popup==>>>",amountOfUnreads);
          document.getElementById("unread-message-count").innerHTML = "";
        }
      });
 }

 TalkService_DestroyAlloadedPopups() {
  if (this.loadedPopups.length > 0) {
      this.loadedPopups.forEach(p => p.destroy());
      this.loadedPopups = [];
  }
}

TalkService_MessageBetweenPatientAndProvider(user_id,user_email, user_photo, user_name ,conversationId)
{
  this.userDetails = "";
  this.userDetails = this.sessionService.cSessionService_GetUserDetails();
  this.userName = "";
  this.userEmail = "";
  this.userID  = "";
  this.userPhoto = "";
  this.userName = this.userDetails.user_name;
  this.userEmail = this.userDetails.user_email;
  this.userID = this.userDetails.user_id;
  this.userPhoto = this.userDetails.user_photo;

  let meUser = 
  {
    id: this.userID,
    name: this.userName,
    email: this.userEmail,
    photoUrl: this.userPhoto,
  }
  if(!meUser.photoUrl) delete meUser.photoUrl;
  const me = new Talk.User(meUser);
  const session = new Talk.Session({
    appId: 'NlIxihxp',
    me: me,
    });
    let otherUser = 
    {
      id : user_id,
      name: user_name,
      email: user_email,
      photoUrl: user_photo
    }
    if(!otherUser.photoUrl) delete otherUser.photoUrl;
    const other = new Talk.User(otherUser);
    const conversation = session.getOrCreateConversation(
      conversationId
    );
    conversation.setParticipant(me);
    conversation.setParticipant(other);
      const popup = session.createPopup(conversation, { keepOpen: false, showCloseInHeader: true,launcher : "never" });
      popup.mount({ show: false });
      this.loadedPopups.push(popup);
      popup.show();
      session.unreads.onChange(function(unreadConversations) {
      const amountOfUnreads = unreadConversations.length;
      // update the text and hide the badge if there are
      // no unreads.
      $('#notifier-badge')
      .text(amountOfUnreads)
      .toggle(amountOfUnreads > 0);
      
      // update the tab title so users can easily see that they have
      // messages waiting
      if (amountOfUnreads > 0) {
        document.getElementById("unread-message-count").innerHTML = JSON.stringify(amountOfUnreads);
        console.log("Messages Number From Popup==>>>",amountOfUnreads)
      } else {
        console.log("Messages Number From Popup==>>>",amountOfUnreads);
        document.getElementById("unread-message-count").innerHTML = "";
      }
    });
}

  TalkService_MessageNotification(user_id,user_name,user_email,user_photo)
  {
    let meUser = 
    {
      id: user_id,
      name: user_name,
      email: user_email,
      photoUrl: user_photo,
    }
    if(!meUser.photoUrl) delete meUser.photoUrl;
    const me = new Talk.User(meUser);
    const session = new Talk.Session({
    appId: 'NlIxihxp',
    me: me,
    });

    session.unreads.onChange(function(unreadConversations) {
      const amountOfUnreads = unreadConversations.length;
      
      // update the text and hide the badge if there are
      // no unreads.
      $('#notifier-badge')
      .text(amountOfUnreads)
      .toggle(amountOfUnreads > 0);
      
      // update the tab title so users can easily see that they have
      // messages waiting
      if (amountOfUnreads > 0) {
        document.getElementById("unread-message-count").innerHTML = JSON.stringify(amountOfUnreads);
        console.log("Messages Number From Header ==>>>",amountOfUnreads)
      } else {
        console.log("Messages Number From Header==>>>",amountOfUnreads);
        document.getElementById("unread-message-count").innerHTML = "";
      }
    });
  }

  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer sk_test_BAiRQS0anw5y2b5k1fjGw23fLFsdWJra'
    })
  }
 
  TalkService_CloseConversation(nIncomingPatientId, nIncomingConversationId, access): Observable<any>
  {
    console.log("Working Or Not ==>>>");
    let body = {access: access}
    return this.http.patch<any>('https://api.talkjs.com/v1/NlIxihxp/conversations/'+nIncomingConversationId+'/participants/'+nIncomingPatientId,body,this.httpOptions)
  }

}
